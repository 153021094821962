import React, { useState, useEffect, useContext, useRef } from "react";
import DataGridView from "../Layouts/DataGridView";
import "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import CustomerLookup from "./Customerlookup";
import { useParams } from "react-router-dom";
import { toast, Slide } from "react-toastify";
import { getResponse, getResponseWithoutParam } from "../../Services/Common/Get";
import { deleteResponse } from "../../Services/Common/Delete";
import { postResponse } from "../../Services/Common/Post";
import { putResponse } from "../../Services/Common/Put";
import QouteHeaders from "./QouteHeaders";
import "react-toastify/dist/ReactToastify.css";
import LZString from 'lz-string';
import 'react-notifications/lib/notifications.css';
import ProgressBar from '@ramonak/react-progress-bar';
import QuotesLookup from "./QuotesLookup";
import { useUniteDetails } from "../../Contexts/UnitDetailsProvider";
import { usePriceListDetails } from "../../Contexts/PriceListDetailsProvider";
import { QuoteCustomerContext } from "../../Contexts/QuoteCustomerContext"; // adjust path as needed
import CustomerEditForm from "./CustomerEditForm";
import ContactEditForm from "./ContactEditForm";
import CustomerIdContext from "../../Contexts/CustomerIdContext";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { confirmAlert } from "react-confirm-alert"; // Import
import { OpenQuoteDataContext } from "../../Contexts/OpenQuoteDataContext";
import { getQuoteDataModel, getDraftQuoteDataModel } from "../../models/Quotations";
import { convertToModel } from "../../models/Quotations";
import { RemoveItemsContext } from "../../Contexts/ReomoveItemsContext";
import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";
import { useNodeContext } from "../../Contexts/NodeProvider";
import { Loadstatuscontext } from "../../Contexts/LoadStatus";
import { useFirstCustomer } from "../../Contexts/FirstQuoteCustomerContext";
import { QuoteCustomerIdContext } from "../../Contexts/QouteCustomerIdContext";
import moment from 'moment-timezone';
import Spinner from "../Common/Spinner";
import { v4 as uuidv4 } from "uuid";
import { itemGridRecalculation } from "../../utils/Calculations";
/*UI*/
import { Accordion, AccordionItem, Collapse } from "reactstrap";
import classnames from "classnames"; //for accordion classnames
import { orderBy, setGroupIds } from "@progress/kendo-react-data-tools";
import { GroupTypes } from "../../enums/GroupTypes";
import { sortItemListByPosition } from "../../utils/GroupFilters";
import { useTrigerSaveQuoteContext } from "../../Contexts/TrigerSaveQuoteContext";
import { useItemList } from "../../Contexts/ItemListProvider";
import { DraftSaveContext } from "../../Contexts/DraftSaveContext";
import { useQuoteContact } from "../../Contexts/QuoteContactProvider";
//#region import approval workflow
import { QteApprovalNotificationContext } from "../../Contexts/QteApprovalNotificationContext";
import { QuoteApprovalMsg } from "../../enums/QuoteNotifMessage";
import { handleQuoteAuditTrail, handleSendEmail } from "../../utils/QuoteApproval";
import { set } from "lodash";
//#endregion
import { useTriggerOldQuoteOpenContext } from "../../Contexts/TriggerOldQuoteOpenContext";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { QuoteContactTypes } from "../../enums/GroupTypes";
import { useMarginAndPrice } from "../../Contexts/MarginAndPriceProvider";
import { isEqual } from 'lodash';

export let newCustomerData;

const CustomerDataGrid = ({
    data,
    onEdit,
    selectedQuote,
    setQuoteNumber,
    quoteNumber,
    setMainCustomer,
    mainCustomer,
    triggerSaveQuote,
    returnListCount,
    triggerAddCustomer,
    baseCustomerOwner,
    setBaseCustomerOwner,
    quoteCustomerOwner,
    setQuoteCustomerOwner,
    quoteContact,
    setQuoteContact,
    selectedContactTypeId,
    onDraftStatusChange,
    isNavigate,
    isSavedQuoteChange,
    totalCost,
    setTotalCost,
    // setImportShowProgress,
    // importShowProgress,
    // progressPercent,
    // setprogressPercent
}) => {
    const [dialogVisible, setDialogVisible] = useState(false);
    const [customerData, setCustomerData] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [quoteApiResponse, setQuoteApiResponse] = useState([]);
    //header values
    const [projectName, setProjectName] = useState("");
    const [category, setCategory] = useState("");
    const [isCategoryChanged, setIsCategoryChanged] = useState(false);
    const [isHeaderChanged, setIsHeaderChanged] = useState(false);
    const [quoteDate, setQuoteDate] = useState();
    const [header, setHeader] = useState("");
    const [isCustomerRemoved, setIsCustomerRemoved] = useState(false);
    const [headerId, setHeaderId] = useState("");
    const bool = useRef(false);
    const { id } = useParams();
    const { priceListDetails, updatePriceListDetailsForId, setIsPriceListCopyed, isPriceListCopyed, copiedPriceList, setCopiedPriceList, isPriceListItemSelected } = usePriceListDetails();
    const { totalOfUnitItems, setTotalOfUnitItems, copiedUnits, setCopiedUnits, unitCopy, setUnitCopy, setCreatedName, isUnitsLoad, setIsUnitsLoad, isCreatedUnit, setIsCreatedUnit, unitId, setUnitId, unitDetails, updateUnitDetailsForId, unitName, setUnitName, newUnitName, setNewUnitName, listOfUnitNames, listOfUnitIdWithNames, setListOfUnitIdWithNames, triggerUnitSaving, setTriggerUnitSaving } = useUniteDetails();
    const [quotesDialogVisible, setQuotesDialogVisible] = useState(false);
    const [customerEditFormVisible, setCustomerEditFormVisible] = useState(false);
    const [customerEditFormClose, setCustomerEditFormClose] = useState(false);
    const [contactEditFormVisible, setContactEditFormVisible] = useState(false);
    const [systemSettingFormVisible, setSystemSettingFormVisible] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const { quoteCustomer, setQuoteCustomer } = useContext(QuoteCustomerContext);
    // const [quoteUpdated, setQuoteUpdated] = useState(false);
    const [isQuoteSave, setIsQuoteSave] = useState(false);
    const { customerId, setCustomerId } = useContext(CustomerIdContext);
    const { LoadStatus, SetLoadStatus } = useContext(Loadstatuscontext);
    const { quoteCustomerId, setQuoteCustomerId } = useContext(QuoteCustomerIdContext);
    // const [usermobile, setUserMobile] = useState("");
    const [isCustomerDataEmpty, setCustomerDataEmpty] = useState(true);
    const [areQuoteHeadersFilled, setQuoteHeadersFilled] = useState(false);
    const [isAlldataLoad, setIsAllDataLoad] = useState(false);
    const { isDataFromOpenQuote } = useContext(OpenQuoteDataContext);
    const { addItems, setAddItems } = useContext(RemoveItemsContext);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [currentQuoteId, setcurrentQuoteId] = useState("");
    const [currentDraftQuoteId, setdraftcurrentQuoteId] = useState("");
    const { quoteDetails, updateQuoteDetailsForId, quoteCreatedDate, setQuoteCreatedDate } = useQuoteDetails();
    const { isDraftQuoteSave, setIsDraftQuoteSave } = useContext(DraftSaveContext);
    const [isDraftQuote, setIsDraftQuote] = useState(true);
    const [categoryList, setCategoryList] = useState([]);
    const [headerList, setHeaderList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastValidCustomerNumber, setLastValidCustomerNumber] = useState("");
    const [quote, setQuote] = useState(null);
    const [oldDraftQuote, setOldDraftQuote] = useState(null);
    const [custCount, setCustCount] = useState(0);
    const [quoteContactmobile, setQuoteContactMobile] = useState("");
    const { firstCustomer, updateFirstCustomerForId } = useFirstCustomer();
    const { customEmail, setCustomEmail, customPhone, setOwner, setCustomPhone, owner, updatedQuoteContact, setUpdatedQuoteContact, search, setSearch, allowSetContact, setAllowSetContact, selectedContactOption,
        setSelectedContactOption, currentContact, setCurrentContact, setContactList, fullName, customName, setCustomName, newQuoteContact, setNewQuoteContact, isDoneButtonClicked, setIsDoneButtonClicked, searchContactMobile, usermobile, setUserMobile, currentContactOption, setCurrentContactOption } = useQuoteContact();
    const user = useContext(UserContext);
    const userId = user?.homeAccountId;
    const { isSaved, setIsSaved, isSaveClicked, setIsSaveClicked, isSaveAs, setIsSaveAs, setVisibleSaveAs, quoteCreatedbyUser, isdraftToOriginal, setIsdraftToOriginal, isSavedUpdated, setIsSavedUpdated, setQuoteCreatedbyUser, suffix, setSuffix, revision, setRevision,
        quoteDateCreatedOn, setQuoteDateCreatedOn } = useTrigerSaveQuoteContext();
    const { isOldQuoteOpen, setIsOldQuoteOpen, file, setFile, hasHeaders, setHasHeaders, isOldQuote, setIsOldQuote } = useTriggerOldQuoteOpenContext();
    const { originalItems, updateItemListForId } = useItemList();

    const {
        quotes,
        setQuotes,
        maxQuoteIndex,
        setMaxQuoteIndex,
        handleAddNode,
        handleCloseNode,
        handleDeleteNode,
        handleDeleteOpenQuote,
        handleNodeWhenUpdating,
        handleNodeWhenSaving,
        setIsQuoteSaved,
        openQuoteCount,
        quoteUpdated,
        setQuoteUpdated,
        quoteContactDetailsMobile,
        setQuoteContactDetailsMobile,
        isSelectedUnitAdded,
        setIsSelectedUnitAdded,
        isPastedUnits,
        setIsPastedUnits,
        isRemoveItemsClicked,
        isOldQuoteDone,
        setIsOldQuoteDone,
        setIsRemoveItemsClicked,
        hideTotalState, setHideTotalState,
        quoteUpdateTriggered, setQuoteUpdateTrigger,
        quoteContactTypes, setQuoteContactTypes, module, setModule, saveRef, saveQuotation,
        isDoneClickForPdf, setIsDoneClickForPdf,
        openShowProgress, setOpenShowProgress,
        importItemShowProgress, setImportItemShowProgress,
        copyPasteItemShowProgress, setCopyPasteItemShowProgress,
        progress, setProgress,
        isDoneChangesForQuoteTerms, setIsDoneChangesForQuoteTerms,
        hasQuoteChanged, setHasQuoteChanged
    } = useNodeContext();
    const [editCustomer, setEditCustomer] = useState({
        customerId: "",
        customerNumber: "",
        homeAccountId: "",
        isOwner: false,
    });
    const [emptyRow, setEmptyRow] = useState({
        customerNumber: "",
        customerName: "",
        contact: "",
        custClassification: "",
        owner: "",
        pricelistNotes: "",
        comments: "",
        // Include any other properties that a row in your DataGridView would have...
    });

    const {
        margin,
        setMargin,
        setTotal,
        totalCostAmt,
        setTotalCostAmt,
        newPrice,
        newMargin,
        allowSetValue,
        setAllowSetValue,
        allowSetMarginValue,
        setAllowSetMarginValue,
        setItemGridCount,
        totalItemPrice, setTotalItemPrice, maxMargin, setMaxMargin, isMarginSelected,
        setIsMarginSelected, isPriceSelected,
        setIsPriceSelected,
    } = useMarginAndPrice();

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(true);

    const t_col2 = () => {
        setcol2(!col2);
        setcol1(true);
    };

    const paginate = (items, pageNumber, pageSize) => {
        if (!items) {
            return [];
        }

        const startIndex = (pageNumber - 1) * pageSize;
        return items.slice(startIndex, startIndex + pageSize);
    };

    const handlePageChange = ({ skip, take }) => {
        setPageNumber(skip / take + 1);
        setPageSize(take);
    };

    const QOUTETYPECONTROLLER = "QuoteType/";
    const QOUTETYPEMETHOD = "getallquotetype";
    const QOUTEHEADERCONTROLLER = "QuoteHeaderType/";
    const QOUTEHEADERMETHOD = "getallquoteheadertype";

    const CategoryapiURL = QOUTETYPECONTROLLER + QOUTETYPEMETHOD;
    const HeaderapiURL = QOUTEHEADERCONTROLLER + QOUTEHEADERMETHOD;
    const navigate = useNavigate();
    const currentSelectedContactTypeId = quoteContactTypes[id];
    const username = user?.userFullName;
    //#region const approval workflow
    const {
        quoteApprovalMesasge, setQuoteApprovalMesasge,
        quoteApprovalStatus, setQuoteApprovalStatus,
        quoteApprovalStatId, setQuoteApprovalStatId,
        showFormForApproval, setShowFormForApproval,
        triggerSaveQteForApproval, setTriggerSaveQteForApproval,
        approvalStatList, setApprovalStatList,
        triggerSaveNewQuoteFromRejected, setTriggerSaveNewQuoteFromRejected,
        refreshStatus, setRefreshStatus,
        shareQuoteFlag, setShareQuoteFlag,
        saveAsNewFlag, setSaveAsNewFlag,
        triggerSaveAsNewFlag, setTriggerSaveAsNewFlag,
        triggerSaveRevision, setTriggerRevision,
        triggerSuffix, setTriggerSuffix,
        checkApprovalStateFor, setCheckApprovalStateFor,
        checkTriggerExport, setCheckTriggerExport
    } = useContext(QteApprovalNotificationContext);

    const resetQteApprovalForm = () => {
        // setQuoteApprovalMesasge('')
        setQuoteApprovalStatus('');
        setQuoteApprovalStatId(0);
        setShowFormForApproval(false);
    }

    const checkCurrentApprovalStatus = () => {
        resetQteApprovalForm();
        // setQuoteApprovalMesasge('')
        setQuoteApprovalStatus('');
        setQuoteApprovalStatId(0);

    }
    const [isSaveTriggered, setIsSaveTriggered] = useState(false);
    const [isSaveSufficAndRevision, setIsSaveSufficAndRevision] = useState(false);
    //#endregion

    //#region useeffect approval workflow
    useEffect(() => {
        if (triggerSaveQteForApproval === null) return;
        if (Object.keys(triggerSaveQteForApproval).length > 0) {
            if (!triggerSaveQteForApproval.triggerSave) return;
            if (!triggerSaveQteForApproval.quoteId === quoteDetails[id]?.quoteId) return;

            if (!isLoading && Object.keys(customerData).length > 0) {
                handleSave(customerData);
                checkCurrentApprovalStatus();
                setTriggerSaveQteForApproval({ triggerSave: false, quoteId: "", approvalStatusId: 0 });
            }
        }
    }, [triggerSaveQteForApproval]);

    ////temporary commented for future use
    // useEffect(() => {
    //     if (triggerSaveNewQuoteFromRejected === null) return;
    //     const { oldQuoteId, newQuoteId } = triggerSaveNewQuoteFromRejected;
    //     if (oldQuoteId.length === 0 || newQuoteId.length === 0) return;
    //     console.log("triggerSaveNewQuoteFromRejected",triggerSaveNewQuoteFromRejected);
    //     if (newQuoteId === null || newQuoteId === undefined || oldQuoteId === null)
    //     {
    //         toast.error("Error in creating new quote from rejected quote.");
    //         return;
    //     }

    //     resetQteApprovalForm();
    //     setcurrentQuoteId("");
    //     setRevision(false);
    //     setSuffix(false);
    //     setQuoteNumber("");
    //     const currentCustData = customerData[id];
    //     const newCustomerData = {[newQuoteId]: currentCustData};
    //     setCustomerData((prevCustomerData) => ({
    //         ...prevCustomerData,
    //         [newQuoteId]: [...(prevCustomerData[newQuoteId] || []), newCustomerData],
    //     }));

    //     const currentQuote = [quoteDetails[id]].map((item) => {
    //        return { ...item, quoteId: newQuoteId }; 
    //     });
    //     const updateQuoteDetailsForId = (prevQuoteDetails) => ({
    //             ...prevQuoteDetails,
    //              [newQuoteId]: [...(prevQuoteDetails[newQuoteId] || []), ...currentQuote[id]],
    //     });

    //     // quoteDetails[newQuoteId].quoteId = newQuoteId;
    //     handleAddNode(quotes, setQuotes, maxQuoteIndex, setMaxQuoteIndex, "Quote");
    //     navigate("/quotes/" + newQuoteId, { replace: true });

    //     // setQuoteDetails((prevQuoteDetails) => { 

    //     // });
    //     // const updateQuoteDetailsForId = (id, data) => {
    //     //     setQuoteDetails((prevQuoteDetails) => ({
    //     //         ...prevQuoteDetails,
    //     //         [id]: { ...prevQuoteDetails[id], ...data },
    //     //     }));
    //     // };

    //     // handleSave(customerData);

    // },[triggerSaveNewQuoteFromRejected]);

    //#endregion
    ////#endregion temp commented for future use

    const [generalDetails, setGeneralDetails] = useState([]);
    const [customerDetails, setCustomerDetails] = useState([]);
    const [itemDetails, setItemDetailst] = useState([]);
    const [isOQDataLoaded, setIsOQDataLoaded] = useState(false);

    useEffect(() => {
        if (selectedCustomer !== null) {
            const selCusId = selectedCustomer?.customerId;
            const selIndex = customerData[id]?.findIndex((c) => c.customerId === selCusId);
            customerData[id][selIndex] = selectedCustomer;
            updateQuoteDetailsForId(id, { selectedCustomers: customerData[id] });
            //setQuoteUpdated(true);
        }
        resetQteApprovalForm();
    }, [selectedCustomer]);

    useEffect(() => {
        window.addEventListener("error", (e) => {
            if (e.message === "ResizeObserver loop limit exceeded" || e.message === "Script error.") {
                const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
                const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        });

    }, []);

    useEffect(() => {
        checkQuoteHeadersFilled();
    }, [projectName, category, quoteDate, header]);


    useEffect(() => {
        if (quoteDetails[id]) {
            if (quoteDetails[id]?.savedQuote?.quoteNumber?.includes("Draft")) {
                setdraftcurrentQuoteId(quoteDetails[id].quoteId ? quoteDetails[id].quoteId : id);
                setSelectedCustomers(quoteDetails[id].selectedCustomers ? quoteDetails[id].selectedCustomers : []);
                setSelectedItems(quoteDetails[id].selectedItems ? quoteDetails[id].selectedItems : []);
                setIsDoneClickForPdf(false);
                setQuote(quoteDetails[id]?.savedQuote ? quoteDetails[id]?.savedQuote : null);
                setQuoteContactDetailsMobile(quoteDetails[id]?.quoteContactDetailsMobile);
                setQuoteNumber(quoteDetails[id]?.savedQuote?.quoteNumber);
                setQuoteContact(quoteDetails[id]?.quoteContact);
                setCurrentContact(quoteDetails[id]?.savedQuote?.quoteContact);
                setIsOldQuoteDone(false);
                setQuoteUpdateTrigger(false);
                setIsDoneChangesForQuoteTerms(false);
                updateQuoteDetailsForId(id, { quoteNumber: quoteNumber , selectedItems: quoteDetails[id]?.selectedItems , selectedCustomers: quoteDetails[id]?.selectedCustomers });
                setCustomerData((prevCustomerData) => ({
                    ...prevCustomerData,
                    [id]: quoteDetails[id]?.selectedCustomers?.length > 0 ? quoteDetails[id].selectedCustomers : [],
                }));
            }
            else {
                setcurrentQuoteId(quoteDetails[id].quoteId ? quoteDetails[id].quoteId : "");
                // setSelectedCustomers(quoteDetails[id].selectedCustomers ? quoteDetails[id].selectedCustomers : []);
                // setSelectedItems(quoteDetails[id].selectedItems ? quoteDetails[id].selectedItems : []);
                setQuote(quoteDetails[id]?.savedQuote ? quoteDetails[id]?.savedQuote : null);
                setQuoteContact(quoteDetails[id]?.quoteContact);
                setCurrentContact(quoteDetails[id].quoteId ? quoteDetails[id]?.savedQuote.quoteContact : quoteDetails[id]?.quoteContact);
                setQuoteContactDetailsMobile(quoteDetails[id]?.quoteContactDetailsMobile);
                //setCurrentContact(currentSelectedContactTypeId === 5 ? username : quoteDetails[id]?.quoteContact);
                setIsOldQuoteDone(false);
                setIsDoneClickForPdf(false);
                setIsCustomerRemoved(false);
                setIsQuoteSaved(false);
                setIsDoneChangesForQuoteTerms(false);
                setQuoteUpdateTrigger(false);
                updateQuoteDetailsForId(id, { quoteNumber: quoteNumber , selectedItems: quoteDetails[id]?.selectedItems , selectedCustomers: quoteDetails[id]?.selectedCustomers });
                setCustomerData((prevCustomerData) => ({
                    ...prevCustomerData,
                    [id]: quoteDetails[id]?.selectedCustomers?.length > 0 ? quoteDetails[id].selectedCustomers : [],
                }));
            }
        }
        checkCurrentApprovalStatus();

        setIsOldQuoteDone(false);

    }, [id]);


    useEffect(() => {
        const fetchUserMobile = async () => {
            try {
                const result = await getResponse("User/getusermobile", { userId: userId });
                setUserMobile(result.mobile); // Access the mobile property of the JSON object
            } catch (error) {
                console.error("Failed to fetch mobile number:", error);
                // Optionally handle error in some way, e.g., show a message to the user
            }
        };

        if (userId) fetchUserMobile();
    }, [customerData, userId]); // If userId is likely to change, include it in the dependency array, otherwise you can leave the array empty

    useEffect(() => {
        // Only run the following code if customerData[id]?.length > 0 is true
        if (customerData[id]?.length > 0) {
            getResponseWithoutParam(
                "Customer/getfilteredcustomerdetails?customerId=" +
                customerData[id][0].customerId +
                "&currentUserID=" +
                userId +
                "&customerNumber=" +
                customerData[id][0].customerNumber
            )
                .then((res) => {
                    if (res) {
                        const r = res?.customerOwner;
                        
                        setQuoteContactMobile(r?.mobile);
                        //use for quote contact component
                        setOwner(customerData[id][0].owner);
                        
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.error("Connection error. Please try agin later.", {
                        position: "top-center",
                        transition: Slide,
                    });
                });
        }
    }, [customerData, userId]); // added userId to the dependencies array as it's used inside the useEffect

    useEffect(() => {
        if (!isDoneButtonClicked && !selectedQuote) {
            try {
                if (selectedContactTypeId === 5) {
                    setQuoteContactDetailsMobile(usermobile);
                    updateQuoteDetailsForId(id, {
                        quoteContactDetailsMobile: usermobile
                    });
                } else if (selectedContactTypeId === 7) {
                    setQuoteContactDetailsMobile(quoteContactmobile);
                    setCurrentContact(quoteDetails[id]?.selectedquoteCustomers);

                    updateQuoteDetailsForId(id, {
                        quoteContactDetailsMobile: quoteContactmobile
                    });

                } else {
                    console.error("Data is empty or does not contain expected properties.");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
        else {
            try {
                if (isDoneClickForPdf) {
                    if (currentContactOption === QuoteContactTypes.OPTION1) {
                        setQuoteContactDetailsMobile(quoteDetails[id]?.quoteContactDetailsMobile);
                    } else if (currentContactOption === QuoteContactTypes.OPTION2) {
                        setQuoteContactDetailsMobile(usermobile);
                        updateQuoteDetailsForId(id, {
                            quoteContactDetailsMobile: usermobile
                        });

                    } else if (currentContactOption === QuoteContactTypes.OPTION3) {
                        setQuoteContactDetailsMobile(quoteContactmobile);
                        updateQuoteDetailsForId(id, {
                            quoteContactDetailsMobile: quoteContactmobile
                        });
                    } else if (currentContactOption === QuoteContactTypes.OPTION4) {
                        setQuoteContactDetailsMobile(searchContactMobile);
                        updateQuoteDetailsForId(id, {
                            quoteContactDetailsMobile: searchContactMobile
                        });
                    }
                    else if (currentContactOption === QuoteContactTypes.OPTION5) {
                        setQuoteContactDetailsMobile(customPhone);
                        updateQuoteDetailsForId(id, {
                            quoteContactDetailsMobile: customPhone
                        });
                    }
                    else {
                        console.error("Data is empty or does not contain expected properties.");
                    }
                }

            } catch (error) {
                console.error("Error:", error);
            }

            //  setIsDoneButtonClicked(false);
            //isDoneButtonClicked,
        }
    }, [customerData, selectedContactTypeId, selectedContactOption, usermobile, quoteContactmobile, searchContactMobile, quoteContactDetailsMobile, currentContactOption]); // Updated dependencies

    // useEffect(() => {
    // if (!isDoneButtonClicked){
    //     try {
    //         if (selectedContactTypeId === 5) {
    //             setQuoteContactDetailsMobile(usermobile);
    //         } else if (selectedContactTypeId === 7) {
    //             setQuoteContactDetailsMobile(quoteContactmobile);
    //         } else {
    //             console.error("Data is empty or does not contain expected properties.");
    //         }
    //     } catch (error) {
    //         console.error("Error:", error);
    //     }
    // }
    // }, [customerData, selectedContactTypeId, usermobile, quoteContactmobile]); // Updated dependencies


    // useEffect(() => {
    //     if (isDoneButtonClicked){
    //         try {
    //             if (selectedContactOption === QuoteContactTypes.OPTION1) {
    //                 setQuoteContactDetailsMobile(quoteDetails[id]?.quoteContactDetailsMobile);
    //             } else if (selectedContactOption === QuoteContactTypes.OPTION2) {
    //                 setQuoteContactDetailsMobile(usermobile);
    //             } else if (selectedContactOption === QuoteContactTypes.OPTION3) {
    //                 setQuoteContactDetailsMobile(quoteDetails[id]?.quoteContactDetailsMobile);
    //             }
    //             else if (selectedContactOption === QuoteContactTypes.OPTION4) {
    //                 setQuoteContactDetailsMobile(searchContactMobile);
    //             }
    //             else if (selectedContactOption === QuoteContactTypes.OPTION5) {
    //                 setQuoteContactDetailsMobile(customPhone);
    //             }
    //             else {
    //                 console.error("Data is empty or does not contain expected properties.");
    //             }

    //         } catch (error) {
    //             console.error("Error:", error);
    //         }
    //     }

    //     }, [quoteDetails[id], searchContactMobile , usermobile , quoteContactDetailsMobile,QuoteContactTypes]);


    useEffect(() => {
        setCustCount(customerData[id]?.length ?? 0);
        returnListCount(customerData[id]?.length ?? 0);
    }, [customerData]);

    useEffect(() => {
        if (triggerSaveQuote == 1 && customerData) {
            if (!isSaveTriggered && !suffix && !revision) {
                handleSave(customerData);
            }
        }
    }, [triggerSaveQuote]);


    useEffect(() => {
        //const draftQuoteId = quoteDetails[id]?.savedQuote?.quoteId;
        if (currentDraftQuoteId || currentQuoteId) {
            handleSave(customerData);
        }


    }, [isdraftToOriginal, isSavedUpdated]);



    useEffect(() => {
        if ((suffix || revision)) {
            handleSave(customerData);
        }
    }, [suffix, revision]);

    useEffect(() => {
        if (triggerAddCustomer > 0) {
            handleAdd();
        }
    }, [triggerAddCustomer]);

    useEffect(() => {
        if (!isSaveClicked) {
            setIsSaved(false);
        }
    }, [isSaved, isSaveClicked]);



    const getSysSetting = async () => {
        const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", { userId: userId });
        if (data) {
            let cat = data.map((item) => item.qtType);
            let header = data.map((item) => item.headerName);

            setCategory(cat[0]);
            setHeader(header[0]);
            sessionStorage.setItem(`category_${id}`, cat[0]);
            sessionStorage.setItem(`header_${id}`, header[0]);
        }
    };

    const triggerCustomerEditFormClose = () => {
        setCustomerEditFormClose(true);
        setTimeout(setCustomerEditFormClose, 100, false); // return to default value
    };

    const formatItemList = (items) => {
        let formattedData = [];
        const groupedList = orderBy(items, [{ field: "position", dir: "asc" }]);

        const firstGroupNames = new Set();
        const firstSubGroupNames = new Set();

        groupedList.forEach((item) => {
            if (
                !firstGroupNames.has(item.groupId) &&
                item.groupId !== "00000000-0000-0000-0000-000000000000" &&
                item.rowType !== "A"
            ) {
                formattedData.push({
                    stStockCode: "",
                    description: "",
                    type: "",
                    rowType: "",
                    listPrice: "",
                    l60: "",
                    l55: "",
                    l50: "",
                    l20: "",
                    stFutureCostRound: "",
                    stFutureCost: "",
                    stEecostRound: "",
                    stEecost: "",
                    costEA: "",
                    costEAExact: "",
                    actCost: "",
                    actCostExact: "",
                    lastCost: "",
                    lastCostExact: "",
                    qty: 1,
                    nettEA: "",
                    groupName: item.groupName,
                    groupId: item.groupId,
                    displayGroupName: item.groupName,
                    subGroupName: "",
                    subGroupId: "",
                    displaySubGroupName: "",
                    stId: uuidv4(),
                    totalPrice: "",
                    rowId: uuidv4(),
                });
                firstGroupNames.add(item.groupId);
            }

            if (item.subGroupName !== "") {
                if (
                    !firstSubGroupNames.has(item.subGroupId) &&
                    item.subGroupId !== "00000000-0000-0000-0000-000000000000"
                ) {
                    formattedData.push({
                        stStockCode: "",
                        description: "",
                        type: "",
                        rowType: "",
                        listPrice: "",
                        l60: "",
                        l55: "",
                        l50: "",
                        l20: "",
                        stFutureCostRound: "",
                        stFutureCost: "",
                        stEecostRound: "",
                        stEecost: "",
                        costEA: "",
                        costEAExact: "",
                        actCost: "",
                        actCostExact: "",
                        lastCost: "",
                        lastCostExact: "",
                        qty: 1,
                        nettEA: "",
                        groupName: item.groupName,
                        groupId: item.groupId,
                        displayGroupName: "",
                        subGroupName: item.subGroupName,
                        subGroupId: item.subGroupId,
                        displaySubGroupName: item.subGroupName,
                        stId: uuidv4(),
                        totalPrice: "",
                        rowId: uuidv4(),
                    });
                    firstSubGroupNames.add(item.subGroupId);
                }
            }

            formattedData.push(item);
        });

        return formattedData;
    };

    function groupAndSumField(data, fieldName) {
        const groupedData = {};

        // Step 1: Calculate the sums
        data.forEach((item) => {
            const { groupName, subGroupName } = item;
            const fieldValue = item[fieldName];
            let value = -1;

            if (fieldName !== "stEecost") {
                value = !isNaN(fieldValue) ? parseFloat(parseFloat(fieldValue).toFixed(2)) : 0;
            } else {
                value = !isNaN(fieldValue) ? parseFloat(fieldValue) : 0;
            }
            // const value = !isNaN(fieldValue) ? parseFloat(parseFloat(fieldValue).toFixed(2)) : 0;

            if (groupName && subGroupName) {
                if (!groupedData[groupName]) {
                    groupedData[groupName] = {};
                }

                if (!groupedData[groupName][subGroupName]) {
                    groupedData[groupName][subGroupName] = 0;
                }

                groupedData[groupName][subGroupName] += value;
            } else if (groupName) {
                if (!groupedData[groupName]) {
                    groupedData[groupName] = {};
                }
            }
        });

        // Step 2: Update the original objects with the calculated sums for matching subGroupName and displaySubGroupName
        data.forEach((item) => {
            const { subGroupName, displaySubGroupName } = item;

            if (subGroupName && displaySubGroupName && subGroupName === displaySubGroupName) {
                const total = groupedData[item.groupName][subGroupName];

                if (fieldName === "stEecost") {
                    item["stEecost"] = parseFloat(total);
                    item["stEecostRound"] = parseFloat(parseFloat(total).toFixed(2));
                } else {
                    item[fieldName] = parseFloat(parseFloat(total).toFixed(2));
                }
                // item[fieldName] =
                //     fieldName === "stEecost" ? parseFloat(total) : parseFloat(parseFloat(total).toFixed(2));
            }
        });

        return data;
    }

    function calculateSumOfListPrices(data, fieldName) {
        const groupedData = {};

        data.forEach((item) => {
            const { groupName, [fieldName]: fieldValue, subGroupName, displaySubGroupName } = item;
            const numericFieldValue =
                fieldName === "stEecost" ? parseFloat(fieldValue) : parseFloat(parseFloat(fieldValue).toFixed(2));

            if (groupName && !isNaN(numericFieldValue) && (!subGroupName || displaySubGroupName)) {
                if (!groupedData[groupName]) {
                    groupedData[groupName] = { sumFieldValue: 0 };
                }
                groupedData[groupName].sumFieldValue +=
                    fieldName === "stEecost"
                        ? parseFloat(numericFieldValue)
                        : parseFloat(parseFloat(numericFieldValue).toFixed(2));
            }
        });

        let totalSum = 0;

        for (const groupName in groupedData) {
            const group = groupedData[groupName];
            totalSum +=
                fieldName === "stEecost"
                    ? parseFloat(group.sumFieldValue)
                    : parseFloat(parseFloat(group.sumFieldValue).toFixed(2));
        }

        return groupedData;
    }


    const compareQuoteData = (apiData, stateData) => {
        if (!apiData || !stateData) return false;
    
        // Extract the quote-specific details from both apiData and stateData
        const apiQuoteDetails = {
            quoteNumber: apiData?.quote?.quoteNumber,
            quoteDate: apiData?.quote?.quoteDate,
            quoteType: apiData?.quote?.quoteType,
            quoteContact: apiData?.quote?.quoteContact,
            quoteTermsAndConditions: apiData?.quote?.quoteTermsAndConditions,
            revisionNumber: apiData?.quote?.revisionNumber,
            suffixLetter: apiData?.quote?.suffixLetter,
            projectName: apiData?.quote?.projectName,
            quoteContactDetailsMobile: apiData?.quote?.quoteContactDetailsMobile,
            // Add any other quote-specific details you want to compare
        };
    
        const stateQuoteDetails = {
            quoteNumber: stateData?.quoteNumber,
            quoteDate: stateData?.quoteDate,
            quoteType: stateData?.quoteType,
            quoteContact: stateData?.quoteContact,
            quoteTermsAndConditions: stateData?.quoteTermsAndConditions,
            revisionNumber: stateData?.revisionNumber,
            suffixLetter: stateData?.suffixLetter,
            projectName: stateData?.projectName,  
            quoteContactDetailsMobile: stateData?.quoteContactDetailsMobile,
            // Ensure these match with what you have in stateData
        };
    
        // Check if there are changes in the quote details
        const quoteChanged = !isEqual(apiQuoteDetails, stateQuoteDetails);
    
        // Check if there are changes in the items or customers
        const itemsChanged = !isEqual(apiData?.quote?.tblQuoteItems, stateData?.selectedItems);
        const customersChanged = !isEqual(apiData?.quote?.tblQuoteCustomers, stateData?.selectedCustomers);

    
        return quoteChanged || itemsChanged || customersChanged;
    };

      useEffect(() => {
        if (quoteApiResponse && quoteDetails[id]) {
          const hasChanged = compareQuoteData(quoteApiResponse, quoteDetails[id]);
          setHasQuoteChanged(hasChanged);
        }
      }, [quoteApiResponse, quoteDetails, id]);

    useEffect(() => {
        if (openQuoteCount > 10) {
            return;
        }
        const fetchData = async () => {
            if (!isCustomerRemoved && selectedQuote !== undefined && (selectedQuote?.tblQuoteCustomers === null || selectedQuote?.tblQuoteCustomers === undefined) && (selectedQuote?.originalQuote?.tblQuoteCustomers === null || selectedQuote?.originalQuote?.tblQuoteCustomers === undefined)) return;
            setIsLoading(true);
            let assemblyId = uuidv4();
            let groupAssembly = 1;
            let assmGrpName = "ASSEMBLY " + groupAssembly;
            try {
                const [categoryData, headerData, filteredQuoteCustomers] = await Promise.all([
                    getResponse(CategoryapiURL),
                    getResponse(HeaderapiURL),
                    selectedQuote && getResponse("Quote/getfilteredquotecustomers", { quoteId: selectedQuote.approvalStatusID !== undefined || null ? selectedQuote.quoteID : selectedQuote.quoteId }),

                ]);

                if (categoryData) {
                    setCategoryList(categoryData);
                    setCategories(categoryData.map((item) => item.qtType));
                } else {
                    toast.error("Failed to retrieve category list.", {
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                }

                if (headerData) {
                    setHeaderList(headerData);
                    setHeaders(headerData.map((item) => item.headerName));
                } else {
                    toast.error("Failed to retrieve header list.", {
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                }

                setQuoteApiResponse(filteredQuoteCustomers);

                if (filteredQuoteCustomers) {
                    const quoteType = categoryData.filter((c) => {
                        return c.qtId === filteredQuoteCustomers.quote.qttype;
                    })[0].qtType;

                    const headerName = headerData.filter((h) => {
                        return h.qhId === filteredQuoteCustomers.quote.headerName;
                    })[0].headerName;
                    setQuoteCreatedDate(filteredQuoteCustomers?.quote?.createdOn); //this added by sachini - Display a notification message when open an old quote to update pricing acording to the quote created date
                    setcurrentQuoteId(filteredQuoteCustomers.quote.quoteId);
                    setdraftcurrentQuoteId(filteredQuoteCustomers.quote.quoteId);
                    setProjectName(filteredQuoteCustomers.quote.projectName);
                    setSelectedCustomers(filteredQuoteCustomers.quote.tblQuoteCustomers);
                    setQuoteDate(new Date(filteredQuoteCustomers.quote.quoteDate));
                    setCategory(quoteType);
                    setHeader(headerName);
              
                    

                    // setCreatedBy(filteredQuoteCustomers.quote.createdBy);
                    // setCreatedByDetails(filteredQuoteCustomers.createdUserName);
                    setQuote(filteredQuoteCustomers.quote);
                    setCustomerData((prevCustomerData) => ({
                        ...prevCustomerData,
                        [id]: filteredQuoteCustomers.quote.tblQuoteCustomers,
                    }));

                    setQuoteNumber(filteredQuoteCustomers?.quote?.quoteNumber);
                    setQuoteContact(filteredQuoteCustomers?.quote?.quoteContact);
                    setQuoteContactDetailsMobile(filteredQuoteCustomers?.quote?.quoteContactDetailsMobile);
                    setCurrentContact(filteredQuoteCustomers?.quote?.quoteContact);
                    setUpdatedQuoteContact(filteredQuoteCustomers?.quote?.quoteContact);
                    setQuoteCreatedbyUser(filteredQuoteCustomers?.quote?.createdBy);
                    //to be used in quotes items grid
                    setMainCustomer(filteredQuoteCustomers?.quote?.tblQuoteCustomers[0]?.customerId);

                    //to load the items data grid when opening a quote
                    setCustomerId(filteredQuoteCustomers?.quote?.tblQuoteCustomers?.[0]?.customerId);

                    const sortedItems = sortItemListByPosition((typeof quoteDetails[id] !== "undefined" 
                        ? typeof quoteDetails[id].selectedItems !== "undefined" 
                            ? quoteDetails[id].selectedItems 
                            : filteredQuoteCustomers?.quote?.tblQuoteItems
                        : filteredQuoteCustomers?.quote?.tblQuoteItems) );
                    const updatedTblQuoteItems = sortedItems.map((item) => {
                        let getItemDtl = filteredQuoteCustomers?.stockDetails?.filter((x) => x.stStockCode === item.stStockCode);
                        if (item.rowType === "DX") {
                            return {
                                ...item,
                                listPrice: "",
                                l60: "",
                                l55: "",
                                l50: "",
                                l20: "",
                                stEecost: "",
                                stEecostRound: "",
                                stFutureCost: "",
                                stFutureCostRound: "",
                                actCost: "",
                                actCostExact: "",
                                frcMargin: "",
                                totalCost: "",
                                lastCost: "",
                                lastCostExact: "",
                                //added later
                                margin: "",
                                actMargin: "",
                                lcMargin: "",
                                repDisc: "",
                                assemblyGroupName: "",
                                displayAssemblyGroupName: "",
                            };
                        } else if (item.rowType === "A") {
                            ///reset assembly items
                            assemblyId = (item.assembly === undefined) ? uuidv4()
                                : item.assemblyId.length === 0 ? uuidv4()
                                    : item.assemblyId;
                            assmGrpName = "ASSEMBLY " + groupAssembly;
                            groupAssembly += 1;
                            return {
                                ...item,
                                assemblyId: assemblyId,
                                assemblyGroupName: assmGrpName,
                                displayAssemblyGroupName: assmGrpName,
                                oldStockCode: item?.oldStockCode ? item?.oldStockCode : null,
                                stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                                // oldStockCode: getItemDtl[0]?.oldStockCode ? getItemDtl[0]?.oldStockCode : null,
                                // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                            };
                        } else if (item.rowType === "AI") {
                            ///reset assembly items
                            return {
                                ...item,
                                nettEA: "",
                                l60: "",
                                l55: "",
                                l50: "",
                                l20: "",
                                totalPrice: "",
                                margin: "",
                                actMargin: "",
                                frcMargin: "",
                                lcMargin: "",
                                dcOnLp: "",
                                listPrice: "",
                                lastCost: "",
                                assemblyId: assemblyId,
                                assemblyId: assemblyId,
                                assemblyGroupName: assmGrpName,
                                oldStockCode: item?.oldStockCode,
                                stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                                // oldStockCode: getItemDtl[0]?.oldStockCode,
                                // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                            };
                        }
                        return {
                            ...item,
                            assemblyGroupName: "",
                            displayAssemblyGroupName: "",
                            l60: !item.l60 ? "" : item.l60,
                            l55: !item.l55 ? "" : item.l55,
                            l50: !item.l50 ? "" : item.l50,
                            l20: !item.l20 ? "" : item.l20,
                            margin: parseFloat(parseFloat(item.margin).toFixed(2)),
                            nettEA: parseFloat(parseFloat(item.nettEA).toFixed(2)),
                            stEecostRound: parseFloat(parseFloat(item.stEecostRound).toFixed(2)),
                            listPrice: parseFloat(parseFloat(item.listPrice).toFixed(2)),
                            totalPrice: parseFloat(parseFloat(item.totalPrice).toFixed(2)),
                            totalCost: parseFloat(parseFloat(item.totalCost).toFixed(2)),
                            oldStockCode: item?.oldStockCode ? item?.oldStockCode : null,
                            stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                            isDisabled: item?.isDisabled,
                            // oldStockCode: getItemDtl[0]?.oldStockCode ? getItemDtl[0]?.oldStockCode : null,
                            // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                            // isDisabled: getItemDtl[0]?.isDisabled,
                        };
                    });

                    const sortedTblQuoteItems = updatedTblQuoteItems.sort((a, b) => {
                        return a.position > b.position ? 1 : -1;
                    });

                    updateQuoteDetailsForId(filteredQuoteCustomers?.quote?.quoteId, {
                        quoteId: filteredQuoteCustomers?.quote?.quoteId,
                        quoteTermsAndConditions : filteredQuoteCustomers?.quote?.quoteTermsAndConditions,
                        pdfQuoteTermsAndConditions : filteredQuoteCustomers?.quote?.pdfQuoteTermsAndConditions,
                        quoteType: quoteType,
                        header: headerName,
                        quoteNumber: filteredQuoteCustomers?.quote?.quoteNumber,
                        quoteContact: filteredQuoteCustomers?.quote?.quoteContact,
                        selectedquoteCustomers: filteredQuoteCustomers?.quote?.quoteContact,
                        quoteContactDetailsMobile: filteredQuoteCustomers?.quote?.quoteContactDetailsMobile,
                        projectName: filteredQuoteCustomers?.quote?.projectName,
                        quoteDate: new Date(filteredQuoteCustomers?.quote?.quoteDate),
                        selectedCustomers: filteredQuoteCustomers?.quote?.tblQuoteCustomers, // tblQuoteCustomer type
                        savedQuote: filteredQuoteCustomers?.quote,
                        // selectedItems: calculatedList,
                        selectedItems: sortedTblQuoteItems,
                        //   quoteContact:filteredQuoteCustomers.quoteContact
                        openOldQuoteTimePerid: filteredQuoteCustomers?.quote?.createdOn,//added for open old quote and reset quote when 30days old
                        resetQuote: false//added for open old quote and reset quote when 30days old
                    });


                    const originalQuoteId = filteredQuoteCustomers?.quote?.originalQuoteId;
                    updateItemListForId(originalQuoteId, {
                        items: [...(originalItems[id]?.items || []), ...(filteredQuoteCustomers?.quote?.tblQuoteItems || [])],
                    });
                    // sessionStorage.setItem("currRowIndex", 0);
                    // sessionStorage.setItem("currColIndex", 4);
                }

                //setQuoteUpdated(true);
                setIsLoading(false);

                const updatedQuoteDetails = quoteDetails[filteredQuoteCustomers?.quote?.quoteId];
                const hasChanged = compareQuoteData(filteredQuoteCustomers, updatedQuoteDetails);
                setHasQuoteChanged(hasChanged);

            } catch (error) {
                setIsLoading(false);
                //toast.error("Connection error. Please try again later.");
                console.error(error);
            }
        };

        fetchData();



    }, [selectedQuote]);

    useEffect(() => {
        if (openQuoteCount > 10) {
            return;
        }
        // main loading of customer and items
        const fetchData = async () => {
            setIsLoading(true);
            let assemblyId = uuidv4();
            let groupAssembly = 1;
            let assmGrpName = "ASSEMBLY " + groupAssembly;
            try {
                const [categoryData, headerData, filteredQuoteCustomers] = await Promise.all([
                    getResponse(CategoryapiURL),
                    getResponse(HeaderapiURL),
                    getResponse("Quote/getfilteredquotecustomers", { quoteId: id }),

                ]);

                if (categoryData) {
                    setCategoryList(categoryData);
                    setCategories(categoryData.map((item) => item.qtType));
                } else {
                    toast.error("Failed to retrieve category list.", {
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                }

                if (headerData) {
                    setHeaderList(headerData);
                    setHeaders(headerData.map((item) => item.headerName));
                } else {
                    toast.error("Failed to retrieve header list.", {
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                }

                if (filteredQuoteCustomers) {
                    const quoteType = categoryData.filter((c) => {
                        return c.qtId === filteredQuoteCustomers.quote.qttype;
                    })[0].qtType;

                    const headerName = headerData.filter((h) => {
                        return h.qhId === filteredQuoteCustomers.quote.headerName;
                    })[0].headerName;

                    //setcurrentQuoteId(filteredQuoteCustomers.quote.quoteId);
                    setdraftcurrentQuoteId(filteredQuoteCustomers.quote.quoteId);
                    setProjectName(filteredQuoteCustomers.quote.projectName);
                    setSelectedCustomers(filteredQuoteCustomers.quote.tblQuoteCustomers);
                    setQuoteDate(new Date(filteredQuoteCustomers.quote.quoteDate));
                    setCategory(quoteType);
                    setHeader(headerName);
                    setQuote(filteredQuoteCustomers.quote);
                    setCustomerData((prevCustomerData) => ({
                        ...prevCustomerData,
                        [id]: filteredQuoteCustomers.quote.tblQuoteCustomers,
                    }));

                    setQuoteNumber(filteredQuoteCustomers?.quote?.quoteNumber);
                    setQuoteContact(filteredQuoteCustomers?.quote?.quoteContact);
                    setQuoteContactDetailsMobile(filteredQuoteCustomers?.quote?.quoteContactDetailsMobile);
                    setQuoteCreatedbyUser(filteredQuoteCustomers?.quote?.createdBy);
                    //to be used in quotes items grid
                    setMainCustomer(filteredQuoteCustomers?.quote?.tblQuoteCustomers[0]?.customerId);

                    //to load the items data grid when opening a quote
                    setCustomerId(filteredQuoteCustomers?.quote?.tblQuoteCustomers?.[0]?.customerId);

                    //update the approval workflow status based on the quote status
                    setQuoteApprovalStatId(filteredQuoteCustomers?.quote?.approvalStatusId);
                    setcurrentQuoteId(filteredQuoteCustomers?.quote?.quoteId ? filteredQuoteCustomers?.quote?.quoteId : "");

                    let retValue = {};
                    let parentId = null;
                    const sortedItems = sortItemListByPosition((typeof quoteDetails[id] !== "undefined" 
                        ? typeof quoteDetails[id].selectedItems !== "undefined" 
                            ? quoteDetails[id].selectedItems 
                            : filteredQuoteCustomers?.quote?.tblQuoteItems
                        : filteredQuoteCustomers?.quote?.tblQuoteItems) );
                    const updatedTblQuoteItems = sortedItems.map((item) => {
                        let getItemDtl = filteredQuoteCustomers?.stockDetails?.filter((x) => x.stStockCode === item.stStockCode);
                        if (item.rowType === "DX") {
                            retValue = {
                                ...item,
                                listPrice: "",
                                l60: "",
                                l55: "",
                                l50: "",
                                l20: "",
                                stEecost: "",
                                stEecostRound: "",
                                stFutureCost: "",
                                stFutureCostRound: "",
                                actCost: "",
                                actCostExact: "",
                                frcMargin: "",
                                totalCost: "",
                                lastCost: "",
                                lastCostExact: "",
                                //added later
                                margin: "",
                                actMargin: "",
                                lcMargin: "",
                                repDisc: "",
                                assemblyGroupName: "",
                                displayAssemblyGroupName: "",
                            };
                        } else if (item.rowType === "A") {
                            ///reset assembly items
                            assemblyId = (item.assembly === undefined) ? uuidv4()
                                : item.assemblyId.length === 0 ? uuidv4()
                                    : item.assemblyId;
                            assmGrpName = "ASSEMBLY " + groupAssembly;
                            groupAssembly += 1;
                            retValue = {
                                ...item,
                                assemblyId: assemblyId,
                                assemblyGroupName: assmGrpName,
                                displayAssemblyGroupName: assmGrpName,
                                oldStockCode: item?.oldStockCode,
                                stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                                // oldStockCode: getItemDtl[0]?.oldStockCode,
                                // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                            };
                        } else if (item.rowType === "AI") {
                            ///reset assembly items
                            retValue = {
                                ...item,
                                nettEA: "",
                                l60: "",
                                l55: "",
                                l50: "",
                                l20: "",
                                totalPrice: "",
                                margin: "",
                                actMargin: "",
                                frcMargin: "",
                                lcMargin: "",
                                dcOnLp: "",
                                listPrice: "",
                                lastCost: "",
                                assemblyId: assemblyId,
                                assemblyGroupName: assmGrpName,
                                displayAssemblyGroupName: "",
                                oldStockCode: item?.oldStockCode,
                                stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                                // oldStockCode: getItemDtl[0]?.oldStockCode,
                                // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                            };
                        } else {
                            retValue = {
                                ...item,
                                assemblyGroupName: "",
                                displayAssemblyGroupName: "",
                                l60: !item.l60 ? "" : item.l60,
                                l55: !item.l55 ? "" : item.l55,
                                l50: !item.l50 ? "" : item.l50,
                                l20: !item.l20 ? "" : item.l20,
                                margin: parseFloat(parseFloat(item.margin).toFixed(2)),
                                nettEA: parseFloat(parseFloat(item.nettEA).toFixed(2)),
                                stEecostRound: parseFloat(parseFloat(item.stEecostRound).toFixed(2)),
                                listPrice: parseFloat(parseFloat(item.listPrice).toFixed(2)),
                                totalPrice: parseFloat(parseFloat(item.totalPrice).toFixed(2)),
                                totalCost: parseFloat(parseFloat(item.totalCost).toFixed(2)),
                                oldStockCode: item?.oldStockCode,
                                stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                                // oldStockCode: getItemDtl[0]?.oldStockCode,
                                // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                                isDisabled: item.isDisabled,
                                groupLevelName: item.rowType === "G" || item.rowType === "SG"
                                    ? item.subGroupId !== "" ? item.subGroupName : item.groupName
                                    : "",
                                id: item.rowId,
                                parentId: item.rowType === "G"
                                    ? null
                                    : parentId
                            };
                        }

                        if (item.rowType === "G" || item.rowType === "SG") parentId = item.rowId;
                        return retValue;

                    });

                    const sortedTblQuoteItems = updatedTblQuoteItems.sort((a, b) => {
                        return a.position > b.position ? 1 : -1;
                    });

                    updateQuoteDetailsForId(filteredQuoteCustomers?.quote?.quoteId, {
                        quoteId: filteredQuoteCustomers?.quote?.quoteId,
                        quoteType: quoteType,
                        header: headerName,
                        quoteContact: filteredQuoteCustomers.quote?.quoteContact,
                        quoteTermsAndConditions : filteredQuoteCustomers?.quote?.quoteTermsAndConditions,
                        quoteContactDetailsMobile: filteredQuoteCustomers?.quote?.quoteContactDetailsMobile,
                        projectName: filteredQuoteCustomers?.quote?.projectName,
                        quoteDate: new Date(filteredQuoteCustomers?.quote?.quoteDate),
                        selectedCustomers: filteredQuoteCustomers?.quote?.tblQuoteCustomers, // tblQuoteCustomer type
                        savedQuote: filteredQuoteCustomers?.quote,
                        // selectedItems: calculatedList,
                        selectedItems: sortedTblQuoteItems,
                        //   quoteContact:filteredQuoteCustomers.quoteContact
                        openOldQuoteTimePerid: filteredQuoteCustomers?.quote?.createdOn,   //added for open old quote and reset quote when 30days old
                        resetQuote: false //added for open old quote and reset quote when 30days old
                    });
                    handleAddNode(
                        quotes,
                        setQuotes,
                        maxQuoteIndex,
                        setMaxQuoteIndex,
                        filteredQuoteCustomers?.quote?.quoteId,
                        true,
                        filteredQuoteCustomers?.quote
                    );


                    const originalQuoteId = filteredQuoteCustomers?.quote?.originalQuoteId;
                    updateItemListForId(originalQuoteId, {
                        items: [...(originalItems[id]?.items || []), ...(filteredQuoteCustomers?.quote?.tblQuoteItems || [])],
                    });
                    // sessionStorage.setItem("currRowIndex", 0);
                    // sessionStorage.setItem("currColIndex", 4);
                }
                //setQuoteUpdated(true);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                //toast.error("Connection error. Please try again later.");
                console.error(error);
            }
        };
        if (window.performance) {
            let navigationEntries = performance.getEntriesByType("navigation");
            if (navigationEntries.length > 0) {
                let type = navigationEntries[0].type;
                if (type === 'reload') {
                    console.log('This page was reloaded');
                    if (!isDraftQuoteSave) {
                        fetchData();
                    }


                } else {
                    console.log('This page was not reloaded');
                }
            }
        }

    }, []);



    // useEffect(() => {

    //     const quotes = getQuoteDetailsFromSessionStorage();

    //     fetchDataForQuote = async (quote) => {

    //         setIsLoading(true);
    //         let assemblyId = uuidv4();
    //         try {
    //             const [categoryData, headerData, filteredQuoteCustomers] = await Promise.all([
    //                 getResponse(CategoryapiURL),
    //                 getResponse(HeaderapiURL),
    //                 getResponse("Quote/getfilteredquotecustomers", { quoteId: id }),

    //             ]);

    //             if (categoryData) {
    //                 setCategoryList(categoryData);
    //                 setCategories(categoryData.map((item) => item.qtType));
    //             } else {
    //                 toast.error("Failed to retrieve category list.");
    //                 return;
    //             }

    //             if (headerData) {
    //                 setHeaderList(headerData);
    //                 setHeaders(headerData.map((item) => item.headerName));
    //             } else {
    //                 toast.error("Failed to retrieve header list.");
    //                 return;
    //             }

    //             if (filteredQuoteCustomers) {
    //                 const quoteType = categoryData.filter((c) => {
    //                     return c.qtId === filteredQuoteCustomers.quote.qttype;
    //                 })[0].qtType;

    //                 const headerName = headerData.filter((h) => {
    //                     return h.qhId === filteredQuoteCustomers.quote.headerName;
    //                 })[0].headerName;

    //                 //setcurrentQuoteId(filteredQuoteCustomers.quote.quoteId);
    //                 setdraftcurrentQuoteId(filteredQuoteCustomers.quote.quoteId);
    //                 setProjectName(filteredQuoteCustomers.quote.projectName);
    //                 setSelectedCustomers(filteredQuoteCustomers.quote.tblQuoteCustomers);
    //                 setQuoteDate(new Date(filteredQuoteCustomers.quote.quoteDate));
    //                 setCategory(quoteType);
    //                 setHeader(headerName);
    //                 setQuote(filteredQuoteCustomers.quote);
    //                 setCustomerData((prevCustomerData) => ({
    //                     ...prevCustomerData,
    //                     [id]: filteredQuoteCustomers.quote.tblQuoteCustomers,
    //                 }));

    //                 setQuoteNumber(filteredQuoteCustomers?.quote?.quoteNumber);
    //                 setQuoteContact(filteredQuoteCustomers?.quote?.quoteContact);
    //                 setQuoteCreatedbyUser(filteredQuoteCustomers?.quote?.createdBy);
    //                 //to be used in quotes items grid
    //                 setMainCustomer(filteredQuoteCustomers?.quote?.tblQuoteCustomers[0]?.customerId);

    //                 //to load the items data grid when opening a quote
    //                 setCustomerId(filteredQuoteCustomers?.quote?.tblQuoteCustomers?.[0]?.customerId);

    //                 //update the approval workflow status based on the quote status
    //                 setQuoteApprovalStatId(filteredQuoteCustomers?.quote?.approvalStatusId);

    //                 const sortedItems = sortItemListByPosition(filteredQuoteCustomers?.quote?.tblQuoteItems);
    //                 const updatedTblQuoteItems = sortedItems.map((item) => {
    //                     let getItemDtl = filteredQuoteCustomers?.stockDetails?.filter((x) => x.stStockCode === item.stStockCode);
    //                     if (item.rowType === "DX") {
    //                         return {
    //                             ...item,
    //                             listPrice: "",
    //                             l60: "",
    //                             l55: "",
    //                             l50: "",
    //                             l20: "",
    //                             stEecost: "",
    //                             stEecostRound: "",
    //                             stFutureCost: "",
    //                             stFutureCostRound: "",
    //                             actCost: "",
    //                             actCostExact: "",
    //                             frcMargin: "",
    //                             totalCost: "",
    //                             lastCost: "",
    //                             lastCostExact: "",
    //                             //added later
    //                             margin: "",
    //                             actMargin: "",
    //                             lcMargin: "",
    //                             repDisc: "",
    //                         };
    //                     } else if (item.rowType === "A") {
    //                         ///reset assembly items
    //                         assemblyId = (item.assembly === undefined) ? uuidv4()
    //                             : item.assemblyId.length === 0 ? uuidv4()
    //                                 : item.assemblyId;
    //                         return {
    //                             ...item,
    //                             assemblyId: assemblyId,
    //                             oldStockCode: getItemDtl[0]?.oldStockCode,
    //                             stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
    //                         };
    //                     } else if (item.rowType === "AI") {
    //                         ///reset assembly items
    //                         return {
    //                             ...item,
    //                             nettEA: "",
    //                             l60: "",
    //                             l55: "",
    //                             l50: "",
    //                             l20: "",
    //                             totalPrice: "",
    //                             margin: "",
    //                             actMargin: "",
    //                             frcMargin: "",
    //                             lcMargin: "",
    //                             dcOnLp: "",
    //                             listPrice: "",
    //                             lastCost: "",
    //                             assemblyId: assemblyId,
    //                             oldStockCode: getItemDtl[0]?.oldStockCode,
    //                             stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
    //                         };
    //                     }
    //                     return {
    //                         ...item,
    //                         margin: parseFloat(parseFloat(item.margin).toFixed(2)),
    //                         nettEA: parseFloat(parseFloat(item.nettEA).toFixed(2)),
    //                         stEecostRound: parseFloat(parseFloat(item.stEecostRound).toFixed(2)),
    //                         listPrice: parseFloat(parseFloat(item.listPrice).toFixed(2)),
    //                         totalPrice: parseFloat(parseFloat(item.totalPrice).toFixed(2)),
    //                         totalCost: parseFloat(parseFloat(item.totalCost).toFixed(2)),
    //                         oldStockCode: getItemDtl[0]?.oldStockCode,
    //                         stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
    //                     };
    //                 });

    //                 const sortedTblQuoteItems = updatedTblQuoteItems.sort((a, b) => {
    //                     return a.position > b.position ? 1 : -1;
    //                 });

    //                 updateQuoteDetailsForId(filteredQuoteCustomers?.quote?.quoteId, {
    //                     quoteId: filteredQuoteCustomers?.quote?.quoteId,
    //                     quoteType: quoteType,
    //                     header: headerName,
    //                     quoteContact: filteredQuoteCustomers.quote?.quoteContact,
    //                     quoteContactDetailsMobile: quoteContactDetailsMobile,
    //                     projectName: filteredQuoteCustomers?.quote?.projectName,
    //                     quoteDate: new Date(filteredQuoteCustomers?.quote?.quoteDate),
    //                     selectedCustomers: filteredQuoteCustomers?.quote?.tblQuoteCustomers, // tblQuoteCustomer type
    //                     savedQuote: filteredQuoteCustomers?.quote,
    //                     // selectedItems: calculatedList,
    //                     selectedItems: sortedTblQuoteItems,
    //                     //   quoteContact:filteredQuoteCustomers.quoteContact
    //                 });
    //                 handleAddNode(
    //                     quotes,
    //                     setQuotes,
    //                     maxQuoteIndex,
    //                     setMaxQuoteIndex,
    //                     filteredQuoteCustomers?.quote?.quoteId,
    //                     true,
    //                     filteredQuoteCustomers?.quote
    //                 );


    //                 const originalQuoteId = filteredQuoteCustomers?.quote?.originalQuoteId;
    //                 updateItemListForId(originalQuoteId, {
    //                     items: [...(originalItems[id]?.items || []), ...(filteredQuoteCustomers?.stockDetails || [])],
    //                 });
    //             }
    //             //setQuoteUpdated(true);
    //             setIsLoading(false);
    //         } catch (error) {
    //             setIsLoading(false);
    //             //toast.error("Connection error. Please try again later.");
    //             console.error(error);
    //         }
    //     };
    //     if (window.performance) {
    //         let navigationEntries = performance.getEntriesByType("navigation");
    //         if (navigationEntries.length > 0) {
    //             let type = navigationEntries[0].type;
    //             if (type === 'reload') {
    //                 console.log('This page was reloaded');
    //                     fetchDataForQuote();
    //             } else {
    //                 console.log('This page was not reloaded');
    //             }
    //         }
    //     }


    // }, [quotes]);

    //localStorage.setItem("quotes" , JSON.stringify(quotes));

    //     if (quotes && quotes.length > 0) {
    //     localStorage.setItem("quotes", JSON.stringify(quotes));
    // }


    //     const getQuoteDetailsFromLocalStorage = () => {
    //         const quoteDetailsString = localStorage.getItem('quotes');
    //         return quoteDetailsString ? JSON.parse(quoteDetailsString) : null;
    //     };


    // useEffect(() => {

    //     const fetchDataForSavedQuotes = async () => {
    //         setIsLoading(true);
    //         let assemblyId = uuidv4();
    //         const localquotes = getQuoteDetailsFromLocalStorage();
    //         // Filter out quotes that are not drafts or new
    //         const savedQuotes =  localquotes.filter(quote => 
    //             !quote.nodeName.includes("Draft") && !quote.nodeName.includes("New")
    //         );

    //         const nodesToAdd = [];            //const fetechedData = [];


    //         try {
    //             if( isAlldataLoad === true) return ;
    //             // Iterate over each saved quote to fetch and process its data
    //             for (const quote of savedQuotes) {
    //                 const [categoryData, headerData, filteredQuoteCustomers] = await Promise.all([
    //                     getResponse(CategoryapiURL),
    //                     getResponse(HeaderapiURL),
    //                     getResponse("Quote/getfilteredquotecustomers", { quoteId: quote.nodeId }),
    //                 ]);

    //                 // Assume these are global or previously set up state setters for category and header data
    //                 if (categoryData) {
    //                     setCategoryList(categoryData);
    //                     setCategories(categoryData.map((item) => item.qtType));
    //                 } else {
    //                     toast.error("Failed to retrieve category list.");
    //                     return;
    //                 }

    //                 if (headerData) {
    //                     setHeaderList(headerData);
    //                     setHeaders(headerData.map((item) => item.headerName));
    //                 } else {
    //                     toast.error("Failed to retrieve header list.");
    //                     return;
    //                 }

    //                 if (filteredQuoteCustomers) {
    //                     const quoteType = categoryData.filter((c) => {
    //                         return c.qtId === filteredQuoteCustomers.quote.qttype;
    //                     })[0].qtType;

    //                     const headerName = headerData.filter((h) => {
    //                         return h.qhId === filteredQuoteCustomers.quote.headerName;
    //                     })[0].headerName;

    //                     //setcurrentQuoteId(filteredQuoteCustomers.quote.quoteId);
    //                     setdraftcurrentQuoteId(filteredQuoteCustomers.quote.quoteId);
    //                     setProjectName(filteredQuoteCustomers.quote.projectName);
    //                     setSelectedCustomers(filteredQuoteCustomers.quote.tblQuoteCustomers);
    //                     setQuoteDate(new Date(filteredQuoteCustomers.quote.quoteDate));
    //                     setCategory(quoteType);
    //                     setHeader(headerName);
    //                     setQuote(filteredQuoteCustomers.quote);
    //                     setCustomerData((prevCustomerData) => ({
    //                         ...prevCustomerData,
    //                         [id]: filteredQuoteCustomers.quote.tblQuoteCustomers,
    //                     }));

    //                     //setQuoteNumber(filteredQuoteCustomers?.quote?.quoteNumber);
    //                     setQuoteContact(filteredQuoteCustomers?.quote?.quoteContact);
    //                     setQuoteCreatedbyUser(filteredQuoteCustomers?.quote?.createdBy);
    //                     //to be used in quotes items grid
    //                     setMainCustomer(filteredQuoteCustomers?.quote?.tblQuoteCustomers[0]?.customerId);

    //                     //to load the items data grid when opening a quote
    //                     setCustomerId(filteredQuoteCustomers?.quote?.tblQuoteCustomers?.[0]?.customerId);

    //                     //update the approval workflow status based on the quote status
    //                     setQuoteApprovalStatId(filteredQuoteCustomers?.quote?.approvalStatusId);

    //                     const sortedItems = sortItemListByPosition(filteredQuoteCustomers?.quote?.tblQuoteItems);
    //                     const updatedTblQuoteItems = sortedItems.map((item) => {
    //                         let getItemDtl = filteredQuoteCustomers?.stockDetails?.filter((x) => x.stStockCode === item.stStockCode);
    //                         if (item.rowType === "DX") {
    //                             return {
    //                                 ...item,
    //                                 listPrice: "",
    //                                 l60: "",
    //                                 l55: "",
    //                                 l50: "",
    //                                 l20: "",
    //                                 stEecost: "",
    //                                 stEecostRound: "",
    //                                 stFutureCost: "",
    //                                 stFutureCostRound: "",
    //                                 actCost: "",
    //                                 actCostExact: "",
    //                                 frcMargin: "",
    //                                 totalCost: "",
    //                                 lastCost: "",
    //                                 lastCostExact: "",
    //                                 //added later
    //                                 margin: "",
    //                                 actMargin: "",
    //                                 lcMargin: "",
    //                                 repDisc: "",
    //                             };
    //                         } else if (item.rowType === "A") {
    //                             ///reset assembly items
    //                             assemblyId = (item.assembly === undefined) ? uuidv4()
    //                                 : item.assemblyId.length === 0 ? uuidv4()
    //                                     : item.assemblyId;
    //                             return {
    //                                 ...item,
    //                                 assemblyId: assemblyId,
    //                                 oldStockCode: getItemDtl[0]?.oldStockCode,
    //                                 stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
    //                             };
    //                         } else if (item.rowType === "AI") {
    //                             ///reset assembly items
    //                             return {
    //                                 ...item,
    //                                 nettEA: "",
    //                                 l60: "",
    //                                 l55: "",
    //                                 l50: "",
    //                                 l20: "",
    //                                 totalPrice: "",
    //                                 margin: "",
    //                                 actMargin: "",
    //                                 frcMargin: "",
    //                                 lcMargin: "",
    //                                 dcOnLp: "",
    //                                 listPrice: "",
    //                                 lastCost: "",
    //                                 assemblyId: assemblyId,
    //                                 oldStockCode: getItemDtl[0]?.oldStockCode,
    //                                 stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
    //                             };
    //                         }
    //                         return {
    //                             ...item,
    //                             margin: parseFloat(parseFloat(item.margin).toFixed(2)),
    //                             nettEA: parseFloat(parseFloat(item.nettEA).toFixed(2)),
    //                             stEecostRound: parseFloat(parseFloat(item.stEecostRound).toFixed(2)),
    //                             listPrice: parseFloat(parseFloat(item.listPrice).toFixed(2)),
    //                             totalPrice: parseFloat(parseFloat(item.totalPrice).toFixed(2)),
    //                             totalCost: parseFloat(parseFloat(item.totalCost).toFixed(2)),
    //                             oldStockCode: getItemDtl[0]?.oldStockCode,
    //                             stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
    //                         };
    //                     });

    //                     const sortedTblQuoteItems = updatedTblQuoteItems.sort((a, b) => {
    //                         return a.position > b.position ? 1 : -1;
    //                     });

    //                     updateQuoteDetailsForId(filteredQuoteCustomers?.quote?.quoteId, {
    //                         quoteId: filteredQuoteCustomers?.quote?.quoteId,
    //                         quoteType: quoteType,
    //                         header: headerName,
    //                         quoteContact: filteredQuoteCustomers.quote?.quoteContact,
    //                         quoteContactDetailsMobile: quoteContactDetailsMobile,
    //                         projectName: filteredQuoteCustomers?.quote?.projectName,
    //                         quoteDate: new Date(filteredQuoteCustomers?.quote?.quoteDate),
    //                         selectedCustomers: filteredQuoteCustomers?.quote?.tblQuoteCustomers, // tblQuoteCustomer type
    //                         savedQuote: filteredQuoteCustomers?.quote,
    //                         // selectedItems: calculatedList,
    //                         selectedItems: sortedTblQuoteItems,
    //                         //   quoteContact:filteredQuoteCustomers.quoteContact
    //                     });


    //                     //fetechedData.push(filteredQuoteCustomers?.quote);



    //                     nodesToAdd.push({
    //                         quotes : quotes,
    //                         setQuotes : setQuotes,
    //                         maxQuoteIndex : maxQuoteIndex,
    //                         setMaxQuoteIndex : setMaxQuoteIndex,
    //                         titlePrefix : filteredQuoteCustomers?.quote?.quoteId,
    //                         isOpenQuote : true,
    //                         openedQuote :filteredQuoteCustomers?.quote

    //                     });


    //                     //     handleAddMultipleNodes(nodesToAdd);



    //                     const originalQuoteId = filteredQuoteCustomers?.quote?.originalQuoteId;
    //                     updateItemListForId(originalQuoteId, {
    //                         items: [...(originalItems[id]?.items || []), ...(filteredQuoteCustomers?.stockDetails || [])],
    //                     });

    //                     setIsAllDataLoad(true);
    //                 }
    //             }

    //             //fetechedData.forEach((quote) => { nodesToAdd.push({ quotes, setQuotes, maxQuoteIndex, setMaxQuoteIndex, titlePrefix: quote.quoteId, isOpenQuote: true, openedQuote: quote }) });
    //             handleAddMultipleNodes(nodesToAdd);
    //         } catch (error) {
    //             console.error("Error loading data for saved quotes:", error);
    //             toast.error("Error loading data for saved quotes.");
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };

    //     if (window.performance) {
    //            let navigationEntries = performance.getEntriesByType("navigation");
    //           if (navigationEntries.length > 0) {
    //                let type = navigationEntries[0].type;
    //                if (type === 'reload') {
    //                    console.log('This page was reloaded');
    //                          fetchDataForSavedQuotes();
    //                    } else {
    //                         console.log('This page was not reloaded');
    //                     }
    //                }
    //             }

    // }, [quotes]); // Dependency array includes 'quotes' to re-run if quotes change


    useEffect(() => {
        const fetchDraftData = async () => {
            if (!isCustomerRemoved && selectedQuote !== undefined && (selectedQuote?.tblQuoteCustomers_Drafts === null || selectedQuote?.tblQuoteCustomers_Drafts === undefined)) return;
            setIsLoading(true);
            let assemblyId = uuidv4();
            let groupAssembly = 1;
            let assmGrpName = "ASSEMBLY " + groupAssembly;
            try {
                const [categoryData, headerData, filteredDraftQuoteCustomers] = await Promise.all([
                    getResponse(CategoryapiURL),
                    getResponse(HeaderapiURL),
                    selectedQuote && getResponse("QuoteDraft/getDraftfilteredquotecustomers", { quoteId: selectedQuote.quoteId }),
                ]);

                if (categoryData) {
                    setCategoryList(categoryData);
                    setCategories(categoryData.map((item) => item.qtType));
                } else {
                    toast.error("Failed to retrieve category lst.", {
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                }

                if (headerData) {
                    setHeaderList(headerData);
                    setHeaders(headerData.map((item) => item.headerName));
                } else {
                    toast.error("Failed to retrieve header list.", {
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                }

                if (filteredDraftQuoteCustomers) {
                    const quoteType = categoryData.filter((c) => {
                        return c.qtId === filteredDraftQuoteCustomers.quotesDraft.qttype;
                    })[0].qtType;

                    const headerName = headerData.filter((h) => {
                        return h.qhId === filteredDraftQuoteCustomers.quotesDraft.headerName;
                    })[0].headerName;

                    //setcurrentQuoteId(filteredDraftQuoteCustomers.quotesDraft.quoteId);
                    setdraftcurrentQuoteId(filteredDraftQuoteCustomers.quotesDraft.quoteId);
                    setProjectName(filteredDraftQuoteCustomers.quotesDraft.projectName);
                    setSelectedCustomers(filteredDraftQuoteCustomers.quotesDraft.tblQuoteCustomers_Drafts);
                    setQuoteDate(new Date(filteredDraftQuoteCustomers.quotesDraft.quoteDate));
                    setQuoteContactDetailsMobile(filteredDraftQuoteCustomers?.quote?.quoteContactDetailsMobile);
                    setCategory(quoteType);
                    setHeader(headerName);
                    setQuote(filteredDraftQuoteCustomers.quotesDraft);
                    setCustomerData((prevCustomerData) => ({
                        ...prevCustomerData,
                        [id]: filteredDraftQuoteCustomers.quotesDraft.tblQuoteCustomers_Drafts,
                    }));

                    setQuoteNumber(filteredDraftQuoteCustomers?.quotesDraft?.quoteNumber);
                    setQuoteContact(filteredDraftQuoteCustomers?.quotesDraft?.quoteContact);
                    setCurrentContact(filteredDraftQuoteCustomers?.quotesDraft?.quoteContact);
                    setQuoteContactDetailsMobile(filteredDraftQuoteCustomers?.quotesDraft?.quoteContactDetailsMobile);

                    //to be used in quotes items grid
                    setMainCustomer(filteredDraftQuoteCustomers?.quotesDraft?.tblQuoteCustomers_Drafts[0]?.customerId);

                    //to load the items data grid when opening a quote
                    setCustomerId(filteredDraftQuoteCustomers?.quotesDraft?.tblQuoteCustomers_Drafts?.[0]?.customerId);

                    const sortedItems = sortItemListByPosition(filteredDraftQuoteCustomers?.quotesDraft?.tblQuoteItems_Drafts);
                    const updatedTblQuoteItems = sortedItems.map((item) => {
                        if (item.rowType === "DX") {
                            return {
                                ...item,
                                listPrice: "",
                                l60: "",
                                l55: "",
                                l50: "",
                                l20: "",
                                stEecost: "",
                                stEecostRound: "",
                                stFutureCost: "",
                                stFutureCostRound: "",
                                actCost: "",
                                actCostExact: "",
                                frcMargin: "",
                                totalCost: "",
                                lastCost: "",
                                lastCostExact: "",
                                //added later
                                margin: "",
                                actMargin: "",
                                lcMargin: "",
                                repDisc: "",
                                assemblyGroupName: "",
                                displayAssemblyGroupName: "",
                            };
                        } else if (item.rowType === "A") {
                            ///reset assembly items
                            assemblyId = (item.assembly === undefined) ? uuidv4()
                                : item.assemblyId.length === 0 ? uuidv4()
                                    : item.assemblyId;
                            assmGrpName = "ASSEMBLY " + groupAssembly;
                            groupAssembly += 1;
                            return {
                                ...item,
                                assemblyId: assemblyId,
                                assemblyGroupName: assmGrpName,
                                displayAssemblyGroupName: assmGrpName,
                            };
                        } else if (item.rowType === "AI") {
                            ///reset assembly items
                            return {
                                ...item,
                                nettEA: "",
                                l60: "",
                                l55: "",
                                l50: "",
                                l20: "",
                                totalPrice: "",
                                margin: "",
                                actMargin: "",
                                frcMargin: "",
                                lcMargin: "",
                                dcOnLp: "",
                                listPrice: "",
                                lastCost: "",
                                assemblyId: assemblyId,
                                assemblyGroupName: assmGrpName,
                                displayAssemblyGroupName: "",

                            };
                        }
                        return {
                            ...item,
                            assemblyGroupName: "",
                            displayAssemblyGroupName: "",
                            l60: !item.l60 ? "" : item.l60,
                            l55: !item.l55 ? "" : item.l55,
                            l50: !item.l50 ? "" : item.l50,
                            l20: !item.l20 ? "" : item.l20,
                            margin: parseFloat(parseFloat(item.margin).toFixed(2)),
                            nettEA: parseFloat(parseFloat(item.nettEA).toFixed(2)),
                            stEecostRound: parseFloat(parseFloat(item.stEecostRound).toFixed(2)),
                            listPrice: parseFloat(parseFloat(item.listPrice).toFixed(2)),
                            totalPrice: parseFloat(parseFloat(item.totalPrice).toFixed(2)),
                            totalCost: parseFloat(parseFloat(item.totalCost).toFixed(2)),
                        };
                    });

                    const sortedTblQuoteItems = updatedTblQuoteItems.sort((a, b) => {
                        return a.position > b.position ? 1 : -1;
                    });

                    updateQuoteDetailsForId(filteredDraftQuoteCustomers?.quotesDraft?.quoteId, {
                        quoteId: filteredDraftQuoteCustomers?.quotesDraft?.quoteId,
                        quoteType: quoteType,
                        header: headerName,
                        quoteContact: filteredDraftQuoteCustomers.quotesDraft?.quoteContact,
                        quoteContactDetailsMobile: filteredDraftQuoteCustomers.quotesDraft?.quoteContactDetailsMobile,
                        projectName: filteredDraftQuoteCustomers?.quotesDraft?.projectName,
                        quoteDate: new Date(filteredDraftQuoteCustomers?.quotesDraft?.quoteDate),
                        selectedCustomers: filteredDraftQuoteCustomers?.quotesDraft?.tblQuoteCustomers_Drafts, // tblQuoteCustomer type
                        savedQuote: filteredDraftQuoteCustomers?.quotesDraft,
                        quoteTermsAndConditions : filteredDraftQuoteCustomers?.quotesDraft?.quoteTermsAndConditions,
                        pdfQuoteTermsAndConditions : filteredDraftQuoteCustomers?.quotesDraft?.pdfQuoteTermsAndConditions,
                        // selectedItems: calculatedList,
                        selectedItems: sortedTblQuoteItems,
                        //   quoteContact:filteredDraftQuoteCustomers.quoteContact
                    });
                    handleAddNode(
                        quotes,
                        setQuotes,
                        maxQuoteIndex,
                        setMaxQuoteIndex,
                        filteredDraftQuoteCustomers?.quotesDraft?.quoteId,
                        true,
                        filteredDraftQuoteCustomers?.quotesDraft
                    );

                    const originalQuoteId = filteredDraftQuoteCustomers?.quotesDraft?.originalQuoteId;
                    updateItemListForId(originalQuoteId, {
                        items: [...(originalItems[id]?.items || []), ...(filteredDraftQuoteCustomers?.stockDetails || [])],
                    });
                }

                //setQuoteUpdated(true);
                //if (isQuoteSave) { isSavedQuoteChange(false) }
                //handleDraftSaveQuote();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                //toast.error("Connection error. Please try again later.");
                console.error(error);
            }
        };
        fetchDraftData();


        // if (window.performance) {
        //     let navigationEntries = performance.getEntriesByType("navigation");
        //     if (navigationEntries.length > 0) {
        //         let type = navigationEntries[0].type;
        //         if (type === 'reload') {
        //             console.log('This page was reloaded');



        //         } else {
        //             console.log('This page was not reloaded');
        //             fetchDraftData();
        //         }
        //     }
        // }
    }, [selectedQuote]);


    // useEffect(() => {
    //     const fetchDraftData = async () => {
    //         setIsLoading(true);
    //         let assemblyId = uuidv4();
    //         try {
    //             const [categoryData, headerData, filteredDraftQuoteCustomers] = await Promise.all([
    //                 getResponse(CategoryapiURL),
    //                 getResponse(HeaderapiURL),
    //                 selectedQuote && getResponse("QuoteDraft/getDraftfilteredquotecustomers", { quoteId: id }),
    //             ]);

    //             if (categoryData) {
    //                 setCategoryList(categoryData);
    //                 setCategories(categoryData.map((item) => item.qtType));
    //             } else {
    //                 toast.error("Failed to retrieve category lst.");
    //                 return;
    //             }

    //             if (headerData) {
    //                 setHeaderList(headerData);
    //                 setHeaders(headerData.map((item) => item.headerName));
    //             } else {
    //                 toast.error("Failed to retrieve header list.");
    //                 return;
    //             }

    //             if (filteredDraftQuoteCustomers) {
    //                 const quoteType = categoryData.filter((c) => {
    //                     return c.qtId === filteredDraftQuoteCustomers.quotesDraft.qttype;
    //                 })[0].qtType;

    //                 const headerName = headerData.filter((h) => {
    //                     return h.qhId === filteredDraftQuoteCustomers.quotesDraft.headerName;
    //                 })[0].headerName;

    //                 //setcurrentQuoteId(filteredDraftQuoteCustomers.quotesDraft.quoteId);
    //                 setdraftcurrentQuoteId(filteredDraftQuoteCustomers.quotesDraft.quoteId);
    //                 setProjectName(filteredDraftQuoteCustomers.quotesDraft.projectName);
    //                 setSelectedCustomers(filteredDraftQuoteCustomers.quotesDraft.tblQuoteCustomers_Drafts);
    //                 setQuoteDate(new Date(filteredDraftQuoteCustomers.quotesDraft.quoteDate));
    //                 setCategory(quoteType);
    //                 setHeader(headerName);
    //                 setQuote(filteredDraftQuoteCustomers.quotesDraft);
    //                 setCustomerData((prevCustomerData) => ({
    //                     ...prevCustomerData,
    //                     [id]: filteredDraftQuoteCustomers.quotesDraft.tblQuoteCustomers_Drafts,
    //                 }));

    //                 setQuoteNumber(filteredDraftQuoteCustomers?.quotesDraft?.quoteNumber);
    //                 setQuoteContact(filteredDraftQuoteCustomers?.quotesDraft?.quoteContact);
    //                 setCurrentContact(filteredDraftQuoteCustomers?.quotesDraft?.quoteContact);

    //                 //to be used in quotes items grid
    //                 setMainCustomer(filteredDraftQuoteCustomers?.quotesDraft?.tblQuoteCustomers_Drafts[0]?.customerId);

    //                 //to load the items data grid when opening a quote
    //                 setCustomerId(filteredDraftQuoteCustomers?.quotesDraft?.tblQuoteCustomers_Drafts?.[0]?.customerId);

    //                 const sortedItems = sortItemListByPosition(filteredDraftQuoteCustomers?.quotesDraft?.tblQuoteItems_Drafts);
    //                 const updatedTblQuoteItems = sortedItems.map((item) => {
    //                     if (item.rowType === "DX") {
    //                         return {
    //                             ...item,
    //                             listPrice: "",
    //                             l60: "",
    //                             l55: "",
    //                             l50: "",
    //                             l20: "",
    //                             stEecost: "",
    //                             stEecostRound: "",
    //                             stFutureCost: "",
    //                             stFutureCostRound: "",
    //                             actCost: "",
    //                             actCostExact: "",
    //                             frcMargin: "",
    //                             totalCost: "",
    //                             lastCost: "",
    //                             lastCostExact: "",
    //                             //added later
    //                             margin: "",
    //                             actMargin: "",
    //                             lcMargin: "",
    //                             repDisc: "",
    //                         };
    //                     } else if (item.rowType === "A") {
    //                         ///reset assembly items
    //                         assemblyId = (item.assembly === undefined) ? uuidv4()
    //                             : item.assemblyId.length === 0 ? uuidv4()
    //                                 : item.assemblyId;
    //                         return {
    //                             ...item,
    //                             assemblyId: assemblyId
    //                         };
    //                     } else if (item.rowType === "AI") {
    //                         ///reset assembly items
    //                         return {
    //                             ...item,
    //                             nettEA: "",
    //                             l60: "",
    //                             l55: "",
    //                             l50: "",
    //                             l20: "",
    //                             totalPrice: "",
    //                             margin: "",
    //                             actMargin: "",
    //                             frcMargin: "",
    //                             lcMargin: "",
    //                             dcOnLp: "",
    //                             listPrice: "",
    //                             lastCost: "",
    //                             assemblyId: assemblyId
    //                         };
    //                     }
    //                     return {
    //                         ...item,
    //                         margin: parseFloat(parseFloat(item.margin).toFixed(2)),
    //                         nettEA: parseFloat(parseFloat(item.nettEA).toFixed(2)),
    //                         stEecostRound: parseFloat(parseFloat(item.stEecostRound).toFixed(2)),
    //                         listPrice: parseFloat(parseFloat(item.listPrice).toFixed(2)),
    //                         totalPrice: parseFloat(parseFloat(item.totalPrice).toFixed(2)),
    //                         totalCost: parseFloat(parseFloat(item.totalCost).toFixed(2)),
    //                     };
    //                 });

    //                 const sortedTblQuoteItems = updatedTblQuoteItems.sort((a, b) => {
    //                     return a.position > b.position ? 1 : -1;
    //                 });

    //                 updateQuoteDetailsForId(filteredDraftQuoteCustomers?.quotesDraft?.quoteId, {
    //                     quoteId: filteredDraftQuoteCustomers?.quotesDraft?.quoteId,
    //                     quoteType: quoteType,
    //                     header: headerName,
    //                     quoteContact: filteredDraftQuoteCustomers.quotesDraft?.quoteContact,
    //                     quoteContactDetailsMobile: filteredDraftQuoteCustomers.quotesDraft?.quoteContactDetailsMobile,
    //                     projectName: filteredDraftQuoteCustomers?.quotesDraft?.projectName,
    //                     quoteDate: new Date(filteredDraftQuoteCustomers?.quotesDraft?.quoteDate),
    //                     selectedCustomers: filteredDraftQuoteCustomers?.quotesDraft?.tblQuoteCustomers_Drafts, // tblQuoteCustomer type
    //                     savedQuote: filteredDraftQuoteCustomers?.quotesDraft,
    //                     // selectedItems: calculatedList,
    //                     selectedItems: sortedTblQuoteItems,
    //                     //   quoteContact:filteredDraftQuoteCustomers.quoteContact
    //                 });
    //                 handleAddNode(
    //                     quotes,
    //                     setQuotes,
    //                     maxQuoteIndex,
    //                     setMaxQuoteIndex,
    //                     filteredDraftQuoteCustomers?.quotesDraft?.quoteId,
    //                     true,
    //                     filteredDraftQuoteCustomers?.quotesDraft
    //                 );

    //                 const originalQuoteId = filteredDraftQuoteCustomers?.quotesDraft?.originalQuoteId;
    //                 updateItemListForId(originalQuoteId, {
    //                     items: [...(originalItems[id]?.items || []), ...(filteredDraftQuoteCustomers?.stockDetails || [])],
    //                 });
    //             }

    //             //setQuoteUpdated(true);
    //             //if (isQuoteSave) { isSavedQuoteChange(false) }
    //             //handleDraftSaveQuote();
    //             setIsLoading(false);
    //         } catch (error) {
    //             setIsLoading(false);
    //             //toast.error("Connection error. Please try again later.");
    //             console.error(error);
    //         }
    //     };



    //     if (window.performance) {
    //         let navigationEntries = performance.getEntriesByType("navigation");
    //         if (navigationEntries.length > 0) {
    //             let type = navigationEntries[0].type;
    //             if (type === 'reload') {
    //                 console.log('This page was reloaded');

    //                 fetchDraftData();

    //             } else {
    //                 console.log('This page was not reloaded');
    //             }
    //         }
    //     }
    // }, []);



    //     const fetchDraftData = async () => {

    //         setIsLoading(true);
    //         let assemblyId = uuidv4();
    //         try {

    //             const [categoryData, headerData, draftQuotesResponse] = await Promise.all([
    //                 getResponse(CategoryapiURL),
    //                 getResponse(HeaderapiURL),
    //                 getResponse("QuoteDraft/getDraftAllquotecustomers", { userId: userId  }),
    //             ]);

    //             console.log("draftQuotesResponse:" , draftQuotesResponse)

    //             if (categoryData) {
    //                 setCategoryList(categoryData);
    //                 setCategories(categoryData.map((item) => item.qtType));
    //             } else {
    //                 toast.error("Failed to retrieve category list.");
    //                 return;
    //             }

    //             if (headerData) {
    //                 setHeaderList(headerData);
    //                 setHeaders(headerData.map((item) => item.headerName));
    //             } else {
    //                 toast.error("Failed to retrieve header list.");
    //                 return;
    //             }
    //             if (draftQuotesResponse && Array.isArray(draftQuotesResponse)) {
    //                 // Filter out duplicate quotes based on QuoteId
    //                 const uniqueDraftQuotes = draftQuotesResponse.reduce((unique, quote) => {
    //                     if (!unique.some(q => q.quotesDraft.quoteId === quote.quotesDraft.quoteId)) {
    //                         unique.push(quote);
    //                     }
    //                     return unique;
    //                 }, []);


    //                 const updates = uniqueDraftQuotes.map(filteredDraftQuoteCustomers => {
    //                     const quoteType = categoryData.filter((c) => c.qtId === filteredDraftQuoteCustomers.quotesDraft.qttype)[0]?.qtType;
    //                     const headerName = headerData.filter((h) => h.qhId === filteredDraftQuoteCustomers.quotesDraft.headerName)[0]?.headerName;

    //                     const sortedItems = sortItemListByPosition(filteredDraftQuoteCustomers.quotesDraft.tblQuoteItems_Drafts);
    //                     const updatedTblQuoteItems = sortedItems.map(item => {
    //                     if (item.rowType === "DX") {
    //                         return {
    //                             ...item,
    //                             listPrice: "",
    //                             l60: "",
    //                             l55: "",
    //                             l50: "",
    //                             l20: "",
    //                             stEecost: "",
    //                             stEecostRound: "",
    //                             stFutureCost: "",
    //                             stFutureCostRound: "",
    //                             actCost: "",
    //                             actCostExact: "",
    //                             frcMargin: "",
    //                             totalCost: "",
    //                             lastCost: "",
    //                             lastCostExact: "",
    //                             //added later
    //                             margin: "",
    //                             actMargin: "",
    //                             lcMargin: "",
    //                             repDisc: "",
    //                         };
    //                     } else if (item.rowType === "A") {
    //                         ///reset assembly items
    //                         assemblyId = (item.assembly === undefined) ? uuidv4()
    //                             : item.assemblyId.length === 0 ? uuidv4()
    //                                 : item.assemblyId;
    //                         return {
    //                             ...item,
    //                             assemblyId: assemblyId
    //                         };
    //                     } else if (item.rowType === "AI") {
    //                         ///reset assembly items
    //                         return {
    //                             ...item,
    //                             nettEA: "",
    //                             l60: "",
    //                             l55: "",
    //                             l50: "",
    //                             l20: "",
    //                             totalPrice: "",
    //                             margin: "",
    //                             actMargin: "",
    //                             frcMargin: "",
    //                             lcMargin: "",
    //                             dcOnLp: "",
    //                             listPrice: "",
    //                             lastCost: "",
    //                             assemblyId: assemblyId
    //                         };
    //                     }


    //                     return {
    //                         ...item,
    //                         margin: parseFloat(parseFloat(item.margin).toFixed(2)),
    //                         nettEA: parseFloat(parseFloat(item.nettEA).toFixed(2)),
    //                         stEecostRound: parseFloat(parseFloat(item.stEecostRound).toFixed(2)),
    //                         listPrice: parseFloat(parseFloat(item.listPrice).toFixed(2)),
    //                         totalPrice: parseFloat(parseFloat(item.totalPrice).toFixed(2)),
    //                         totalCost: parseFloat(parseFloat(item.totalCost).toFixed(2)),
    //                     };




    //                 });

    //                 const sortedTblQuoteItems = updatedTblQuoteItems.sort((a, b) => {
    //                     return a.position > b.position ? 1 : -1;

    //                 });

    //                 return {
    //                     quoteId: filteredDraftQuoteCustomers.quotesDraft.quoteId,
    //                     projectName: filteredDraftQuoteCustomers.quotesDraft.projectName,
    //                     selectedCustomers: filteredDraftQuoteCustomers.quotesDraft.tblQuoteCustomers_Drafts,
    //                     quoteDate: new Date(filteredDraftQuoteCustomers.quotesDraft.quoteDate),
    //                     quoteType,
    //                     headerName,
    //                     quoteContact: filteredDraftQuoteCustomers.quotesDraft.quoteContact,
    //                     quoteContactDetailsMobile: filteredDraftQuoteCustomers.quotesDraft.quoteContactDetailsMobile,
    //                     selectedItems: sortedTblQuoteItems,
    //                     originalQuoteId: filteredDraftQuoteCustomers.quotesDraft.originalQuoteId

    //                 };
    //             });




    //             updates.forEach(update => {
    //                 setdraftcurrentQuoteId(update.quoteId);
    //                 setProjectName(update.projectName);
    //                 setSelectedCustomers(update.selectedCustomers);
    //                 setQuoteDate(update.quoteDate);
    //                 setCategory(update.quoteType);
    //                 setHeader(update.headerName);
    //                 setQuoteContact(update.quoteContact);
    //                 setQuoteContactDetailsMobile(update.quoteContactDetailsMobile);
    //                 setSelectedItems(update.selectedItems);
    //                 updateQuoteDetailsForId(update?.quoteId, {
    //                     quoteId:update?.quoteId,
    //                     quoteType: update.quoteType,
    //                     header:update.headerName,
    //                     quoeContact: update?.quoteContact,
    //                     quoteContactDetailsMobile: update?.quoteContactDetailsMobile,
    //                     projectName:update?.projectName,
    //                     quoteDate: new Date(update?.quoteDate),
    //                     selectedCustomers: update?.tblQuoteCustomers_Drafts, // tblQuoteCustomer type
    //                     savedQuote: update,
    //                     // selectedItems: calculatedList,
    //                     selectedItems: update?.tblQuoteItems_Drafts,
    //                     //   quoteContact:filteredDraftQuoteCustomers.quoteContact
    //                 });
    //                 handleAddNode(
    //                     quotes,
    //                     setQuotes,
    //                     maxQuoteIndex,
    //                     setMaxQuoteIndex,
    //                     update?.quoteId,
    //                     true,
    //                     update,
    //                 );
    //             });
    //         }
    //              else {
    //                 toast.error("Failed to retrieve draft quotes.");
    //             }
    //         } catch (error) {
    //             console.error(error);
    //             //toast.error("Connection error. Please try again later.");
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };

    //     if (window.performance) {
    //         let navigationEntries = performance.getEntriesByType("navigation");
    //         if (navigationEntries.length > 0) {
    //             let type = navigationEntries[0].type;
    //             if (type === 'reload') {
    //                 console.log('This page was reloaded');

    //                 fetchDraftData();

    //             } else {
    //                 console.log('This page was not reloaded');
    //             }
    //         }
    //     }

    //     useEffect(() => {
    //         fetchDraftData();
    // }, []);






    const updateGridData = (updatedData) => {
        handleSave(updatedData);
        const customerDataArray = customerData[id] || [];

        const updatedDataIndex = customerDataArray.findIndex((item) => item.customerId === updatedData.customerId);

        if (updatedDataIndex !== -1) {
            const updatedCustomerDataArray = [...customerDataArray];

            updatedCustomerDataArray[updatedDataIndex] = {
                ...updatedCustomerDataArray[updatedDataIndex],
                ...updatedData,
            };

            setCustomerData((prevCustomerData) => ({
                ...prevCustomerData,

                [id]: updatedCustomerDataArray,
            }));
        }

        // Update quoteCustomer state only if quoteId is available

        // Update quoteCustomer state only if quoteId is available

        const quoteId = quoteCustomer[0]?.quote?.quoteId;

        if (quoteId) {
            const updatedQuoteCustomer = quoteCustomer.map((item) => {
                if (item.quoteCustomer.customerId === updatedData.customerId) {
                    return { ...item, ...updatedData };
                }

                return item;
            });
            setQuoteCustomer(updatedQuoteCustomer);
        }

        // Perform any other necessary actions with the updated data
    };

    const checkQuoteHeadersFilled = () => {
        // Perform the required validation logic here

        const isFilled = projectName !== "" && category !== "" && quoteDate !== "" && header !== "";

        // Add more conditions for other quote headers if needed

        setQuoteHeadersFilled(isFilled);
    };

    // quotes header will not load if uncommented
    // useEffect(() => {
    //     const isEmpty = customerData[id] ? customerData[id].length === 0 : true;
    //     setCustomerDataEmpty(isEmpty);
    // }, [customerData[id]]);

    const handleCustomerNumberChange = async ({ value: customerNumber, dataItem, clearInput }, item) => {
        SetLoadStatus(true);
        setIsQuoteSaved(false);
        setIsDoneButtonClicked(false);
        try {
            if (!customerNumber) {
                throw new Error("Customer number cannot be empty");
            }

            const response = await getResponse("Customer/getpriclistwithcustomer", {
                customerNumber: customerNumber,
                currentUserId: user?.homeAccountId,
            });

            const formattedData = convertToModel([response]);

            const customerId = dataItem.customerId;
            const customerDataArray = customerData[id] || [];

            const duplicateCustomerIndex = customerDataArray.findIndex(
                (i) =>
                    i.customerNumber.toUpperCase() === customerNumber.toUpperCase() &&
                    i.customerId !== dataItem.customerId
            );

            const existingItemIndex = customerDataArray.findIndex(
                (i) => i.customerNumber === customerNumber || i.customerId === customerId
            );

            //commented on 04/09/2023 by Manoj
            //let newCustomerData = [...customerDataArray];
            newCustomerData = [...customerDataArray];

            if (duplicateCustomerIndex !== -1) {
                toast.error("Customer Already Exists", {
                    position: "top-center",
                    transition: Slide,
                });
                clearInput(lastValidCustomerNumber);
                return;
            }

            setLastValidCustomerNumber(customerNumber);

            if (existingItemIndex !== -1) {
                newCustomerData[existingItemIndex] = {
                    ...item,
                    customerNumber: customerNumber,
                    ...formattedData[0],
                };
            } else {
                newCustomerData.push({ ...item, ...formattedData[0] });
            }

            // update isPrimary for the first item if no other item is primary
            if (!newCustomerData.some((item) => item.isPrimary)) {
                newCustomerData[0].isPrimary = true;
                //    item.isPrimary = e.target.checked;

                setBaseCustomerOwner(newCustomerData[0].owner);
                updateQuoteDetailsForId(id, {

                    quoteContact: currentSelectedContactTypeId === 7 ? newCustomerData[0].owner : quoteContact,
                    selectedquoteCustomers: currentSelectedContactTypeId === 7 ? newCustomerData[0].owner : quoteContact,
                    selectedCustomers: quoteDetails[id].selectedCustomers,
                    selectedItems: quoteDetails[id].selectedItems,


                });
            }

            if (newCustomerData.length > 0) {
                setCustomerId(newCustomerData[0].customerId);
            }

            setCustomerData((prevCustomerData) => ({
                ...prevCustomerData,
                [id]: newCustomerData,
            }));

            //to be used in quotes items grid
            setMainCustomer(newCustomerData[0].customerId);

            const finalSelectedCustomers = [...newCustomerData];

            //Start - added by Manoj
            const filteredCustomerArray = customerDataArray.filter((item) => item.customerNumber !== customerNumber);
            // const finalSelectedCustomers = [...newCustomerData];

            //End - added by Manoj

            if (selectedCustomers.length < 2) {
                updateQuoteDetailsForId(id, {
                    selectedquoteCustomers: finalSelectedCustomers[0]?.owner,
                    quoteContactDetailsMobile: quoteContactDetailsMobile,
                    quoteContact: currentSelectedContactTypeId === 7 ? finalSelectedCustomers[0]?.owner : quoteContact,
                    quoteDate: quoteDate,
                });
                setQuoteCustomerOwner(finalSelectedCustomers[0]?.owner);
            }
            setSelectedCustomers(finalSelectedCustomers);
            updateQuoteDetailsForId(id, { selectedCustomers: finalSelectedCustomers });
            setQuoteUpdated(true);

            if (!firstCustomer[id]?.customer) {
                updateFirstCustomerForId(id, { ...firstCustomer, customer: finalSelectedCustomers[0] });
            }
        } catch (error) {
            // Clear the input if there's an error
            clearInput(lastValidCustomerNumber);

            if (error.message === "Customer number cannot be empty") {
                return;
            }

            if (error.response && error.response.status === 404) {
                toast.error(error.response.data.message || "Error occurred while fetching customer data", {
                    position: "top-center",
                    transition: Slide,
                });
            } else {
                toast.error("Customer does not exist.", { autoClose: 2000, position: "top-center", transition: Slide, });
                console.error(error);
                //setCustomerDataEmpty("");
                return;
            }
        }
    };

    // useEffect(() => {
    //     console.log("loadStatuscustomernumberchange:", LoadStatus);
    // }, [LoadStatus]);
    //new -b

    const handleSelectedItems = (items) => {
        setSelectedItems(items);
    };

    const getCustomerData = async () => {
        try {
            // Removing duplicates based on customerNumber
            const uniqueAddItems = Array.from(new Set(addItems.map((item) => item.customerNumber))).map(
                (customerNumber) => addItems.find((item) => item.customerNumber === customerNumber)
            );

            // Processing the unique items
            const responses = await Promise.all(
                uniqueAddItems.map((item) =>
                    getResponse("Customer/getpriclistwithcustomer", {
                        customerNumber: item.customerNumber,
                        currentUserId: user?.homeAccountId,
                    })
                )
            );

            const formattedData = convertToModel(responses);
            updateQuoteDetailsForId(id, { selectedCustomers: formattedData });
            setQuoteUpdated(true);

            return responses;
        } catch (error) {
            console.error("Error : ", error);
        }
    };

    const handleAddItems = async () => {
        setIsQuoteSaved(false);
        if (isLoading) {
            return; // Exit if the function is still processing
        }

        setIsLoading(true);

        // Check if there are no items to be added
        if (!addItems || addItems.length === 0) {
            toast.error("No Customers added", {
                autoClose: 4000,
                position: "top-center",
                transition: Slide,
            });
            setIsLoading(false);
            return;
        }
        const items = await getCustomerData(addItems);
        const formattedData = convertToModel(items);

        const existingCustomerNumbers = (customerData[id] || []).map((item) => item.customerNumber);

        // Separate out duplicate and new customers
        const duplicateItems = formattedData.filter((item) => existingCustomerNumbers.includes(item.customerNumber));
        const newItemsList = formattedData.filter((item) => !existingCustomerNumbers.includes(item.customerNumber));

        // If all items are duplicates, show the toast and stop processing
        if (duplicateItems.length === formattedData.length) {
            toast.error(
                "Cannot add customers which are already added in to the customer grid!! please add a new customer.",
                {
                    autoClose: 5000,
                    position: "top-center",
                    transition: Slide,
                }
            );
            setIsLoading(false);
            return; // Stop further processing
        } else if (duplicateItems.length > 0) {
            // Notify user of the duplicates but continue processing new items
            const duplicateNumbers = duplicateItems.map((item) => item.customerNumber).join(", ");
            toast.error(`These customers are already added in to the customer grid: ${duplicateNumbers}`, {
                autoClose: 5000,
                position: "top-center",
                transition: Slide,
            });
        }

        // Process the new customers
        let newItems = newItemsList.map((item, index) => ({
            ...item,
            isPrimary: (customerData[id] || []).length === 0 && index === 0 ? true : false,
            quote: isDataFromOpenQuote ? quoteCustomer.quote : undefined,
        }));

        setCustomerData((prevCustomerData) => ({
            ...prevCustomerData,
            [id]: [...(prevCustomerData[id] || []), ...newItems],
        }));

        const combinedList = [...selectedCustomers, ...newItems];

        //setMainCustomer(newCustomerData[0].customerId);

        if (combinedList.length > 0) {
            setCustomerId(combinedList[0].customerId);
        }

        setSelectedCustomers(combinedList);
        updateQuoteDetailsForId(id, { selectedCustomers: combinedList });
        setQuoteUpdated(true);

        if (!firstCustomer[id]?.customer) {
            updateFirstCustomerForId(id, { ...firstCustomer, customer: combinedList[0] });
        }
        if (selectedCustomers.length < 2) {
            updateQuoteDetailsForId(id, {
                selectedquoteCustomers: combinedList[0]?.owner,
                quoteContactDetailsMobile: quoteContactDetailsMobile,
                quoteContact: currentSelectedContactTypeId === 7 ? combinedList[0]?.owner : quoteContact,//quoteContact,
                quoteDate: quoteDate,
            });
            setQuoteCustomerOwner(combinedList[0]?.owner);
            setQuoteUpdated(true);
        }
        setSelectedItems([]);
        setAddItems([]);
        //setMainCustomer(selectedCustomers[0].customerId);
        setIsLoading(false);
        closeDialog();
    };

    const handleAdd = () => {
        setDialogVisible(true);
    };

    const closeDialog = () => {
        setDialogVisible(false);

        setAddItems([]);
    };

    const openQuotesDialog = () => {
        setCustomerData({});

        setQuoteCustomer([]);

        setQuotesDialogVisible(true);
    };

    const openSystemSetting = () => {
        setSystemSettingFormVisible(true);
    };

    const flattenData = (data) => {
        if (!Array.isArray(data)) {
            return [];
        }

        return data.map((item) => {
            return {
                ...item,
                customerId: item.customerId || item.quoteCustomer?.customerId,
                customerNumber: item.customerNumber || item.quoteCustomer?.customerNumber,
                customerName: item.customerName || item.quoteCustomer?.customerName,
                contactFullName: item.contactFullName || item.quoteCustomer?.contactFullName,
                custClassification: item.custClassification || item.quoteCustomer?.custClassification,
                owner: item.owner || item.quoteCustomer?.owner,
                pricelistNotes: item.pricelistNotes || item.quoteCustomer?.pricelistNotes,
                comments: item.comments || item.quoteCustomer?.comments,
            };
        });
    };

    const openContactEditForm = () => {
        setContactEditFormVisible(true);
    };

    const openSystemEditForm = () => {
        setSystemEditFormVisible(true);
    };

    const toggleEditForm = () => {
        setCustomerEditFormVisible(!customerEditFormVisible);
    };

    const handleEditChange = ({ dataItem, field, value }) => {
        let newData = [...(customerData[id] || [])];

        let item = newData.find((item) => item.customerId === dataItem.customerId);

        item[field] = value;

        const isLastRow = newData.indexOf(item) === newData.length - 1;

        if (isLastRow) {
            setEditingLastRow(false);
        }

        if (newData.length > 0 && newData[0].customerId === dataItem.customerId) {
            newData[0].isPrimary = true;
        }

        setCustomerData((prevCustomerData) => ({
            ...prevCustomerData,

            [id]: newData,
        }));
    };

    let newCustomerList;

    const handleRemove = (e) => {
        SetLoadStatus(true);
        setIsRemoveItemsClicked(true);
        setIsQuoteSaved(false);

        if (selectedRows.length > 0) {
            const selectedCustomer = selectedRows[0];

            if (!selectedCustomer.customerId) {
                toast.error("Please select at least one Customer!", { autoClose: 3000, closeOnClick: true, position: "top-center", transition: Slide, });
            } else {
                confirmAlert({
                    title: "Confirm to submit",
                    message: "Are you sure you want to delete the customer?",
                    buttons: [
                        {
                            label: "Yes",
                            onClick: () => {
                                const customerNumber = selectedCustomer.customerNumber;
                                const currentUserId = user?.homeAccountId;

                                putResponse(
                                    `Customer/removecommentbyuserspecific?customerNumber=${customerNumber}&currentUserID=${currentUserId}`
                                )
                                    .then((res) => {
                                        if (res.statusCode || res.message == "0") {
                                            const newCustomerList = customerData[id].filter((c) => {
                                                return c.customerNumber !== selectedCustomer.customerNumber;
                                            });

                                            setCustomerData((prevCustomerData) => ({
                                                ...prevCustomerData,
                                                [id]: newCustomerList,
                                            }));
                                            
                                            updateQuoteDetailsForId(id, { selectedCustomers: newCustomerList });
                                            (newCustomerList.length > 0 ? setCustomerId(newCustomerList[0].customerId) : <></>);
                                            setIsCustomerRemoved(true);

                                            if (selectedCustomer === customerData[id][0]) {
                                                // If another customer exists, update the customerId with its id
                                                if (newCustomerList.length > 0) {
                                                    setMainCustomer(newCustomerList[0]?.customerId);

                                                    // Make the new first customer as primary
                                                    newCustomerList[0].isPrimary = true;
                                                }
                                            }

                                            setSelectedRows([]); // Reset selected rows
                                        } else {
                                            toast.error("Customer not found. Please try again later.", {
                                                position: "top-center",
                                                transition: Slide,
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        console.error("Customer not found:", error);
                                        toast.error("Customer not found.", {
                                            position: "top-center",
                                            transition: Slide,
                                        });
                                    });
                            },
                        },
                        {
                            label: "No",
                            onClick: () => { },
                        },
                    ],
                });
            }
        } else {
            toast.error("Please select at least one Customer!", { autoClose: 3000, closeOnClick: true, position: "top-center", transition: Slide, });
        }
    };

    const calculateDcOnLp = (listPrice, nettEA) => {
        return parseInt(listPrice) === 0 ? 0.0 : parseFloat((((listPrice - nettEA) / listPrice) * 100).toFixed(2));
    };

    const calculateGroupWiseDcOnLp = (itemList, type) => {
        const groupedData = {};

        // if (type === "group") {
        if (type === GroupTypes.GROUP) {
            itemList.forEach((item) => {
                const { groupName, subGroupName, displaySubGroupName, displayGroupName, listPrice, nettEA } = item;

                if (groupName && displayGroupName !== "") {
                    if (!groupedData[groupName]) {
                        groupedData[groupName] = { listPrice: 0, nettEA: 0, dcOnLp: 0 };
                    }
                    (groupedData[groupName].listPrice = listPrice), (groupedData[groupName].nettEA = nettEA);
                }
            });

            for (const groupName in groupedData) {
                const group = groupedData[groupName];
                group.dcOnLp = calculateDcOnLp(group.listPrice, group.nettEA);
            }
        } else {
            itemList.forEach((item) => {
                const { groupName, subGroupName, displaySubGroupName, listPrice, nettEA } = item;

                if (groupName !== "" && subGroupName !== "" && displaySubGroupName !== "") {
                    if (!groupedData[subGroupName]) {
                        groupedData[subGroupName] = { listPrice: 0, nettEA: 0, dcOnLp: 0, groupName: "" };
                    }
                    (groupedData[subGroupName].listPrice = listPrice),
                        (groupedData[subGroupName].nettEA = nettEA),
                        (groupedData[subGroupName].groupName = groupName);
                }
            });

            for (const subGroupName in groupedData) {
                const subGroup = groupedData[subGroupName];
                subGroup.dcOnLp = calculateDcOnLp(subGroup.listPrice, subGroup.nettEA);
            }
        }

        return groupedData;
    };

    const calculateMargin = (groupWiseNettEa, groupWisestEecost) => {
        return parseFloat(groupWiseNettEa) === 0.0
            ? 0.0
            : parseFloat(
                (
                    ((parseFloat(groupWiseNettEa) - parseFloat(groupWisestEecost)) / parseFloat(groupWiseNettEa)) *
                    100
                ).toFixed(2)
            );
    };

    const calculateGroupWiseMargin = (itemList, type) => {
        const groupedData = {};

        // if (type === "group") {
        if (type === GroupTypes.GROUP) {
            itemList.forEach((item) => {
                const { groupName, subGroupName, displaySubGroupName, displayGroupName, nettEA, stEecost } = item;

                if (groupName && displayGroupName !== "") {
                    if (!groupedData[groupName]) {
                        groupedData[groupName] = { nettEA: 0, stEecost: 0, margin: 0 };
                    }
                    (groupedData[groupName].nettEA = nettEA), (groupedData[groupName].stEecost = stEecost);
                }
            });

            for (const groupName in groupedData) {
                const group = groupedData[groupName];
                group.margin = calculateMargin(group.nettEA, group.stEecost);
            }
        } else {
            itemList.forEach((item) => {
                const { groupName, subGroupName, displaySubGroupName, nettEA, stEecost } = item;

                if (groupName !== "" && subGroupName !== "" && displaySubGroupName !== "") {
                    if (!groupedData[subGroupName]) {
                        groupedData[subGroupName] = { nettEA: 0, stEecost: 0, margin: 0, groupName: "" };
                    }
                    (groupedData[subGroupName].nettEA = nettEA),
                        (groupedData[subGroupName].stEecost = stEecost),
                        (groupedData[subGroupName].groupName = groupName);
                }
            });

            for (const subGroupName in groupedData) {
                const subGroup = groupedData[subGroupName];
                subGroup.margin = calculateMargin(subGroup.nettEA, subGroup.stEecost);
            }
        }

        return groupedData;
    };

    const deleteDraftQuote = async (draftQuoteId) => {

        try {
            // Replace with the correct endpoint and method to delete the draft quote
            const response = await deleteResponse(`QuoteDraft/deleteDraftquote`, { quoteId: draftQuoteId });

            if (response) {
                console.log("The Draft Quote has been Deleted Successfully.");


            } else {
                console.error("Failed to delete the draft quote.");
                return;
            }
        }
        catch (error) {
            console.error("Failed to delete the draft quote.");
            return;
        }

    };


    const handleSave = async (updatedData = {}) => {
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (Object.keys(updatedData).length === 0) return;
        setIsQuoteSaved(true);
        setQuoteUpdated(false);
        //const draftQuoteId = quoteDetails[id]?.savedQuote?.quoteId;
        ////Temporary commented parked the code for future use
        // //#region approval worlflow new quote from rejected
        // const { oldQuoteId, newQuoteId } = triggerSaveNewQuoteFromRejected;
        // //#endregion
        //// end of Temporary commented parked the code for future use
        // (newQuoteId === undefined || newQuoteId === null) ? await deleteDraftQuote(draftQuoteId) : null; //add validation to not triggere if saving new quote from rejected
        if ((currentDraftQuoteId == "" || currentDraftQuoteId == null || currentDraftQuoteId == undefined) && (currentQuoteId === "" || currentQuoteId === null || currentQuoteId === undefined)) {
            toast.error("Sorry! Cannot save without a customer and items. Please add a customer and items to the quote and try again.", {
                position: "top-center",
                transition: Slide,
            });
            setIsSaveClicked(false);
            setIsSaveAs(false);
            setRevision(false);
            setSuffix(false);
            return;
        }
        if ((currentQuoteId === "" || currentQuoteId === null || currentQuoteId === undefined)) {
            if(currentDraftQuoteId !== "" && currentDraftQuoteId !== null && currentDraftQuoteId !== undefined){
            await deleteDraftQuote(currentDraftQuoteId);
            }
            //setdraftcurrentQuoteId("");
        }

        const sessiondataItemsKey = `sessionTerms_${id}`;
        const combinedItemsKey = `combinedItems_${id}`;
        const storedAppendedNodes = isDoneChangesForQuoteTerms == true ? sessionStorage.getItem(sessiondataItemsKey) : null;
        const storedPdfAppendedNodes = isDoneChangesForQuoteTerms == true ? sessionStorage.getItem(combinedItemsKey) : null;



        // const quoteItems = quoteDetails[id]?.selectedItems;
        const quoteItems = quoteDetails[id]?.selectedItems.filter((item) => item.rowType !== "");
        const filteredQuotes = quoteItems;
        // &&-
        // quoteItems.length > 0 &&
        // quoteItems.filter((quoteItem) => {
        //     return (
        //         quoteItem.displayGroupName?.split(" ")[0] !== "Group" &&
        //         quoteItem.displaySubGroupName?.split(" ")[0] !== "Sub" &&
        //         quoteItem.displayGroupName?.split(" ")[0] !== "Non"
        //     );
        // });

        if (filteredQuotes.length === 0) {
            toast.error("Sorry! Cannot save without a customer and items. Please add a customer and items to the quote and try again.", {
                position: "top-center",
                transition: Slide,
            });
            setIsSaveClicked(false);
            setIsSaveAs(false);
            setRevision(false);
            setSuffix(false);
            return;
        }

        if (isLoading) {
            //toast.info("Another request is in progress. Please wait.");
            setIsSaveClicked(false);
            setIsSaveAs(false);
            setRevision(false);
            setSuffix(false);
            return;
        }

        if (customerData[id]?.length <= 0) {
            toast.error("Sorry! Cannot save without a customer and items. Please add a customer and items to the quote and try again.", {
                position: "top-center",
                transition: Slide,
            });

            setIsSaveClicked(false);
            return;
        }

        if (!customerData[id]?.some((item) => item?.isPrimary == true)) {
            toast.error("Please select a quote customer and try again.", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        // You need to iterate over all customers from customerData for the current subNodeTitle.
        const quoteCustomers = quoteCustomer.length > 0 ? quoteCustomer : customerData[id] || [];

        const updatedDataIndex = quoteCustomers.findIndex((item) => item.customerId === updatedData.customerId);

        if (updatedDataIndex !== -1) {
            quoteCustomers[updatedDataIndex] = { ...quoteCustomers[updatedDataIndex], ...updatedData };
        }

        ////Temporary commented parked the code for future use
        // //#region approval worlflow new quote from rejected
        //  const qteId = ((newQuoteId !== undefined || newQuoteId !== null) && newQuoteId !== oldQuoteId) ? newQuoteId : id;
        // //#endregion
        //// end of Temporary commented parked the code for future use

        let storedCategory = sessionStorage.getItem(`category_${id}`);
        let storedHeader = sessionStorage.getItem(`header_${id}`);

        let storedCategoryId;
        let storedHeaderId;

        if (storedCategory === "General Quote") {
            storedCategoryId = 1;
        } else if (storedCategory === "DB Quote") {
            storedCategoryId = 2;
        } else if (storedCategory === "Techno Quote") {
            storedCategoryId = 3;
        } else if (storedCategory === "Busduct Quote") {
            storedCategoryId = 4;
        } else if (storedCategory === "Automation & Control") {
            storedCategoryId = 6;
        }

        if (isCategoryChanged) {
            switch (quoteDetails[id]?.quoteType) {
                case "General Quote":
                    storedCategoryId = 1;
                    break;
                case "DB Quote":
                    storedCategoryId = 2;
                    break;
                case "Techno Quote":
                    storedCategoryId = 3;
                    break;
                case "Busduct Quote":
                    storedCategoryId = 4;
                    break;
                case "Automation & Control":
                    storedCategoryId = 6;
                    break;
            }
        }

        switch (storedHeader) {
            case "IPD":
                storedHeaderId = 1;
                break;
            case "PJW":
                storedHeaderId = 2;
                break;
            case "Services":
                storedHeaderId = 3;
                break;
        }

        if (isHeaderChanged) {
            switch (quoteDetails[id]?.header) {
                case "IPD":
                    storedHeaderId = 1;
                    break;
                case "PJW":
                    storedHeaderId = 2;
                    break;
                case "Services":
                    storedHeaderId = 3;
                    break;
            }
        }


        //#region dot not allow to save quote if its already been sent for approval = 2, approved = 4 and rejected = 5
        try {
            if ((saveAsNewFlag === 1 || revision || suffix || (triggerSaveQteForApproval != null || triggerSaveQteForApproval?.length > 0))) console.log("skip checking for this process...");
            else {
                const qid = currentQuoteId === "" || currentQuoteId === null || currentQuoteId === undefined ? id : currentQuoteId;
                const custData = await getResponse("Quote/getfilteredquotecustomers", { quoteId: qid });
                if(custData !== null){
                    if (custData?.quote !== null) {
                        if (custData?.quote?.approvalStatusId !== null) {
                            if (custData?.quote.approvalStatusId === 2 || custData?.quote.approvalStatusId === 4 || custData?.quote.approvalStatusId === 5) {
                                if (!checkTriggerExport)
                                {
                                    const msg = custData?.quote.approvalStatusId === 2 
                                        ? "sent for approval" 
                                        : custData?.quote.approvalStatusId === 4 
                                            ? "Approved"
                                            : "Rejected";
                                    toast.error(`Sorry! Cannot save the quote as it is already ${msg}.`, {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                }
                                setIsSaveClicked(false);
                                setIsSaveAs(false);
                                setRevision(false);
                                setSuffix(false);
                                return;
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Error checking the quote for approval status:", error);
        }
        
        //#endregion


        if (currentQuoteId === "" || currentQuoteId === null || currentQuoteId === undefined) {
            const convertedQuoteDate = moment(quoteDate).tz("Australia/Sydney").format();
            const quote = {

                quoteId: id,
                quoteNumber: id,
                amount: totalItemPrice,
                cost: totalCostAmt,
                margin: maxMargin,
                quoteDate: new Date(quoteDate),
                owner: "",
                createdOn: new Date(),
                modifiedOn: new Date(),
                parentQuoteNo: "",
                quoteType: 0,
                quoteTermsAndConditions : storedAppendedNodes,
                pdfQuoteTermsAndConditions : storedPdfAppendedNodes,
                projectName: projectName,
                qttype:
                    quoteDetails[id].quoteType ? storedCategoryId : storedCategoryId && categoryList?.length > 0
                        ? storedCategoryId
                        : 1,
                headerName:
                    storedHeaderId && headerList?.length > 0
                        ? storedHeaderId
                        : 1,
                createdBy: user?.homeAccountId,
                modifiedBy: user?.homeAccountId,
                createdUserEmail: "",
                createdUserName: "",
                modifiedUserName: "",
                modifiedUserEmail: "",
                isDeleted: false,
                previousQuoteRef: null,
                quoteContact: isDoneButtonClicked ? quoteDetails[id]?.quoteContact : currentSelectedContactTypeId === 5 ? quoteDetails[id]?.quoteContact : currentSelectedContactTypeId === 7 && (quoteDetails[id]?.quoteNumber === undefined || quoteDetails[id]?.quoteNumber.startsWith("Draft")) ? quoteDetails[id]?.selectedquoteCustomers : quoteDetails[id]?.quoteContact,//quoteDetails[id]?.quoteContact,
                quoteContactDetailsMobile: quoteContactDetailsMobile,
                isActive: true,
                approvalStatusId: quoteApprovalStatId
            };

            const dataToSend = {
                quote: quote,
                quoteCustomers: customerData[id],
                quoteItems: filteredQuotes,
                userId: user?.homeAccountId,
                timeZone: clientTimezone,
            };

            const formattedData = getQuoteDataModel(dataToSend);

            setIsLoading(true);
            setIsSaveTriggered(true);
            setIsdraftToOriginal(false);
            //setIsSavedUpdated(false);

            postResponse("Quote/createnewquote", formattedData)
                .then((res) => {
                    if (res.statusCode) {
                        setcurrentQuoteId(res.data.quoteId);
                        setSelectedCustomers(res.data.tblQuoteCustomers);
                        setProjectName(res.data.projectName);
                        setHeader(headerList.filter((item) => item.qhId === res.data.headerName)[0].headerName);
                        setQuoteDate(new Date(res.data.quoteDate));
                        setCategory(
                            categoryList.filter((item) => {
                                return item.qtId === res.data.qttype;
                            })[0].qtType
                        );
                        setQuote(res.data);

                        setQuoteNumber(res.data?.quoteNumber);
                        // setQuoteContact(res.data.quoteContact);
                        setQuoteContact(res.data?.quoteContact);
                        //   setQuoteCreatedbyUser(res.data?.createdBy);
                        //added to load the items data grid when creating a quote

                        const sortedTblQuoteItems = sortItemListByPosition(res.data?.tblQuoteItems);
                        let assemblyId = uuidv4();
                        let groupAssembly = 1;
                        let assmGrpName = "ASSEMBLY " + groupAssembly;
                        let oldStkCode = '';
                        let parentId = null;
                        let treeId = null;
                        let retValue = {};
                        const updatedQuoteItems = sortedTblQuoteItems.map((item) => {
                            let getItemDtl = quoteDetails[res.data.quoteId].selectedItems.filter((x) => x.stStockCode === item.stStockCode);
                            treeId = uuidv4();
                            if (item.rowType === "DX") {
                                retValue = {
                                    ...item,
                                    listPrice: "",
                                    l60: "",
                                    l55: "",
                                    l50: "",
                                    l20: "",
                                    stEecost: "",
                                    stEecostRound: "",
                                    stFutureCost: "",
                                    stFutureCostRound: "",
                                    actCost: "",
                                    actCostExact: "",
                                    frcMargin: "",
                                    totalCost: "",
                                    lastCost: "",
                                    lastCostExact: "",
                                    //added later
                                    margin: "",
                                    actMargin: "",
                                    lcMargin: "",
                                    repDisc: "",
                                    assemblyGroupName: "",
                                    displayAssemblyGroupName: "",
                                    id: treeId,
                                    parentId: parentId
                                };
                            } else if (item.rowType === "A") {
                                ///reset assembly items
                                assemblyId = (item.assembly === undefined) ? uuidv4()
                                    : item.assemblyId.length === 0 ? uuidv4()
                                        : item.assemblyId;
                                assmGrpName = "ASSEMBLY " + groupAssembly;
                                groupAssembly += 1;
                                retValue = {
                                    ...item,
                                    assemblyId: assemblyId,
                                    oldStockCode: item?.oldStockCode,
                                    stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                                    // oldStockCode: getItemDtl[0]?.oldStockCode,
                                    // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                                    id: treeId,
                                    parentId: parentId
                                };
                            } else if (item.rowType === "AI") {
                                ///reset assembly items
                                retValue = {
                                    ...item,
                                    nettEA: "",
                                    l60: "",
                                    l55: "",
                                    l50: "",
                                    l20: "",
                                    totalPrice: "",
                                    margin: "",
                                    actMargin: "",
                                    frcMargin: "",
                                    lcMargin: "",
                                    dcOnLp: "",
                                    listPrice: "",
                                    lastCost: "",
                                    assemblyId: assemblyId,
                                    assemblyGroupName: assmGrpName,
                                    displayAssemblyGroupName: "",
                                    oldStockCode: item?.oldStockCode,
                                    stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                                    // oldStockCode: getItemDtl[0]?.oldStockCode,
                                    // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                                    id: treeId,
                                    parentId: parentId
                                };
                            } else {
                                retValue = {
                                    ...item,
                                    assemblyGroupName: "",
                                    displayAssemblyGroupName: "",
                                    l60: !item.l60 ? "" : item?.l60,//!getItemDtl[0]?.l60 ? "" : getItemDtl[0]?.l60,
                                    l55: !item.l55 ? "" : item?.l55,//!getItemDtl[0]?.l55 ? "" : getItemDtl[0]?.l55,
                                    l50: !item?.l50 ? "" : item?.l50,//!getItemDtl[0]?.l50 ? "" : getItemDtl[0]?.l50,
                                    l20: !item.l20 ? "" : item?.l20,//!getItemDtl[0]?.l20 ? "" : getItemDtl[0]?.l20,
                                    oldStockCode: item.oldStockCode,//getItemDtl[0]?.oldStockCode,
                                    stkWithDiffSPPrice: item?.stkWithDiffSPPrice,//getItemDtl[0]?.stkWithDiffSPPrice,
                                    isDisabled: item?.isDisabled,//getItemDtl[0]?.isDisabled,
                                    id: treeId,
                                    parentId: item.rowType === "G"
                                        ? null
                                        : parentId
                                }
                            }

                            parentId = item.rowType === "G" || item.rowType === "SG"
                                ? treeId
                                : !item.groupName.startsWith("Group")
                                    ? null
                                    : parentId;
                            return retValue;

                            // return item;
                        });

                        //updating the context once the quote is created
                        updateQuoteDetailsForId(id, {
                            quoteId: res.data.quoteId,
                            quoteType: categoryList.filter((item) => {
                                return item.qtId === res.data.qttype;
                            })[0].qtType,
                            header: headerList.filter((item) => {
                                return item.qhId === res.data.headerName;
                            })[0].headerName,
                            projectName: res.data.projectName,
                            quoteContact: res.data.quoteContact,
                            quoteTermsAndConditions : res.data.quoteTermsAndConditions,
                            pdfQuoteTermsAndConditions : res.data.pdfQuoteTermsAndConditions,

                            quoteDate: new Date(res.data.quoteDate),
                            quoteContactDetailsMobile: quoteContactDetailsMobile,
                            selectedCustomers: res.data.tblQuoteCustomers, // tblQuoteCustomer type
                            savedQuote: res.data,
                            // selectedItems: sortedTblQuoteItems,
                            selectedItems: updatedQuoteItems,
                            //  quoteContact:res.data.quoteContact
                        });
                        setQuoteUpdated(false);
                        // onDraftStatusChange(false);
                        // setIsQuoteSave(true);
                        // isSavedQuoteChange(true);
                        handleNodeWhenSaving(quotes, setQuotes, res.data.quoteId, res.data.quoteNumber);
                        setVisibleSaveAs(true);
                        toast.success("The Quote has been Created Successfully", {
                            position: "top-center",
                            transition: Slide,
                        });
                        if (isSaveClicked) {
                            setIsSaved(true);
                        }

                        return;
                    }
                    // else if (res.message === "A similar request is already being processed. Please wait.") {

                    //     toast.info("A  request is already being processed. Please wait.", {
                    //         position: "top-center",
                    //         transition: Slide,
                    //     });                
                    //     return;
                    // }
                    else {
                        // toast.error("failed to save the quote. Please try again later.", {
                        //     position: "top-center",
                        //     transition: Slide,
                        // });                
                        return;
                    }
                })

                .catch((error) => {
                    //console.error("Failed to save data:", error);
                    // toast.error("Connection error. Please try again later.", {
                    //     position: "top-center",
                    //     transition: Slide,
                    // });            
                    return;
                })

                .finally(() => {
                    setIsLoading(false); // Set loading back to false after request completes (either success or error)
                    setIsSaveTriggered(false);
                    setIsQuoteSaved(false);

                });

        } else {
            if (!customerData[id]?.some((item) => item?.isPrimary == true)) {
                toast.error("Please select a base customer and try again.", {
                    position: "top-center",
                    transition: Slide,
                });
                return;
            }

            const convertedQuoteDate = moment(quoteDate).tz("Australia/Sydney").format();
            const convertedquoteDateCreatedOn = moment(quoteDateCreatedOn).tz("Australia/Sydney").format();
            //const combinedItemsKey = `combinedItems_${currentQuoteId}`;
            const savedQuote = {
                quoteId: currentQuoteId,
                quoteNumber: quoteDetails[id]?.savedQuote?.quoteNumber
                    ? quoteDetails[id]?.savedQuote?.quoteNumber
                    : quote?.quoteNumber,
                amount: totalItemPrice,
                cost: totalCostAmt,
                margin: maxMargin,
                quoteDate: quoteDate === '' ? new Date(quoteDateCreatedOn) : new Date((quoteDetails[id]?.quoteDate)),
                owner: quote?.owner,
                parentQuoteNo: quote?.parentQuoteNo,
                quoteType: quote?.quoteType,
                projectName: projectName,
                //quoteTermsAndConditions :  storedAppendedNodes !== null ? storedAppendedNodes :  quoteDetails[currentQuoteId]?.quoteTermsAndConditions,
                quoteTermsAndConditions : storedAppendedNodes !== null ? storedAppendedNodes : quoteDetails[currentQuoteId]?.quoteTermsAndConditions,
                pdfQuoteTermsAndConditions : storedPdfAppendedNodes !== null ? storedPdfAppendedNodes : quoteDetails[currentQuoteId]?.pdfQuoteTermsAndConditions,
                qttype:
                    category && categoryList?.length > 0
                        ? categoryList.filter((item) => item.qtType === category)[0].qtId
                        : 1,
                headerName:
                    header && headerList?.length > 0
                        ? headerList.filter((item) => item.headerName === header)[0].qhId
                        : 1,
                createdBy: suffix || revision ? user?.homeAccountId : quote?.createdBy,
                modifiedBy: suffix || revision ? user?.homeAccountId : quote?.modifiedBy,
                createdUserEmail: quote?.createdUserEmail,
                createdUserName: quote?.createdUserEmail,
                modifiedUserName: quote?.modifiedUserName,
                modifiedUserEmail: quote?.modifiedUserEmail,
                isDeleted: quote?.isDeleted,
                previousQuoteRef: quote?.previousQuoteRef,
                isActive: typeof quote?.isActive === "undefined" ? quote?.isActve : quote?.isActive,  ///// this is temporarily added as a checker because some object specially on the draft saving used without " i ".
                quoteContact: isDoneButtonClicked ? quoteDetails[id]?.quoteContact : currentSelectedContactTypeId === 5 ? quoteDetails[id]?.quoteContact : currentSelectedContactTypeId === 7 && quoteDetails[id]?.selectedquoteCustomers && (quoteDetails[id]?.quoteNumber === undefined || quoteDetails[id]?.quoteNumber.startsWith("Draft")) ? quoteDetails[id]?.selectedquoteCustomers : quoteDetails[id]?.quoteContact,//quoteDetails[id]?.quoteContact,
                quoteContactDetailsMobile: quoteDetails[id]?.quoteContactDetailsMobile,
                stId: quote?.stId,
                originalQuoteId: quote?.originalQuoteId,
                // approvalStatusId: suffix || revision ? 1 : quoteApprovalStatId,
                approvalStatusId: quoteApprovalStatId,
            };

            const qt = quoteItems;
            setIsdraftToOriginal(false);
            setIsSavedUpdated(false);
            // &&
            // quoteItems.length > 0 &&
            // quoteItems.filter((quoteItem) => {
            //     return (
            //         quoteItem.displayGroupName?.split(" ")[0] !== "Group" &&
            //         quoteItem.displaySubGroupName?.split(" ")[0] !== "Sub" &&
            //         quoteItem.displayGroupName?.split(" ")[0] !== "Non"
            //     );
            // });

            if (saveAsNewFlag === 1 || revision || suffix) {
                if (saveAsNewFlag === 1) savedQuote.quoteNumber = "0000";
                savedQuote.createdBy = user?.homeAccountId;
                savedQuote.modifiedBy = user?.homeAccountId;
                savedQuote.createdUserEmail = user?.userName;
                savedQuote.createdUserName = user?.username;
                savedQuote.modifiedBy = '';
            }

            const dataToSend = {
                quote: savedQuote,
                quoteCustomers: customerData[id],
                quoteItems: qt,
                userId: user?.homeAccountId,
                timeZone: clientTimezone,
            };
            const formattedData = getQuoteDataModel(dataToSend);

            setIsLoading(true);

            //this used for revision and suffix    ///     postResponse(`Quote/UpdateQuoteWithPreviousQuoteRef?totalCost=${totalCost}&totalItemPrice=${totalItemPrice}`, formattedData)
            const url = `Quote/UpdateSaveAsQuoteWithPreviousQuoteRef?revision=${revision}&suffix=${suffix}`;
            let currProcessingQuoteId = "";
            let currProcessingState = {};
            if (revision == true || suffix == true || saveAsNewFlag === 1) {
                postResponse(url, formattedData)
                    .then((res) => {
                        if (res.statusCode) {
                            currProcessingQuoteId = res.data.quoteId;
                            currProcessingState = res.data;
                            setcurrentQuoteId(res.data.quoteId);
                            setSelectedCustomers(res.data.tblQuoteCustomers);
                            setProjectName(res.data.projectName);
                            setHeader(headerList.filter((item) => item.qhId === res.data.headerName)[0].headerName);
                            setQuoteDate(new Date(res.data.quoteDate));
                            setCategory(
                                categoryList.filter((item) => {
                                    return item.qtId === res.data.qttype;
                                })[0].qtType
                            );
                            setQuote(res.data);
                            setQuoteContact(res.data?.quoteContact);
                            setQuoteNumber(res.data?.quoteNumber);
                            // setQuoteContact(quoteContact);
                            setQuoteContactDetailsMobile(res.data.quoteContactDetailsMobile);
                            setQuoteContactDetailsMobile(quoteContactDetailsMobile);

                            //added to load the items data grid when creating a quote

                            const sortedTblQuoteItems = sortItemListByPosition(res.data?.tblQuoteItems);
                            let assemblyId = uuidv4();
                            let groupAssembly = 1;
                            let assmGrpName = "ASSEMBLY " + groupAssembly;
                            let retValue = {};
                            let parentId = null;
                            let treeId = null;
                            const updatedQuoteItems = sortedTblQuoteItems.map((item) => {
                                let getItemDtl = quoteDetails[id]?.selectedItems.filter((x) => x.stStockCode === item.stStockCode);
                                treeId = uuidv4();
                                if (item.rowType === "DX") {
                                    retValue = {
                                        ...item,
                                        listPrice: "",
                                        l60: "",
                                        l55: "",
                                        l50: "",
                                        l20: "",
                                        stEecost: "",
                                        stEecostRound: "",
                                        stFutureCost: "",
                                        stFutureCostRound: "",
                                        actCost: "",
                                        actCostExact: "",
                                        frcMargin: "",
                                        totalCost: "",
                                        lastCost: "",
                                        lastCostExact: "",
                                        //added later
                                        margin: "",
                                        actMargin: "",
                                        lcMargin: "",
                                        repDisc: "",
                                        assemblyGroupName: "",
                                        displayAssemblyGroupName: "",
                                        id: treeId,
                                        parentId: parentId
                                    };
                                } else if (item.rowType === "A") {
                                    ///reset assembly items
                                    assemblyId = (item.assembly === undefined) ? uuidv4()
                                        : item.assemblyId.length === 0 ? uuidv4()
                                            : item.assemblyId;
                                    assmGrpName = "ASSEMBLY " + groupAssembly;
                                    groupAssembly += 1;
                                    retValue = {
                                        ...item,
                                        assemblyId: assemblyId,
                                        assemblyGroupName: assmGrpName,
                                        displayAssemblyGroupName: assmGrpName,
                                        oldStockCode: item.oldStockCode,//getItemDtl[0]?.oldStockCode,
                                        stkWithDiffSPPrice: item.stkWithDiffSPPrice,//getItemDtl[0]?.stkWithDiffSPPrice,
                                        id: treeId,
                                        parentId: parentId
                                    };
                                } else if (item.rowType === "AI") {
                                    ///reset assembly items
                                    retValue = {
                                        ...item,
                                        assemblyId: assemblyId,
                                        assemblyGroupName: assmGrpName,
                                        displayAssemblyGroupName: "",
                                        oldStockCode: item?.oldStockCode,
                                        stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                                        // oldStockCode: getItemDtl[0]?.oldStockCode,
                                        // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                                        id: treeId,
                                        parentId: parentId
                                    };
                                } else {
                                    retValue = {
                                        ...item,
                                        assemblyGroupName: "",
                                        displayAssemblyGroupName: "",
                                        // l60: !getItemDtl[0]?.l60 ? "" : getItemDtl[0]?.l60,
                                        // l55: !getItemDtl[0]?.l55 ? "" : getItemDtl[0]?.l55,
                                        // l50: !getItemDtl[0]?.l50 ? "" : getItemDtl[0]?.l50,
                                        // l20: !getItemDtl[0]?.l20 ? "" : getItemDtl[0]?.l20,
                                        // oldStockCode: getItemDtl[0]?.oldStockCode,
                                        // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                                        // isDisabled: getItemDtl[0]?.isDisabled,
                                        l60: !item?.l60 ? "" : item?.l60,
                                        l55: !item?.l55 ? "" : item?.l55,
                                        l50: !item?.l50 ? "" : item?.l50,
                                        l20: !item?.l20 ? "" : item?.l20,
                                        oldStockCode: item?.oldStockCode,
                                        stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                                        isDisabled: item?.isDisabled,
                                        id: treeId,
                                        parentId: parentId
                                    }
                                }
                                parentId = item.rowType === "G" || item.rowType === "SG"
                                    ? treeId
                                    : !item.groupName.startsWith("Group")
                                        ? null
                                        : parentId;
                                return retValue;
                                //return item;
                            });

                            //updating the context once the quote is updated
                            updateQuoteDetailsForId(res.data.quoteId, {
                                quoteId: res.data.quoteId,
                                quoteTermsAndConditions : res.data.quoteTermsAndConditions,
                                pdfQuoteTermsAndConditions : res.data.pdfQuoteTermsAndConditions,
                                quoteType: categoryList.filter((item) => {
                                    return item.qtId === res.data.qttype;
                                })[0].qtType,
                                header: headerList.filter((item) => {
                                    return item.qhId === res.data.headerName;
                                })[0].headerName,
                                projectName: res.data.projectName,
                                quoteContact: res.data.quoteContact,
                                quoteDate: quoteDateCreatedOn,
                                // quoteDate: new Date(res.data.quoteDate),
                                selectedCustomers: res.data.tblQuoteCustomers, // tblQuoteCustomer type
                                savedQuote: res.data,
                                // selectedItems: formattedList,
                                selectedItems: updatedQuoteItems, // changed here for testing purpose
                                //quoteContact:quoteContact
                            });
                            setQuoteUpdated(false);
                            // onDraftStatusChange(false);
                            // setIsQuoteSave(true);
                            handleNodeWhenUpdating(quotes, setQuotes, id, res.data.quoteId, res.data.quoteNumber);
                            // isSavedQuoteChange(true);
                            setIsSaveAs(false);
                            setRevision(false);
                            setSuffix(false);
                            setSaveAsNewFlag(0);
                            toast.success("The Quote has been Updated Successfully", {
                                position: "top-center",
                                transition: Slide,
                            });
                            if (isSaveClicked) {
                                setIsSaved(true);
                                setIsSaveAs(false);
                                setRevision(false);
                                setSuffix(false);
                                setSaveAsNewFlag(0);
                            }
                            return;
                        } else {
                            toast.error("Failed to update the Quote.", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                    })
                    .catch((error) => {
                        console.error("Failed to update data:", error);
                        toast.error("Connection error. Please try again later.", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    })
                    .finally(() => {
                        setIsLoading(false); // Set loading back to false after request completes (either success or error)
                        setIsSaveTriggered(false);
                        setSuffix(false);
                        setRevision(false);
                        setIsQuoteSaved(false);
                        navigate(`/quotes/${currProcessingQuoteId}`, { state: { currProcessingState } }, { replace: true });
                    });
            } else if (!revision && !suffix && triggerSaveQteForApproval != null) {
                triggerSaveQteForApproval.triggerSave = shareQuoteFlag === 1 ? false : true;
                if (!triggerSaveQteForApproval.triggerSave) {  //update for Share Quote
                    if (shareQuoteFlag === 1) {
                        try {
                            const email = await handleSendEmail(quoteDetails[id].savedQuote?.quoteId, 
                                triggerSaveQteForApproval.approvedUserId, true, triggerSaveQteForApproval.comment);
                            const msgTimeClose = {
                                autoClose: 5000,
                                closeOnClick: true,
                            }
                            if (shareQuoteFlag === 1) {
                                toast.success("The Quote has been Shared to the Selected Approver.", msgTimeClose);
       
                                 setIsLoading(false); // Set loading back to false after request completes (either success or error)
                                setIsSaveTriggered(false);
                                setIsQuoteSaved(false);
                                setQuoteUpdated(false);
                                setTimeout(() => {
                                    window.location.reload(true);
                                }, 3000);
                                
                            }
                          
                        } catch (error) {
                            console.error("Sharing quote failed:", error);
                            toast.error("Error executing share quote.", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                       
                    }
                    return;
                }
                postResponse(`Quote/updatequoteapprovalstatus`, formattedData)
                    .then((res) => {
                        if (res.statusCode) {
                            setcurrentQuoteId(res.data.quoteId);
                            setSelectedCustomers(res.data.tblQuoteCustomers);
                            setProjectName(res.data.projectName);
                            setHeader(headerList.filter((item) => item.qhId === res.data.headerName)[0].headerName);
                            setQuoteDate(new Date(res.data.quoteDate));
                            setCategory(
                                categoryList.filter((item) => {
                                    return item.qtId === res.data.qttype;
                                })[0].qtType
                            );
                            setQuote(res.data);
                            setQuoteContact(res.data?.quoteContact);
                            setQuoteNumber(res.data?.quoteNumber);
                            setQuoteContactDetailsMobile(res.data.quoteContactDetailsMobile);
                            //setQuoteContactDetailsMobile(quoteContactDetailsMobile);

                            //added to load the items data grid when creating a quote

                            const sortedTblQuoteItems = sortItemListByPosition(res.data?.tblQuoteItems);
                            let assemblyId = uuidv4();
                            let groupAssembly = 1;
                            let assmGrpName = "ASSEMBLY " + groupAssembly;
                            const updatedQuoteItems = sortedTblQuoteItems.map((item) => {
                                let getItemDtl = quoteDetails[id]?.selectedItems.filter((x) => x.stStockCode === item.stStockCode);
                                if (item.rowType === "DX") {
                                    return {
                                        ...item,
                                        listPrice: "",
                                        l60: "",
                                        l55: "",
                                        l50: "",
                                        l20: "",
                                        stEecost: "",
                                        stEecostRound: "",
                                        stFutureCost: "",
                                        stFutureCostRound: "",
                                        actCost: "",
                                        actCostExact: "",
                                        frcMargin: "",
                                        totalCost: "",
                                        lastCost: "",
                                        lastCostExact: "",
                                        //added later
                                        margin: "",
                                        actMargin: "",
                                        lcMargin: "",
                                        repDisc: "",
                                        assemblyGroupName: "",
                                        displayAssemblyGroupName: "",
                                    };
                                } else if (item.rowType === "A") {
                                    ///reset assembly items
                                    assemblyId = (item.assembly === undefined) ? uuidv4()
                                        : item.assemblyId.length === 0 ? uuidv4()
                                            : item.assemblyId;
                                    assmGrpName = "ASSEMBLY " + groupAssembly;
                                    groupAssembly += 1;
                                    return {
                                        ...item,
                                        assemblyId: assemblyId,
                                        assemblyGroupName: assmGrpName,
                                        displayAssemblyGroupName: assmGrpName,
                                        oldStockCode: item?.oldStockCode,
                                        stkWithDiffSPPrice: item?.stkWithDiffSPPrice
                                        // oldStockCode: getItemDtl[0]?.oldStockCode,
                                        // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
                                    };
                                } else if (item.rowType === "AI") {
                                    ///reset assembly items
                                    return {
                                        ...item,
                                        assemblyId: assemblyId,
                                        assemblyGroupName: assmGrpName,
                                        displayAssemblyGroupName: "",
                                        oldStockCode: item?.oldStockCode,
                                        stkWithDiffSPPrice: item?.stkWithDiffSPPrice
                                        // oldStockCode: getItemDtl[0]?.oldStockCode,
                                        // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
                                    };
                                } else {
                                    return {
                                        ...item,
                                        assemblyGroupName: "",
                                        displayAssemblyGroupName: "",
                                        // l60: !getItemDtl[0]?.l60 ? "" : getItemDtl[0]?.l60,
                                        // l55: !getItemDtl[0]?.l55 ? "" : getItemDtl[0]?.l55,
                                        // l50: !getItemDtl[0]?.l50 ? "" : getItemDtl[0]?.l50,
                                        // l20: !getItemDtl[0]?.l20 ? "" : getItemDtl[0]?.l20,
                                        // oldStockCode: getItemDtl[0]?.oldStockCode,
                                        // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                                        // isDisabled: getItemDtl[0]?.isDisabled,
                                        l60: !item?.l60 ? "" : item?.l60,
                                        l55: !item?.l55 ? "" : item?.l55,
                                        l50: !item?.l50 ? "" : item?.l50,
                                        l20: !item?.l20 ? "" : item?.l20,
                                        oldStockCode: item?.oldStockCode,
                                        stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                                        isDisabled: item?.isDisabled,
                                    }
                                }
                            });

                            //updating the context once the quote is updated
                            updateQuoteDetailsForId(res.data.quoteId, {
                                quoteId: res.data.quoteId,
                                quoteType: categoryList.filter((item) => {
                                    return item.qtId === res.data.qttype;
                                })[0].qtType,
                                quoteTermsAndConditions : res.data.quoteTermsAndConditions,
                                pdfQuoteTermsAndConditions : res.data.pdfQuoteTermsAndConditions,
                                header: headerList.filter((item) => {
                                    return item.qhId === res.data.headerName;
                                })[0].headerName,
                                projectName: res.data.projectName,
                                quoteContact: res.data.quoteContact,
                                quoteDate: new Date(res.data.quoteDate),
                                selectedCustomers: res.data.tblQuoteCustomers, // tblQuoteCustomer type
                                quoteContactDetailsMobile: res.data.quoteContactDetailsMobile,
                                savedQuote: res.data,
                                // selectedItems: formattedList,
                                selectedItems: updatedQuoteItems, // changed here for testing purpose
                                //quoteContact:quoteContact
                            });
                            handleNodeWhenUpdating(quotes, setQuotes, id, res.data.quoteId, res.data.quoteNumber);
                            if (isSaveClicked) {
                                setIsSaved(true);
                                setRevision(false);
                                setSuffix(false);
                            }
                            //creating approval audit log
                            const auditLog = {
                                quoteId: quoteDetails[id].savedQuote?.quoteId,
                                comment: triggerSaveQteForApproval.comment,
                                approvalStatusId: quoteApprovalStatId,
                                approvedUserId: triggerSaveQteForApproval.approvedUserId,
                                approvedDateTime: new Date(),
                                originalQuoteId: quoteDetails[id].savedQuote?.originalQuoteId,
                                userId: user?.homeAccountId,
                                id: uuidv4(),
                            };

                            const escalateApprvl = triggerSaveQteForApproval.escalateNotifToSalesAgent;
                            let retaudit = handleQuoteAuditTrail(auditLog,false,escalateApprvl);
                            setTriggerSaveQteForApproval({ triggerSave: false, quoteId: "", approvalStatusId: 0, comment: "", escalateNotifToSalesAgent : false});
                            const msgTimeClose = {
                                autoClose: 1000,
                                closeOnClick: true,
                            }
                            switch (quoteApprovalStatId) {
                                case 2:
                                    toast.success("The Quote has been Submitted for Approval", msgTimeClose, {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    break;
                                case 3:
                                    toast.success("The Quote has been Set as In Need Of Changes", msgTimeClose, {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    break;
                                case 4:
                                    toast.success("The Quote has been Approved", msgTimeClose, {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    break;
                                case 5:
                                    toast.success("The Quote has been Rejected", msgTimeClose, {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    break;
                            }
                            setRefreshStatus(true);
                        } else {
                            toast.error("Failed to update the Quote.", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                    })
                    .catch((error) => {
                        console.error("Failed to update data:", error);
                        toast.error("Failed to update data.", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    })
                    .finally(() => {
                        setIsLoading(false); // Set loading back to false after request completes (either success or error)
                        setIsSaveTriggered(false);
                        setIsQuoteSaved(false);
                        setQuoteUpdated(false);
                    });
            }
            else {
                postResponse(`Quote/UpdateQuoteWithPreviousQuoteRef`, formattedData)
                    .then((res) => {
                        if (res.statusCode) {
                            setcurrentQuoteId(res.data.quoteId);
                            setSelectedCustomers(res.data.tblQuoteCustomers);
                            setProjectName(res.data.projectName);
                            setHeader(headerList.filter((item) => item.qhId === res.data.headerName)[0].headerName);
                            setQuoteDate(new Date(res.data.quoteDate));
                            setCategory(
                                categoryList.filter((item) => {
                                    return item.qtId === res.data.qttype;
                                })[0].qtType
                            );
                            setQuote(res.data);
                            setQuoteContact(res.data?.quoteContact);
                            setQuoteNumber(res.data?.quoteNumber);
                            //setQuoteContact(res.data?.quoteContact);
                            setQuoteContactDetailsMobile(res.data.quoteContactDetailsMobile);
                            const combinedItemsKey = `combinedItems_${res.data.quoteId}`;
                            sessionStorage.setItem(combinedItemsKey, res?.data?.pdfQuoteTermsAndConditions);
                            //setQuoteContactDetailsMobile(quoteContactDetailsMobile);

                            //added to load the items data grid when creating a quote

                            const sortedTblQuoteItems = sortItemListByPosition(res.data?.tblQuoteItems);
                            let assemblyId = uuidv4();
                            let groupAssembly = 1;
                            let assmGrpName = "ASSEMBLY " + groupAssembly;
                            const updatedQuoteItems = sortedTblQuoteItems.map((item) => {
                                let getItemDtl = quoteDetails[id]?.selectedItems.filter((x) => x.stStockCode === item.stStockCode);
                                if (item.rowType === "DX") {
                                    return {
                                        ...item,
                                        listPrice: "",
                                        l60: "",
                                        l55: "",
                                        l50: "",
                                        l20: "",
                                        stEecost: "",
                                        stEecostRound: "",
                                        stFutureCost: "",
                                        stFutureCostRound: "",
                                        actCost: "",
                                        actCostExact: "",
                                        frcMargin: "",
                                        totalCost: "",
                                        lastCost: "",
                                        lastCostExact: "",
                                        //added later
                                        margin: "",
                                        actMargin: "",
                                        lcMargin: "",
                                        repDisc: "",
                                        assemblyGroupName: "",
                                        displayAssemblyGroupName: "",
                                    };
                                } else if (item.rowType === "A") {
                                    ///reset assembly items
                                    assemblyId = (item.assembly === undefined) ? uuidv4()
                                        : item.assemblyId.length === 0 ? uuidv4()
                                            : item.assemblyId;
                                    assmGrpName = "ASSEMBLY " + groupAssembly;
                                    groupAssembly += 1;
                                    return {
                                        ...item,
                                        assemblyId: assemblyId,
                                        assemblyGroupName: assmGrpName,
                                        displayAssemblyGroupName: assmGrpName,
                                        oldStockCode: item?.oldStockCode,
                                        stkWithDiffSPPrice: item?.stkWithDiffSPPrice
                                        // oldStockCode: getItemDtl[0]?.oldStockCode,
                                        // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
                                    };
                                } else if (item.rowType === "AI") {
                                    ///reset assembly items
                                    return {
                                        ...item,
                                        assemblyId: assemblyId,
                                        assemblyGroupName: assmGrpName,
                                        displayAssemblyGroupName: "",
                                        oldStockCode: item?.oldStockCode,
                                        stkWithDiffSPPrice: item?.stkWithDiffSPPrice
                                        // oldStockCode: getItemDtl[0]?.oldStockCode,
                                        // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
                                    };
                                } else {
                                    return {
                                        ...item,
                                        assemblyGroupName: "",
                                        displayAssemblyGroupName: "",
                                        l60: !item?.l60 ? "" : item?.l60,
                                        l55: !item.l55 ? "" : item?.l55,
                                        l50: !item?.l50 ? "" : item?.l50,
                                        l20: !item?.l20 ? "" : item?.l20,
                                        oldStockCode: item?.oldStockCode,
                                        stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                                        isDisabled: item?.isDisabled,
                                        // l60: !getItemDtl[0]?.l60 ? "" : getItemDtl[0]?.l60,
                                        // l55: !getItemDtl[0]?.l55 ? "" : getItemDtl[0]?.l55,
                                        // l50: !getItemDtl[0]?.l50 ? "" : getItemDtl[0]?.l50,
                                        // l20: !getItemDtl[0]?.l20 ? "" : getItemDtl[0]?.l20,
                                        // oldStockCode: getItemDtl[0]?.oldStockCode,
                                        // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                                        // isDisabled: getItemDtl[0]?.isDisabled,
                                    }
                                }

                                //return item;
                            });

                            //updating the context once the quote is updated
                            updateQuoteDetailsForId(res.data.quoteId, {
                                quoteId: res.data.quoteId,
                                quoteType: categoryList.filter((item) => {
                                    return item.qtId === res.data.qttype;
                                })[0].qtType,
                                header: headerList.filter((item) => {
                                    return item.qhId === res.data.headerName;
                                })[0].headerName,
                                quoteTermsAndConditions: res.data.quoteTermsAndConditions,
                                pdfQuoteTermsAndConditions: res.data.pdfQuoteTermsAndConditions,
                                projectName: res.data.projectName,
                                quoteContact: res.data.quoteContact,
                                quoteDate: new Date(res.data.quoteDate),
                                selectedCustomers: res.data.tblQuoteCustomers, // tblQuoteCustomer type
                                quoteContactDetailsMobile: res.data.quoteContactDetailsMobile,
                                savedQuote: res.data,
                                // selectedItems: formattedList,
                                selectedItems: updatedQuoteItems, // changed here for testing purpose
                                //quoteContact:quoteContact
                            });
                            // setQuoteUpdated(false);
                            // onDraftStatusChange(false);
                            // setsQuoteSave(true);
                            handleNodeWhenUpdating(quotes, setQuotes, id, res.data.quoteId, res.data.quoteNumber);
                            // isSavedQuoteChange(true);
                            toast.success("The Quote has been Updated Successfully", {
                                position: "top-center",
                                transition: Slide,
                            });
                            if (isSaveClicked) {
                                setIsSaved(true);
                                // setIsSaveAs(false);
                                setRevision(false);
                                setSuffix(false);
                            }
                            setIsQuoteSaved(false);
                            return;
                        }

                        // else if (res.message === "A similar request is already being processed. Please wait.") {

                        //     toast.info("A  request is already being processed. Please wait.", {
                        //         position: "top-center",
                        //         transition: Slide,
                        //     });                    
                        //     return;                           
                        // }
                        else {
                            toast.error("Failed to update the Quote.", {
                                position: "top-center",
                                transition: Slide,
                            });
                            setIsQuoteSaved(false);
                            return;
                        }
                    })
                    .catch((error) => {
                        console.error("Failed to update data:", error);
                        toast.error("Connection error. Please try again later.", {
                            position: "top-center",
                            transition: Slide,
                        });
                        setIsQuoteSaved(false);
                        return;
                    })
                    .finally(() => {
                        setIsLoading(false);// Set loading back to false after request completes (either success or error)
                        setIsSaveTriggered(false);
                        setIsQuoteSaved(false);
                        !hideTotalState[id] ? setQuoteUpdateTrigger(false) : setQuoteUpdateTrigger(true);
                        setIsQuoteSaved(false);
                        setQuoteUpdated(false);
                        setIsCategoryChanged(false);
                        setIsDoneChangesForQuoteTerms(false);
                        setIsHeaderChanged(false);
                    });
            }


        }
    };

    // useEffect(() => {
    //     if( saveQuotation !== 0 || selectedQuote){
    //     saveRef.current = handleSave;
    //     }
    // }, [handleSave, saveRef]);

    // useEffect(() => {
    //     if (isQuoteSave) {
    //         handleDraftSaveQuote();
    //     }
    // }, [isQuoteSave]); // Add other dependencies as needed

    const timeoutRef = useRef(null);

    const [isFirsttimeUnitAdded, setIsFirsttimeUnitAdded] = useState(false);

    useEffect(() => {
        updateQuoteDetailsForId(id, { quoteNumber: quoteNumber });
    }, [quoteNumber]);

    const isDraftSaved = useRef(false);

    useEffect(() => {
        const getQuoteDetailsFromSessionStorage = () => {
            const compressedQuoteDetailsString = sessionStorage.getItem('quoteDetails');
            if (compressedQuoteDetailsString) {
                const decompressedQuoteDetailsString = LZString.decompress(compressedQuoteDetailsString);
                try {
                    return JSON.parse(decompressedQuoteDetailsString);
                } catch (e) {
                    console.error('Error parsing decompressed quote details:', e);
                    return null;
                }
            }
            return null;
        };
        const handleBeforeUnload = async (event) => {
            if (!isDraftSaved.current) {
                isDraftSaved.current = true;
                await handleDraftSaveQuote();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);

            const quoteDetailsLocal = getQuoteDetailsFromSessionStorage();
            const quoQuotes = Object.values(quoteDetailsLocal || {}).filter(detail =>
                detail.savedQuote?.quoteNumber?.startsWith("QUO")
            );

            if (window.performance && quoteDetails.length === 0) {
                let navigationEntries = performance.getEntriesByType("navigation");
                if (navigationEntries.length > 0) {
                    let type = navigationEntries[0].type;
                    if (type === 'reload') {
                        console.log('This page was reloaded');

                        // Additional condition to check if navigating to the root URL
                        if (window.location.pathname === "/") {
                            sessionStorage.removeItem('quoteDetails');  // Remove item from sessionStorage
                            console.log('Navigating to root, clearing session storage');
                        }

                        navigate("/dashboard");
                        if (quoQuotes.length > 0) {
                            quoQuotes.forEach(quote => {
                                handleAddNode(
                                    quotes,
                                    setQuotes,
                                    maxQuoteIndex,
                                    setMaxQuoteIndex,
                                    quote.savedQuote?.quoteId,
                                    true,
                                    quote.savedQuote
                                );
                            });
                        }
                    } else {
                        console.log('This page was not reloaded');
                    }
                }
            }
        };
    }, [navigate, quotes, setQuotes, maxQuoteIndex, setMaxQuoteIndex, quoteDetails, handleAddNode]);

    useEffect(() => {
        return () => {
            if (quoteDetails[currentDraftQuoteId]?.savedQuote?.quoteNumber?.includes("Draft")) {
                handleDraftSaveQuote();

            }
        };

    }, [id]);
    const handleDraftSaveQuote = async () => {
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setIsFirsttimeUnitAdded(true);
        setQuoteUpdated(false);
        setIsQuoteSaved(false);
        //const quoteItems = quoteDetails[previousQuoteRef? previousQuoteRef :id]?.selectedItems;
        const quoteItems = quoteDetails[id]?.selectedItems;
        const quoteCustomers = quoteDetails[id]?.selectedCustomers;
        //const quoteCustomers = quoteDetails[previousQuoteRef? previousQuoteRef :id]?.selectedCustomers;
        onDraftStatusChange(true);
        const filteredDraftQuotes = !quoteItems ? quoteItems : quoteItems?.filter((f)=>f.rowType !== ""); //exclude the last empty row from saving. To eliminate errors when saving
        //if (!quoteUpdated) return; // Only proceed if there's an update

        const setDraftQuoteSaveFalse = () => {
            setIsDraftQuoteSave(false);
        };



        //Clear existing timeout when function is called
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        let storedCategory = sessionStorage.getItem(`category_${id}`);
        let storedHeader = sessionStorage.getItem(`header_${id}`);

        let storedCategoryId;
        let storedHeaderId;

        if (storedCategory === "General Quote") {
            storedCategoryId = 1;
        } else if (storedCategory === "DB Quote") {
            storedCategoryId = 2;
        } else if (storedCategory === "Techno Quote") {
            storedCategoryId = 3;
        } else if (storedCategory === "Busduct Quote") {
            storedCategoryId = 4;
        } else if (storedCategory === "Automation & Control") {
            storedCategoryId = 6;
        }

        if (isCategoryChanged) {
            switch (quoteDetails[id]?.quoteType) {
                case "General Quote":
                    storedCategoryId = 1;
                    break;
                case "DB Quote":
                    storedCategoryId = 2;
                    break;
                case "Techno Quote":
                    storedCategoryId = 3;
                    break;
                case "Busduct Quote":
                    storedCategoryId = 4;
                    break;
                case "Automation & Control":
                    storedCategoryId = 6;
                    break;
            }
        }

        switch (storedHeader) {
            case "IPD":
                storedHeaderId = 1;
                break;
            case "PJW":
                storedHeaderId = 2;
                break;
            case "Services":
                storedHeaderId = 3;
                break;
        }

        if (isHeaderChanged) {
            switch (quoteDetails[id]?.header) {
                case "IPD":
                    storedHeaderId = 1;
                    break;
                case "PJW":
                    storedHeaderId = 2;
                    break;
                case "Services":
                    storedHeaderId = 3;
                    break;
            }
        }

        const draftId = currentDraftQuoteId ? currentDraftQuoteId : id;
        const sessiondataItemsKey = `sessionTerms_${draftId}`;
        const combinedItemsKey = `combinedItems_${draftId}`;
        const storedAppendedNodes = isDoneChangesForQuoteTerms == true ? sessionStorage.getItem(sessiondataItemsKey) : null;
        const storedPdfAppendedNodes = isDoneChangesForQuoteTerms == true ? sessionStorage.getItem(combinedItemsKey) : null;


        // You need to iterate over all customers from customerData for the current subNodeTitle.
        //const quoteCustomers = quoteCustomer.length > 0 ? quoteCustomer : customerData[id] || [];
        //let updatedDataIndex = [];
        //if (quoteCustomers != [] || updatedData != undefined) {
        // const updatedDataIndex = quoteCustomers.findIndex((item) => item.customerId === updatedData.customerId);
        // //return;
        // //}
        // if (updatedDataIndex !== -1) {
        //     quoteCustomers[updatedDataIndex] = { ...quoteCustomers[updatedDataIndex], ...updatedData };
        // }
        if (currentDraftQuoteId === "" || currentDraftQuoteId === null || currentDraftQuoteId === undefined) {
            //const convertedQuoteDate = moment(quoteDate).tz("Australia/Sydney").format();
            const quote = {
                quoteId: id,
                quoteNumber: id,
                amount: totalItemPrice,
                cost: totalCostAmt,
                margin: maxMargin,
                quoteDate: new Date(quoteDate),
                owner: "",
                createdOn: new Date(),
                modifiedOn: new Date(),
                parentQuoteNo: "",
                quoteType: 0,
                projectName: projectName,
                quoteTermsAndConditions : storedAppendedNodes,
                pdfQuoteTermsAndConditions : storedPdfAppendedNodes,
                qttype:
                    storedCategoryId && categoryList?.length > 0
                        ? storedCategoryId
                        : 1,
                headerName:
                    storedHeaderId && headerList?.length > 0
                        ? storedHeaderId
                        : 1,
                createdBy: user?.homeAccountId,
                modifiedBy: user?.homeAccountId,
                createdUserEmail: "",
                createdUserName: "",
                modifiedUserName: "",
                modifiedUserEmail: "",
                isDeleted: false,
                previousQuoteRef: null,
                quoteContact: currentSelectedContactTypeId === 5 && !isDoneClickForPdf ? username : currentContact,
                quoteContactDetailsMobile: quoteContactDetailsMobile,
                isActve: true,
            };

            const dataToSend = {
                quote: quote,
                quoteDraftCustomers: customerData[id],
                quoteDraftItems: filteredDraftQuotes,
                clientTimezone: clientTimezone,
            };

            const formattedData = getDraftQuoteDataModel(dataToSend);

            //setIsLoading(true);

            postResponse("QuoteDraft/createdraftnewquote", formattedData)
                .then((res) => {
                    if (res.statusCode) {
                        setdraftcurrentQuoteId(res.data.quoteId);
                        setQuote(quoteDetails[id]);
                        setOldDraftQuote(res.data);

                        // setSelectedCustomers(res.data.tblQuoteCustomers_Drafts);
                        // setProjectName(res.data.projectName);
                        // setHeader(headerList.filter((item) => item.qhId === res.data.headerName)[0].headerName);
                        // setQuoteDate(new Date(res.data.quoteDate));
                        // setCategory(
                        //     categoryList.filter((item) => {
                        //         return item.qtId === res.data.qttype;
                        //     })[0].qtType
                        // );
                        // setQuote(res.data);

                        setQuoteNumber(res.data?.quoteNumber);
                        setQuoteContactDetailsMobile(res.data.quoteContactDetailsMobile);
                        setQuoteContact(res.data?.quoteContact);
                        // //added to load the items data grid when creating a quote

                        // const sortedTblQuoteItems = sortItemListByPosition(res.data?.tblQuoteItems_Drafts);
                        // let assemblyId = uuidv4();
                        // let oldStkCode = '';
                        // let groupAssembly = 1;
                        // let assmGrpName = "ASSEMBLY " + groupAssembly;
                        // const updatedQuoteItems = sortedTblQuoteItems.map((item) => {
                        //     let getItemDtl = quoteDetails[res.data.quoteId].selectedItems.filter((x) => x.stStockCode === item.stStockCode);
                        //     if (item.rowType === "DX") {
                        //         return {
                        //             ...item,
                        //             listPrice: "",
                        //             l60: "",
                        //             l55: "",
                        //             l50: "",
                        //             l20: "",
                        //             stEecost: "",
                        //             stEecostRound: "",
                        //             stFutureCost: "",
                        //             stFutureCostRound: "",
                        //             actCost: "",
                        //             actCostExact: "",
                        //             frcMargin: "",
                        //             totalCost: "",
                        //             lastCost: "",
                        //             lastCostExact: "",
                        //             //added later
                        //             margin: "",
                        //             actMargin: "",
                        //             lcMargin: "",
                        //             repDisc: "",
                        //             assemblyGroupName: "",
                        //             displayAssemblyGroupName: "",
                        //         };
                        //     } else if (item.rowType === "A") {
                        //         ///reset assembly items
                        //         assemblyId = (item.assembly === undefined) ? uuidv4()
                        //             : item.assemblyId.length === 0 ? uuidv4()
                        //                 : item.assemblyId;
                        //         assmGrpName = "ASSEMBLY " + groupAssembly;
                        //             groupAssembly +=1;
                        //         return {
                        //             ...item,
                        //             assemblyId: assemblyId,
                        //             assemblyGroupName: assmGrpName,
                        //             displayAssemblyGroupName: assmGrpName,
                        //             oldStockCode: getItemDtl[0]?.oldStockCode,
                        //             stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
                        //         };
                        //     } else if (item.rowType === "AI") {
                        //         ///reset assembly items
                        //         return {
                        //             ...item,
                        //             nettEA: "",
                        //             l60: "",
                        //             l55: "",
                        //             l50: "",
                        //             l20: "",
                        //             totalPrice: "",
                        //             margin: "",
                        //             actMargin: "",
                        //             frcMargin: "",
                        //             lcMargin: "",
                        //             dcOnLp: "",
                        //             listPrice: "",
                        //             lastCost: "",
                        //             assemblyId: assemblyId,
                        //             assemblyGroupName: assmGrpName,
                        //             displayAssemblyGroupName: "",
                        //             oldStockCode: getItemDtl[0]?.oldStockCode,
                        //             stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
                        //         };
                        //     } else {
                        //         return {
                        //             ...item,
                        //             assemblyGroupName:  "",
                        //             displayAssemblyGroupName: "",
                        //             oldStockCode: getItemDtl[0]?.oldStockCode,
                        //             stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
                        //         }
                        //     }

                        //     // return item;
                        // });

                        // //updating the context once the quote is created
                        // updateQuoteDetailsForId(id, {
                        //     quoteId: res.data.quoteId,
                        //     quoteType: categoryList.filter((item) => {
                        //         return item.qtId === res.data.qttype;
                        //     })[0].qtType,
                        //     header: headerList.filter((item) => {
                        //         return item.qhId === res.data.headerName;
                        //     })[0].headerName,
                        //     projectName: res.data.projectName,
                        //     quoteDate: new Date(res.data.quoteDate),
                        //     quoteContact: res.data.quoteContact,
                        //     quoteContactDetailsMobile: res.data.quoteContactDetailsMobile,
                        //     selectedContactOption : selectedContactOption,
                        //     selectedCustomers: res.data.tblQuoteCustomers_Drafts, // tblQuoteCustomer type
                        //     savedQuote: res.data,
                        //     // selectedItems: sortedTblQuoteItems,
                        //     selectedItems: updatedQuoteItems,
                        //     //  quoteContact:res.data.quoteContact
                        // });
                        //setQuoteUpdated(true);
                        updateQuoteDetailsForId(res.data.quoteId, {
                            quoteType: categoryList.filter((item) => {
                                return item.qtId === res.data.qttype;
                            })[0].qtType,
                            header: headerList.filter((item) => {
                                return item.qhId === res.data.headerName;
                            })[0].headerName,
                            quoteContactDetailsMobile: res.data.quoteContactDetailsMobile,
                            quoteNumber: res.data.quoteNumber,
                            savedQuote: res.data,
                            quoteContact: res.data.quoteContact,
                            quoteTermsAndConditions : res.data.quoteTermsAndConditions,
                            pdfQuoteTermsAndConditions : res.data.pdfQuoteTermsAndConditions,

                        });
                        handleNodeWhenSaving(quotes, setQuotes, res.data.quoteId, res.data.quoteNumber);
                        // toast.success("Auto Save in progress"); // Success toast
                        setIsDraftQuoteSave(true);
                        // Set a timeout to set it to false after 3 seconds
                        timeoutRef.current = setTimeout(setDraftQuoteSaveFalse, 2000);
                        // NotificationManager.info('Alert Message', 'Auto Save in progress', 3000);
                        setQuoteUpdated(false);
                    } else {
                        // toast.error("Failed to auto save the Quote.", {
                        //     position: "top-center",
                        //     transition: Slide,
                        // }); // Error toast for non-successful status code
                    }
                })
                .catch((error) => {
                    console.error("Failed to save data:", error);
                    //toast.error("Connection error. Please try again later.");
                    return;
                })
                .finally(() => {
                    setIsLoading(false); // Set loading back to false after request completes (either success or error)
                    setIsDraftQuote(false);

                });
        } else {



            //const convertedQuoteDate = moment(quoteDate).tz("Australia/Sydney").format();

            const savedQuote = {
                quoteId: currentDraftQuoteId,
                quoteNumber: quoteDetails[currentDraftQuoteId]?.savedQuote?.quoteNumber
                    ? quoteDetails[currentDraftQuoteId]?.savedQuote?.quoteNumber
                    : oldDraftQuote?.quoteNumber,
                amount: quote?.amount === undefined ? 0 : quote?.amount,
                cost: quote?.cost === undefined ? 0 : quote?.cost,
                margin: quote?.margin === undefined ? 0 : quote?.margin,
                quoteDate: new Date(quoteDate),
                owner: quote?.owner,
                parentQuoteNo: oldDraftQuote?.parentQuoteNo,
                quoteType: 0,//quote?.quoteType,
                projectName: projectName,
                quoteTermsAndConditions : storedAppendedNodes !== null ? storedAppendedNodes : quoteDetails[currentDraftQuoteId]?.quoteTermsAndConditions,
                pdfQuoteTermsAndConditions : storedPdfAppendedNodes !== null ? storedPdfAppendedNodes : quoteDetails[currentDraftQuoteId]?.pdfQuoteTermsAndConditions,
                qttype:
                    category && categoryList?.length > 0
                        ? categoryList.filter((item) => item.qtType === category)[0].qtId
                        : 1,
                headerName:
                    header && headerList?.length > 0
                        ? headerList.filter((item) => item.headerName === header)[0].qhId
                        : 1,
                createdBy: user?.homeAccountId,
                modifiedBy: user?.homeAccountId,
                createdUserEmail: "",
                createdUserName: "",
                modifiedUserName: "",
                modifiedUserEmail: "",
                isDeleted: false,
                // previousQuoteRef: oldDraftQuote?.previousQuoteRef,
                isActive: true,
                quoteContact: currentContact,
                quoteContactDetailsMobile: quoteContactDetailsMobile,
                // stId: quote?.stId,
                originalQuoteId: oldDraftQuote?.originalQuoteId,
            };

            const qt = quoteDetails[currentDraftQuoteId].selectedItems;
            // &&
            // quoteItems.length > 0 &&
            // quoteItems.filter((quoteItem) => {
            //     return (
            //         quoteItem.displayGroupName?.split(" ")[0] !== "Group" &&
            //         quoteItem.displaySubGroupName?.split(" ")[0] !== "Sub" &&
            //         quoteItem.displayGroupName?.split(" ")[0] !== "Non"
            //     );
            // });

            const dataToSend = {
                quote: savedQuote,
                quoteDraftCustomers: quoteDetails[currentDraftQuoteId].selectedCustomers,
                quoteDraftItems: qt,
                clientTimezone: clientTimezone
            };

            const formattedData = getDraftQuoteDataModel(dataToSend);

            //setIsLoading(true);

            // postResponse(`Quote/UpdateQuoteWithPreviousQuoteRef?existingQuoteId=${currentQuoteId}`, formattedData)
            postResponse(`QuoteDraft/UpdateDraftQuoteWithPreviousQuoteRef`, formattedData)
                .then((res) => {
                    if (res.statusCode) {
                        setdraftcurrentQuoteId(res.data.quoteId);
                        setQuote(quoteDetails[currentDraftQuoteId]);
                        setOldDraftQuote(res.data);
                        // setSelectedCustomers(res.data.tblQuoteCustomers_Drafts);
                        // setProjectName(res.data.projectName);
                        // setHeader(headerList.filter((item) => item.qhId === res.data.headerName)[0].headerName);
                        // setQuoteDate(new Date(res.data.quoteDate));
                        // setCategory(
                        //     categoryList.filter((item) => {
                        //         return item.qtId === res.data.qttype;
                        //     })[0].qtType
                        // );
                        // setQuote(res.data);
                        setQuoteContact(res.data?.quoteContact);
                        //setQuoteNumber(res.data?.quoteNumber);
                        setQuoteContactDetailsMobile(res.data.quoteContactDetailsMobile);
                        // // setQuoteContactDetailsMobile(quoteContactDetailsMobile);

                        // //added to load the items data grid when creating a quote

                        // const sortedTblQuoteItems = sortItemListByPosition(res.data?.tblQuoteItems_Drafts);
                        // let assemblyId = uuidv4();
                        // let groupAssembly = 1;
                        // let assmGrpName = "ASSEMBLY " + groupAssembly;
                        // const updatedQuoteItems = sortedTblQuoteItems.map((item) => {
                        //     let getItemDtl = quoteDetails[id]?.selectedItems.filter((x) => x.stStockCode === item.stStockCode);
                        //     if (item.rowType === "DX") {
                        //         return {
                        //             ...item,
                        //             listPrice: "",
                        //             l60: "",
                        //             l55: "",
                        //             l50: "",
                        //             l20: "",
                        //             stEecost: "",
                        //             stEecostRound: "",
                        //             stFutureCost: "",
                        //             stFutureCostRound: "",
                        //             actCost: "",
                        //             actCostExact: "",
                        //             frcMargin: "",
                        //             totalCost: "",
                        //             lastCost: "",
                        //             lastCostExact: "",
                        //             //added later
                        //             margin: "",
                        //             actMargin: "",
                        //             lcMargin: "",
                        //             repDisc: "",
                        //             assemblyGroupName: "",
                        //             displayAssemblyGroupName: "",
                        //         };
                        //     } else if (item.rowType === "A") {
                        //         ///reset assembly items
                        //         assemblyId = (item.assembly === undefined) ? uuidv4()
                        //             : item.assemblyId.length === 0 ? uuidv4()
                        //                 : item.assemblyId;
                        //         assmGrpName = "ASSEMBLY " + groupAssembly;
                        //         groupAssembly +=1;
                        //         return {
                        //             ...item,
                        //             assemblyId: assemblyId,
                        //             oldStockCode: getItemDtl[0]?.oldStockCode,
                        //             stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
                        //         };
                        //     } else if (item.rowType === "AI") {
                        //         ///reset assembly items
                        //         return {
                        //             ...item,
                        //             assemblyId: assemblyId,
                        //             assemblyGroupName: assmGrpName,
                        //             displayAssemblyGroupName: assmGrpName,
                        //             oldStockCode: getItemDtl[0]?.oldStockCode,
                        //             stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
                        //         };
                        //     } else {
                        //         return {
                        //             ...item,
                        //             assemblyGroupName:  "",
                        //             displayAssemblyGroupName: "",
                        //             oldStockCode: getItemDtl[0]?.oldStockCode,
                        //             stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
                        //         }
                        //     }

                        //     //return item;
                        // });

                        // //updating the context once the quote is updated
                        // updateQuoteDetailsForId(res.data.quoteId, {
                        //     quoteId: res.data.quoteId,
                        //     quoteType: categoryList.filter((item) => {
                        //         return item.qtId === res.data.qttype;
                        //     })[0].qtType,
                        //     header: headerList.filter((item) => {
                        //         return item.qhId === res.data.headerName;
                        //     })[0].headerName,
                        //     projectName: res.data.projectName,
                        //     quoteDate: new Date(res.data.quoteDate),
                        //     quoteContact: res.data.quoteContact,
                        //     selectedContactOption : selectedContactOption,
                        //     quoteContactDetailsMobile: res.data.quoteContactDetailsMobile,
                        //     selectedCustomers: res.data.tblQuoteCustomers_Drafts, // tblQuoteCustomer type
                        //     savedQuote: res.data,
                        //     // selectedItems: formattedList,
                        //     selectedItems: updatedQuoteItems, // changed here for testing purpose
                        //     //quoteContact:quoteContact
                        // });

                        updateQuoteDetailsForId(res.data.quoteId, quoteDetails[currentDraftQuoteId]);
                        //setQuoteUpdated(true);
                        handleNodeWhenUpdating(quotes, setQuotes, currentDraftQuoteId, res.data.quoteId, res.data.quoteNumber);
                        setIsDraftQuoteSave(true);
                        // //toast.success("Auto Save in progress"); // Success toast

                        // Set a timeout to set it to false after 3 seconds
                        timeoutRef.current = setTimeout(setDraftQuoteSaveFalse, 2000);

                        // NotificationManager.info('Alert Message', 'Auto Save in progress', 3000);
                        setQuoteUpdated(false);
                    } else {
                        toast.error("Failed to auto save the Quote.", {
                            position: "top-center",
                            transition: Slide,
                        }); // Error toast for non-successful status codeat 
                    }
                })
                .catch((error) => {
                    console.error("Failed to update data:", error);
                    //toast.error("Connection error. Please try again later.");
                    return;
                })
                .finally(() => {
                    const unitExists = listOfUnitIdWithNames.some(unit => unit.unitId === id);
                    if (unitDetails[id] && (unitDetails[id]?.unitName === undefined || unitDetails[id].unitName === null)) {
                        navigate(`/new-units/${id}`, { replace: true })
                        return;
                    }
                    if (priceListDetails[id]) {
                        navigate(`/price-lists/${id}`, { replace: true })
                        return;
                    }
                    if (module === "library") {
                        navigate(`/library/{id}`, { replace: true })
                        return;
                    }
                    if (quoteDetails[id]) {
                        navigate(`/quotes/${id}`, { replace: true });
                        return;
                    }
                    if (unitExists) {
                        navigate(`/unit/${id}`, { replace: true })
                        return;
                    }

                    //setIsLoading(false); // Set loading back to false after request completes (either success or error)
                    navigate(`/quotes/${id}`, { replace: true });
                });
        }
    };


    // useEffect(() => {
    //     if (customerData[id] && customerData[id].length > 0 && quoteDetails[id]?.selectedItems?.length > 0) {
    //         if (!isFirsttimeUnitAdded && quoteDetails[id]?.savedQuote?.tblQuoteCustomers == undefined && isDraftQuote && !selectedQuote && (currentDraftQuoteId === "" || currentDraftQuoteId === null || currentDraftQuoteId === undefined)) {
    //             handleDraftSaveQuote();
    //         }
    //         setIsDraftQuoteSave(false);

    //     }
    // }, [quoteDetails]); // Dependency array

    const hasEmptyStStockCode = quoteDetails[id]?.selectedItems?.some(item => item.stStockCode !== '');


    useEffect(() => {
        if (customerData[id] && customerData[id].length > 0 && hasEmptyStStockCode) {
            if (!isFirsttimeUnitAdded && quoteDetails[id]?.savedQuote?.tblQuoteCustomers == undefined && isDraftQuote && !selectedQuote && (currentDraftQuoteId === "" || currentDraftQuoteId === null || currentDraftQuoteId === undefined)) {
                if (isSelectedUnitAdded || isPastedUnits) {
                    // Add a 1-second delay before executing handleDraftSaveQuote
                    setTimeout(() => {
                        handleDraftSaveQuote();
                    }, 1000);
                } else {
                    // Execute immediately if isSelectedUnitAdded is not true
                    handleDraftSaveQuote();

                }
            }
            setIsDraftQuoteSave(false);
            setIsSelectedUnitAdded(false);
            setIsPastedUnits(false);
        }
    }, [quoteDetails]);





    useEffect(() => {
        // Function to be executed every 2 minute
        const intervalFunction = () => {
            // Check if quoteUpdated is true and erData array has elements
            if (quoteDetails[id]?.savedQuote?.tblQuoteCustomers == undefined && quoteUpdated && customerData[id] && customerData[id].length > 0 && hasEmptyStStockCode) {
                isSavedQuoteChange(false);
                handleDraftSaveQuote();

            }
        };

        // Setting up the interval
        const intervalId = setInterval(intervalFunction, 120000);

        // Clearing the interval when the component unmounts
        return () => clearInterval(intervalId);


    }, [quoteUpdated, quoteDetails]); // Dependency array includes quoteUpdated and customerData
    // useEffect(() => {
    //     // Function to save the quote initially after 1 second and then every 15 seconds
    //     const handleSaveQuoteInitiallyAndThenRegularly = () => {
    //         // Immediately save the quote for the first time after 1 second
    //         const timeoutId = setTimeout(() => {
    //             if (quoteDetails[id]?.savedQuote?.tblQuoteCustomers === undefined && quoteUpdated && customerData[id] && customerData[id].length > 0 && quoteDetails[id]?.selectedItems?.length > 0) {
    //                 console.log("Quote saved initially after 1 second");
    //                 isSavedQuoteChange(false); // Assuming this function initiates the save operation
    //                 handleDraftSaveQuote(); // Trigger save operation
    //             }
    //             // Then, set up a regular interval of 15 seconds for subsequent saves
    //             setInterval(() => {
    //                 if (quoteDetails[id]?.savedQuote?.tblQuoteCustomers === undefined && quoteUpdated && customerData[id] && customerData[id].length > 0 && quoteDetails[id]?.selectedItems?.length > 0) {
    //                     console.log("Subsequent quote save triggered every 15 seconds");
    //                     isSavedQuoteChange(false); // Assuming this function initiates the save operation
    //                     handleDraftSaveQuote(); // Trigger save operation
    //                 }
    //             }, 15000); // 15000 milliseconds = 15 seconds
    //         }, 1000); // 1000 milliseconds = 1 second

    //         // Clean up function to clear the timeout
    //         return () => clearTimeout(timeoutId);
    //     };

    //     // Execute the function to handle the initial and subsequent saves
    //     const cleanupFunction = handleSaveQuoteInitiallyAndThenRegularly();

    //     // Cleanup on component unmount
    //     return cleanupFunction;
    // }, [quoteUpdated, quoteDetails, customerData, id]); // Dependency array

    // useEffect(() => {
    //     if (isQuoteSave) {
    //         handleDraftSaveQuote();
    //     }
    // }, [isQuoteSave]); // Add other dependencies as needed

    // const handleDraftSaveQuote = () => {
    //     const quoteItems = quoteDetails[id]?.selectedItems;
    //     const quoteCustomers = quoteDetails[id]?.selectedCustomers;
    //     onDraftStatusChange(true);
    //     const filteredQuotes = quoteItems;
    //     if (!quoteUpdated) return; // Only proceed if there's an update


    //     const setDraftQuoteSaveFalse = () => {
    //         setIsDraftQuoteSave(false);
    //     };



    //     // You need to iterate over all customers from customerData for the current subNodeTitle.
    //     //const quoteCustomers = quoteCustomer.length > 0 ? quoteCustomer : customerData[id] || [];
    //     //let updatedDataIndex = [];
    //     //if (quoteCustomers != [] || updatedData != undefined) {
    //     // const updatedDataIndex = quoteCustomers.findIndex((item) => item.customerId === updatedData.customerId);
    //     // //return;
    //     // //}
    //     // if (updatedDataIndex !== -1) {
    //     //     quoteCustomers[updatedDataIndex] = { ...quoteCustomers[updatedDataIndex], ...updatedData };
    //     // }
    //     if (currentDraftQuoteId === "" || currentDraftQuoteId === null || currentDraftQuoteId === undefined) {
    //         const quote = {
    //             quoteId: id,
    //             quoteNumber: id,
    //             amount: 0,
    //             cost: 0,
    //             margin: 0,
    //             quoteDate: quoteDate,
    //             owner: "",
    //             createdOn: new Date(),
    //             modifiedOn: new Date(),
    //             parentQuoteNo: "",
    //             quoteType: 0,
    //             projectName: projectName,
    //             qttype:
    //                 category && categoryList?.length > 0
    //                     ? categoryList.filter((item) => item.qtType === category)[0].qtId
    //                     : 1,
    //             headerName:
    //                 header && headerList?.length > 0
    //                     ? headerList.filter((item) => item.headerName === header)[0].qhId
    //                     : 1,
    //             createdBy: user?.homeAccountId,
    //             modifiedBy: user?.homeAccountId,
    //             createdUserEmail: "",
    //             createdUserName: "",
    //             modifiedUserName: "",
    //             modifiedUserEmail: "",
    //             isDeleted: false,

    //             previousQuoteRef: null,
    //             quoteContact: quoteContact,
    //             quoteContactDetailsMobile: quoteContactDetailsMobile,
    //             isActive: true,
    //         };

    //         const dataToSend = {
    //             quote: quote,
    //             quoteDraftCustomers: customerData[id],
    //             quoteDraftItems: filteredQuotes,
    //         };

    //         const formattedData = getDraftQuoteDataModel(dataToSend);
    //         console.log("formattedData:", formattedData);

    //         //setIsLoading(true);

    //         postResponse("QuoteDraft/createdraftnewquote", formattedData)
    //             .then((res) => {
    //                 if (res.statusCode) {
    //                     setdraftcurrentQuoteId(res.data.quoteId);
    //                     setSelectedCustomers(res.data.tblQuoteCustomers_Drafts);
    //                     setProjectName(res.data.projectName);
    //                     setHeader(headerList.filter((item) => item.qhId === res.data.headerName)[0].headerName);
    //                     setQuoteDate(new Date(res.data.quoteDate));
    //                     setCategory(
    //                         categoryList.filter((item) => {
    //                             return item.qtId === res.data.qttype;
    //                         })[0].qtType
    //                     );
    //                     setQuote(res.data);

    //                     setQuoteNumber(res.data?.quoteNumber);
    //                     setQuoteContact(res.data.quoteContact);
    //                     //added to load the items data grid when creating a quote

    //                     const sortedTblQuoteItems = sortItemListByPosition(res.data?.tblQuoteItems_Drafts);
    //                     let assemblyId = uuidv4();
    //                     const updatedQuoteItems = sortedTblQuoteItems.map((item) => {
    //                         let getItemDtl = quoteDetails[res.data.quoteId].selectedItems.filter((x)=>x.stStockCode === item.stStockCode);
    //                         if (item.rowType === "DX") {
    //                             return {
    //                                 ...item,
    //                                 listPrice: "",
    //                                 l60: "",
    //                                 l55: "",
    //                                 l50: "",
    //                                 l20: "",
    //                                 stEecost: "",
    //                                 stEecostRound: "",
    //                                 stFutureCost: "",
    //                                 stFutureCostRound: "",
    //                                 actCost: "",
    //                                 actCostExact: "",
    //                                 frcMargin: "",
    //                                 totalCost: "",
    //                                 lastCost: "",
    //                                 lastCostExact: "",
    //                                 //added later
    //                                 margin: "",
    //                                 actMargin: "",
    //                                 lcMargin: "",
    //                                 repDisc: "",
    //                             };
    //                         } else if (item.rowType === "A") {
    //                             ///reset assembly items
    //                             assemblyId = (item.assembly === undefined) ? uuidv4()
    //                                 : item.assemblyId.length === 0 ? uuidv4()
    //                                     : item.assemblyId;
    //                             return {
    //                                 ...item,
    //                                 assemblyId: assemblyId,
    //                                 oldStockCode: getItemDtl[0]?.oldStockCode,
    //                                 stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
    //                             };
    //                         } else if (item.rowType === "AI") {
    //                             ///reset assembly items
    //                             return {
    //                                 ...item,
    //                                 nettEA: "",
    //                                 l60: "",
    //                                 l55: "",
    //                                 l50: "",
    //                                 l20: "",
    //                                 totalPrice: "",
    //                                 margin: "",
    //                                 actMargin: "",
    //                                 frcMargin: "",
    //                                 lcMargin: "",
    //                                 dcOnLp: "",
    //                                 listPrice: "",
    //                                 lastCost: "",
    //                                 assemblyId: assemblyId,
    //                                oldStockCode: getItemDtl[0]?.oldStockCode,
    //                                stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
    //                             };
    //                         } else {
    //                             return {
    //                                 ...item,
    //                                 oldStockCode: getItemDtl[0]?.oldStockCode,
    //                                 stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
    //                             }
    //                         }

    //                         //return item;
    //                     });

    //                     //updating the context once the quote is created
    //                     updateQuoteDetailsForId(id, {
    //                         quoteId: res.data.quoteId,
    //                         quoteType: categoryList.filter((item) => {
    //                             return item.qtId === res.data.qttype;
    //                         })[0].qtType,
    //                         header: headerList.filter((item) => {
    //                             return item.qhId === res.data.headerName;
    //                         })[0].headerName,
    //                         projectName: res.data.projectName,
    //                         quoteDate: new Date(res.data.quoteDate),
    //                         quoteContactDetailsMobile: quoteContactDetailsMobile,
    //                         selectedCustomers: res.data.tblQuoteCustomers_Drafts, // tblQuoteCustomer type
    //                         savedQuote: res.data,
    //                         // selectedItems: sortedTblQuoteItems,
    //                         selectedItems: updatedQuoteItems,
    //                         //  quoteContact:res.data.quoteContact
    //                     });
    //                     //setQuoteUpdated(true);
    //                     handleNodeWhenSaving(quotes, setQuotes, res.data.quoteId, res.data.quoteNumber);
    //                     // toast.success("Auto Save in progress"); // Success toast
    //                     setIsDraftQuoteSave(true);
    //                    // Clear existing timeout when function is called
    //                     if (timeoutRef.current) {
    //                         clearTimeout(timeoutRef.current);
    //                     }
    //                     // Set a timeout to set it to false after 3 seconds
    //                     timeoutRef.current = setTimeout(setDraftQuoteSaveFalse, 3000);
    //                     // NotificationManager.info('Alert Message', 'Auto Save in progress', 3000);
    //                     setQuoteUpdated(false);
    //                 } else {
    //                     toast.error("Failed to auto save the Quote."); // Error toast for non-successful status code
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error("Failed to save data:", error);
    //                 //toast.error("Connection error. Please try again later.");
    //                 return;
    //             })
    //             .finally(() => {
    //                 setIsLoading(false); // Set loading back to false after request completes (either success or error)


    //             });
    //     } else {


    //         console.log("quote :", quote);

    //         const savedQuote = {
    //             quoteId: currentDraftQuoteId,
    //             quoteNumber: quoteDetails[id]?.savedQuote?.quoteNumber
    //                 ? quoteDetails[id]?.savedQuote?.quoteNumber
    //                 : quote?.quoteNumber,
    //             amount: quote?.amount,
    //             cost: quote?.cost,
    //             margin: quote?.margin,
    //             quoteDate: quoteDate,
    //             owner: quote?.owner,
    //             parentQuoteNo: quote?.parentQuoteNo,
    //             quoteType: quote?.quoteType,
    //             projectName: projectName,
    //             qttype:
    //                 category && categoryList?.length > 0
    //                     ? categoryList.filter((item) => item.qtType === category)[0].qtId
    //                     : 1,
    //             headerName:
    //                 header && headerList?.length > 0
    //                     ? headerList.filter((item) => item.headerName === header)[0].qhId
    //                     : 1,
    //             createdBy: quote?.createdBy,
    //             modifiedBy: quote?.modifiedBy,
    //             createdUserEmail: quote?.createdUserEmail,
    //             createdUserName: quote?.createdUserEmail,
    //             modifiedUserName: quote?.modifiedUserName,
    //             modifiedUserEmail: quote?.modifiedUserEmail,
    //             isDeleted: quote?.isDeleted,
    //             previousQuoteRef: quote?.previousQuoteRef,
    //             isActive: quote?.isActive,
    //             quoteContact: quote?.quoteContact,
    //             quoteContactDetailsMobile: quoteContactDetailsMobile,
    //             stId: quote?.stId,
    //             originalQuoteId: quote?.originalQuoteId,
    //         };

    //         const qt = quoteItems;
    //         // &&
    //         // quoteItems.length > 0 &&
    //         // quoteItems.filter((quoteItem) => {
    //         //     return (
    //         //         quoteItem.displayGroupName?.split(" ")[0] !== "Group" &&
    //         //         quoteItem.displaySubGroupName?.split(" ")[0] !== "Sub" &&
    //         //         quoteItem.displayGroupName?.split(" ")[0] !== "Non"
    //         //     );
    //         // });

    //         const dataToSend = {
    //             quote: savedQuote,
    //             quoteDraftCustomers: quoteCustomers,
    //             quoteDraftItems: qt,
    //         };

    //         const formattedData = getDraftQuoteDataModel(dataToSend);

    //         //setIsLoading(true);

    //         // postResponse(`Quote/UpdateQuoteWithPreviousQuoteRef?existingQuoteId=${currentQuoteId}`, formattedData)
    //         postResponse(`QuoteDraft/UpdateDraftQuoteWithPreviousQuoteRef`, formattedData)
    //             .then((res) => {
    //                 if (res.statusCode) {

    //                     setdraftcurrentQuoteId(res.data.quoteId);
    //                     setSelectedCustomers(res.data.tblQuoteCustomers_Drafts);
    //                     setProjectName(res.data.projectName);
    //                     setHeader(headerList.filter((item) => item.qhId === res.data.headerName)[0].headerName);
    //                     setQuoteDate(new Date(res.data.quoteDate));
    //                     setCategory(
    //                         categoryList.filter((item) => {
    //                             return item.qtId === res.data.qttype;
    //                         })[0].qtType
    //                     );
    //                     setQuote(res.data);
    //                     setQuoteContact(res.data.quoteContact);
    //                     setQuoteNumber(res.data?.quoteNumber);
    //                     setQuoteContact(quoteContact);
    //                     setQuoteContactDetailsMobile(res.data.quoteContactDetailsMobile);
    //                     setQuoteContactDetailsMobile(quoteContactDetailsMobile);

    //                     //added to load the items data grid when creating a quote

    //                     const sortedTblQuoteItems = sortItemListByPosition(res.data?.tblQuoteItems_Drafts);
    //                     let assemblyId = uuidv4();
    //                     const updatedQuoteItems = sortedTblQuoteItems.map((item) => {
    //                         let getItemDtl = quoteDetails[res.data.quoteId]?.selectedItems.filter((x)=>x.stStockCode === item.stStockCode);
    //                         if (item.rowType === "DX") {
    //                             return {
    //                                 ...item,
    //                                 listPrice: "",
    //                                 l60: "",
    //                                 l55: "",
    //                                 l50: "",
    //                                 l20: "",
    //                                 stEecost: "",
    //                                 stEecostRound: "",
    //                                 stFutureCost: "",
    //                                 stFutureCostRound: "",
    //                                 actCost: "",
    //                                 actCostExact: "",
    //                                 frcMargin: "",
    //                                 totalCost: "",
    //                                 lastCost: "",
    //                                 lastCostExact: "",
    //                                 //added later
    //                                 margin: "",
    //                                 actMargin: "",
    //                                 lcMargin: "",
    //                                 repDisc: "",
    //                             };
    //                         } else if (item.rowType === "A") {
    //                             ///reset assembly items
    //                             assemblyId = (item.assembly === undefined) ? uuidv4()
    //                                 : item.assemblyId.length === 0 ? uuidv4()
    //                                     : item.assemblyId;
    //                             return {
    //                                 ...item,
    //                                 assemblyId: assemblyId,
    //                                 oldStockCode: getItemDtl[0]?.oldStockCode,
    //                                 stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
    //                             };
    //                         } else if (item.rowType === "AI") {
    //                             ///reset assembly items
    //                             return {
    //                                 ...item,
    //                                 assemblyId: assemblyId,
    //                                 oldStockCode: getItemDtl[0]?.oldStockCode,
    //                                 stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
    //                             };
    //                         } else {
    //                             return {
    //                                 ...item,
    //                                 oldStockCode: getItemDtl[0]?.oldStockCode,
    //                                 stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice
    //                             }
    //                         }

    //                         //return item;
    //                     });

    //                     //updating the context once the quote is updated
    //                     updateQuoteDetailsForId(res.data.quoteId, {
    //                         quoteId: res.data.quoteId,
    //                         quoteType: categoryList.filter((item) => {
    //                             return item.qtId === res.data.qttype;
    //                         })[0].qtType,
    //                         header: headerList.filter((item) => {
    //                             return item.qhId === res.data.headerName;
    //                         })[0].headerName,
    //                         projectName: res.data.projectName,
    //                         quoteDate: new Date(res.data.quoteDate),
    //                         selectedCustomers: res.data.tblQuoteCustomers_Drafts, // tblQuoteCustomer type
    //                         savedQuote: res.data,
    //                         // selectedItems: formattedList,
    //                         selectedItems: updatedQuoteItems, // changed here for testing purpose
    //                         //quoteContact:quoteContact
    //                     });
    //                     setQuoteUpdated(true);
    //                     handleNodeWhenUpdating(quotes, setQuotes, id, res.data.quoteId, res.data.quoteNumber);

    //                     //toast.success("Auto Save in progress"); // Success toast
    //                     setIsDraftQuoteSave(true);
    //                     // Set a timeout to set it to false after 3 seconds
    //                     timeoutRef.current = setTimeout(setDraftQuoteSaveFalse, 3000);
    //                     // NotificationManager.info('Alert Message', 'Auto Save in progress', 3000);
    //                     setQuoteUpdated(false);
    //                 } else {
    //                     toast.error("Failed to auto save the Quote."); // Error toast for non-successful status code
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error("Failed to update data:", error);
    //                 //toast.error("Connection error. Please try again later.");
    //                 return;
    //             })
    //             .finally(() => {
    //                 setIsLoading(false); // Set loading back to false after request completes (either success or error)

    //             });
    //     }
    // };



    // useEffect(() => {
    //     // Function to be executed every 1 minute
    //     const intervalFunction = () => {
    //         // Check if quoteUpdated is true and customerData array has elements
    //         if (quoteUpdated && customerData[id] && customerData[id].length > 0) {
    //             isSavedQuoteChange(false);
    //             handleDraftSaveQuote();
    //         }
    //     };

    //     // Setting up the interval
    //     const intervalId = setInterval(intervalFunction, 6000); // 60000 milliseconds = 1 minute

    //     // Clearing the interval when the component unmounts
    //     return () => clearInterval(intervalId);
    // }, [quoteDetails]); // Dependency array includes quoteUpdated and customerData
    // // useEffect(() => {
    // //     if (isQuoteSave) {
    // //         handleDraftSaveQuote();
    // //     }
    // // }, [isQuoteSave]); // Add other dependencies as needed






    const onCustomerEditClick = (customerId, customerNumber, e, isOwner = false) => {
        setIsQuoteSaved(false);
        if (e) e.preventDefault();

        let selectedCustomerId = customerId;

        if (customerId === undefined && selectedRows[0]) {
            selectedCustomerId = selectedRows[0].customerId;
        }

        const selCus = customerData[id].filter((cus) => {
            return cus.customerId === customerId;
        })[0];

        setSelectedCustomer(selCus);

        // If still no valid customerId, show an error
        if (selectedCustomerId === "00000000-0000-0000-0000-000000000000" || !selectedCustomerId) {
            toast.error("Customer does not exist, please enter valid customer number.", {
                autoClose: 4000,
                closeOnClick: true,
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        // If customerNumber is not provided, fetch it from the selected row
        if (customerNumber === undefined && selectedRows[0]) {
            customerNumber = selectedRows[0].customerNumber;
        }

        if (isOwner) {
            setEditCustomer({
                customerId: customerId,
                customerNumber: customerNumber,
                homeAccountId: user?.homeAccountId,
                isOwner: true,
            });
        } else {
            setEditCustomer({
                customerId: customerId,
                customerNumber: customerNumber,
                homeAccountId: user?.homeAccountId,
                isOwner: false,
            });
        }

        toggleEditForm();
    };

    const onContactEditClick = (customerId, customerNumber, e) => {
        if (e) e.preventDefault();

        let selectedCustomerId = customerId;

        if (customerId === undefined && selectedRows[0]) {
            selectedCustomerId = selectedRows[0].customerId;
        }

        const selCus = customerData[id].filter((cus) => {
            return cus.customerId === customerId;
        })[0];

        setSelectedCustomer(selCus);

        // If still no valid customerId, show an error
        if (selectedCustomerId === "00000000-0000-0000-0000-000000000000" || !selectedCustomerId) {
            toast.error("Customer does not exist, please enter valid customer number.", {
                autoClose: 4000,
                closeOnClick: true,
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        // If customerNumber is not provided, fetch it from the selected row
        if (customerNumber === undefined && selectedRows[0]) {
            customerNumber = selectedRows[0].customerNumber;
        } else {
            setEditCustomer({
                customerId: customerId,
                customerNumber: customerNumber,
                homeAccountId: user?.homeAccountId,
            });
        }

        toggleCotactForm();
    };

    const handleDelete = () => {
        putResponse(`Quote/deletequotewithupdateisdeleted?quoteId=${id}`)
            .then((res) => {
                if (res) {
                    handleDeleteOpenQuote(quotes, setQuotes, id);
                    toast.success("The Quote has been Deleted Successfully", {
                        position: "top-center",
                        transition: Slide,
                    });
                } else {
                    toast.error("Failed to delete the Quote. Please try again later.", {
                        position: "top-center",
                        transition: Slide,
                    });
                }
            })
            .catch((error) => {
                console.error("Failed to delete quote:", error);
                toast.error("Connection error. Please try again later.", {
                    position: "top-center",
                    transition: Slide,
                });
            });
    };

    const toggleCotactForm = () => {
        setContactEditFormVisible(!contactEditFormVisible);
    };

    const handleOnClickContact = (customerNumber, bool1) => {
        toggleCotactForm();
    };

    const columns = [
        { field: "customerNumber", title: "Customer No", editable: true, onchange: handleEditChange },
        {
            field: "customerName",
            title: "Customer",
            cell: (props) => (
                <td>
                    {props.dataItem.customerId !== "00000000-0000-0000-0000-000000000000" ? (
                        <a
                            className="ip-hover-link"
                            href={props.dataItem.customerName}
                            onClick={(e) =>
                                onCustomerEditClick(props.dataItem.customerId, props.dataItem.customerNumber, e, false)
                            }>
                            <u>{props.dataItem.customerName}</u>
                        </a>
                    ) : (
                        <span>{props.dataItem.customerName}</span>
                    )}
                </td>
            ),
        },
        {
            field: "contactFullName",
            title: "Contact",

            cell: (props) => (
                <td>
                    {props.dataItem.customerId !== "00000000-0000-0000-0000-000000000000" ? (
                        // <div className="d-flex" >

                        //     <p>{props.dataItem.contactFullName}</p>
                        //     <button onClick={(e) => onContactEditClick(props.dataItem.customerId, props.dataItem.customerNumber, e, true)}>
                        //         ...

                        //     </button>
                        // </div>
                        <a
                            className="ip-hover-link"
                            href={props.dataItem.contactFullName}
                            onClick={(e) =>
                                onContactEditClick(props.dataItem.customerId, props.dataItem.customerNumber, e, true)
                            }>
                            <u>{props.dataItem.contactFullName}</u>
                        </a>
                    ) : (
                        <span>{props.dataItem.contactFullName}</span>
                    )}
                </td>
            ),
        },
        { field: "custClassification", title: "Classification" },
        // { field: 'owner', title: 'Owner' },

        {
            field: "alias",
            title: "Owner",
            cell: (props) => (
                <td>
                    <a
                        className="ip-hover-link"
                        href={props.dataItem.alias ? props.dataItem.alias : props.dataItem.owner}
                        onClick={(e) =>
                            onCustomerEditClick(props.dataItem.customerId, props.dataItem.customerNumber, e, true)
                        }>
                        <u>{props.dataItem.alias ? props.dataItem.alias : props.dataItem.owner}</u>
                    </a>
                </td>
            ),
        },
        { field: "priceListNote", title: "Pricelist Notes" },
        { field: "comments", title: "Comments" },
        {
            title: "Show",
            cell: (props) => (
                <td>
                    <input
                        type="checkbox"
                        className="form-check-input form-check-input ip-tick-show"
                        //title="Sets this customer as default so you can view its reports, preview and more..."
                        checked={props.dataItem.isPrimary || false}
                        onChange={(e) => {
                            SetLoadStatus(true);
                            setIsDoneButtonClicked(false);
                            const quoteId = quoteCustomer[0]?.quoteId;
                            let sourceData = customerData[id];
                            let newData = [...(sourceData || [])];
                            let item = newData.find((item) => item.customerNumber === props.dataItem.customerNumber);
                            const isLastRow = props.dataItem === newData[newData.length - 1];

                            // If the clicked checkbox is already checked, ignore the click event
                            if (props.dataItem.isPrimary) {
                                e.preventDefault();
                                return;
                            }

                            if (!props.dataItem.customerNumber) {
                                // Customer number not available, uncheck the checkbox and show error message
                                e.target.checked = false;
                                toast.error('Cannot select "show" box in the last row.', {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }

                            // else if (isLastRow || !props.dataItem.isEmptyRow) {
                            //   // Display the error message for the last non-empty row
                            //   toast.error('Cannot select "show" box in the last row.');
                            else if (
                                (!item || !item.isEmptyRow) &&
                                (newData.length === 0 || (item && !item.isEmptyRow))
                            ) {
                                if (item && item.customerId) {
                                    setQuoteCustomerId(item.customerId);
                                }
                                newData.forEach((dataItem) => {
                                    if (
                                        dataItem.customerNumber !== props.dataItem.customerNumber &&
                                        !dataItem.isEmptyRow
                                    ) {
                                        dataItem.isPrimary = false;
                                    }
                                });

                                if (item) {
                                    item.isPrimary = true; // Always set to true because we're ignoring direct uncheck
                                    updateQuoteDetailsForId(id, {
                                        selectedquoteCustomers: item.owner,
                                        header: header,
                                        quoteContactDetailsMobile: quoteContactDetailsMobile,
                                        quoteContact: quoteContact,
                                        quoteDate: quoteDate,
                                    });
                                    setQuoteUpdated(true);
                                    setQuoteCustomerOwner(item.owner);
                                } else {
                                    newData.push({
                                        ...emptyRow,
                                        customerNumber: props.dataItem.customerNumber,
                                        isPrimary: true, // Always set to true because we're ignoring direct uncheck
                                    });
                                }

                                setCustomerData((prevCustomerData) => ({
                                    ...prevCustomerData,
                                    [id]: newData,
                                }));
                            } else if (item && !item.isEmptyRow) {
                                item.isPrimary = true; // Always set to true because we're ignoring direct uncheck
                            }
                        }}
                    />
                </td>
            ),
        },
    ];

    useEffect(() => {
        if (quoteDetails[id] === undefined) {
            getSysSetting();
        }
    }, [quoteCustomerId, id, quoteDetails[id], category]);


    //OldQuote Import - Maduka

  
// Function to check if the data type of the value matches the expected type
function isValidType(field, value) {
    const typeMapping = {
        "quoteNumber": "string",
        "amount": "number",
        "cost": "number",
        "margin": "number",
        "quoteDate": "string", // or Date if you parse it
        "projectName": "string",
        "qttype": "string",
        "headerName": "string",
        "quoteContact": "string",
        "quoteContactDetailsMobile": "string",
        "contactFullName": "string",
        "owner": "string",
        "alias": "string",
        "enabled": "boolean",
        "custClassification": "string",
        "customerName": "string",
        "comments": "string",
        "isPrimary": "boolean",
        "phone": "number",
        "contactEmail": "string",
        "isQuoteContact": "number",
        "ownerJobTitle": "string",
        "ownerMobile": "number",
        "ownerEmail": "string",
        "debtorType": "string",
        "priceCategory": "string",
        "customerNumber": "string",
        "priceListNote": "string",
        "addressName": "string",
        "addressLine1": "string",
        "addressLine2": "string",
        "addressLine3": "string",
        "suburb": "string",
        "state": "string",
        "country": "string",
        "postCode": "number",
        "fax": "number",
        "contactFname": "string",
        "contactLname": "string",
        "customerId": "string",
        "actCost": "number",
        "actCostExact": "number",
        "stFutureCostRound": "number",
        "stFutureCost": "number",
        "stEecostRound": "number",
        "stEecost": "number",
        "costEa": "number",
        "costEaexact": "number",
        "dcOnLp": "number",
        "description": "string",
        "l20": "number",
        "l50": "number",
        "l55": "number",
        "l60": "number",
        "lastCost": "number",
        "lastCostExact": "number",
        "listPrice": "number",
        "margin": "number",
        "nettEA": "number",
        "qty": "number",
        "stStockCode": "string",
        "totalCost": "number",
        "totalPrice": "number",
        "type": "string",
        "rowType": "string",
        "soh": "number",
        "repDisc": "number",
        "dcCode": "string",
        "dcRate": "number",
        "actMargin": "number",
        "frcMargin": "number",
        "lcMargin": "number",
        "assemblyName": "string",
        "oldStockCode": "string",
        "stkWithDiffSPPrice": "number",
        "displayGroupName": "string",
        "displaySubGroupName": "string",
    };

    return field in typeMapping ? value === undefined || value === null || typeof value === typeMapping[field] : true;

    // Check if the field is in the mapping and handle undefined/null cases
    // if (field in typeMapping) {
    //     return value === undefined || value === null || typeof value === typeMapping[field];
    // }

    // // If the field is not in the mapping, assume it's valid
    // return true;
}


// Function to process the old quote data
function processOldQuoteImportedData(jsonData, hasHeaders) {
    const startingIndex = hasHeaders ? 1 : 0; // Skip the first row if it does not contain headers

    const generalDetails = jsonData.slice(startingIndex).map((row) => {
        let item = {};
        let CategoryType = row[0] !== undefined && row[0] !== null ? row[0] : "";

        if (CategoryType === "GD") {
            const mappingArray = getMappingArrayForOldQuotes.GeneralDetails;
            mappingArray.forEach((field, index) => {
                let value = row[index + 1];
                if (isValidType(field, value)) {
                    item[field] = value !== undefined && value !== null ? value : "";
                }
                else {
                    toast.error(`Invalid data type for field "${field}" at row ${rowIndex + 1}`, {
                        position: "top-center",
                        transition: Slide,
                    });
                }
            });
        }

        return item;
    });

    const customerDetails = jsonData.slice(startingIndex).map((row) => {
        let item = {};
        let CategoryType = row[0] !== undefined && row[0] !== null ? row[0] : "";

        if (CategoryType === "CU") {
            const mappingArray = getMappingArrayForOldQuotes.CustomerDetails;
            mappingArray.forEach((field, index) => {
                let value = row[index + 1];
                if (isValidType(field, value)) {
                    item[field] = value !== undefined && value !== null ? value : "";
                }
                else {
                    toast.error(`Invalid data type for field "${field}" at row ${rowIndex + 1}`, {
                        position: "top-center",
                        transition: Slide,
                    });
                }
            });
        }

        return item;
    });

    const itemDetails = jsonData.slice(startingIndex).map((row) => {
        let item = {};
        let CategoryType = row[0] !== undefined && row[0] !== null ? row[0] : "";

        if (CategoryType === "IT") {
            const mappingArray = getMappingArrayForOldQuotes.ItemDetails;
            mappingArray.forEach((field, index) => {
                let value = row[index + 1];
                if (isValidType(field, value)) {
                    item[field] = value !== undefined && value !== null ? value : "";
                }
                else {
                    toast.error(`Invalid data type for field "${field}" at row ${rowIndex + 1}`, {
                        position: "top-center",
                        transition: Slide,
                    });
                }
            });
        }

        return item;
    });

    // Filter out empty objects
    let generalDetails_ = generalDetails.filter(value => Object.keys(value).length !== 0);
    let customerDetails_ = customerDetails.filter(value => Object.keys(value).length !== 0);
    let itemDetails_ = itemDetails.filter(value => Object.keys(value).length !== 0);

    // Set the state or handle the processed data
    setGeneralDetails(generalDetails_);
    setCustomerDetails(customerDetails_);
    setItemDetailst(itemDetails_);
    setIsOQDataLoaded(true);
}
    useEffect(() => {
        if (isOldQuoteOpen) {
            handleImport(file, hasHeaders, isOldQuote);
        }
    }, [isOldQuoteOpen, file, hasHeaders, isOldQuote]);

    useEffect(() => {
        if (isOQDataLoaded) {
            setIsOQDataLoaded(false);
            setIsOldQuoteOpen(false);
            assignCustomerAndItemIDs();
        }
    }, [isOQDataLoaded]);

    const handleImport = (file, hasHeaders, isOldQuote) => {
        handleFileUpload({ target: { files: [file] } }, hasHeaders, isOldQuote);
    };

    function handleFileUpload(event, hasHeaders, isOldQuote) {
        const fileEx = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const oqSheetName = workbook.SheetNames[0];
            const oqWorksheet = workbook.Sheets[oqSheetName];
            let jsonData = XLSX.utils.sheet_to_json(oqWorksheet, { header: 1, raw: false });  // Read data, keep as raw text

            if (hasHeaders) {
                const columnIndex = 21;  // Index of 'AddressLine2_StStockCode'
                jsonData = jsonData.map(row => {
                    // Process each cell in the row
                    return row.map((value, index) => {
                        // If it's the special 'AddressLine2_StStockCode' column, format as string
                        if (index === columnIndex || index === 10) {
                            return value ? `${value}` : '';
                        }
                        // Remove single quotes for all other columns
                        if (typeof value === 'string') {
                            value = value.replace(/'/g, '');  // Remove single quotes from string values

                            // Remove % and other special characters but preserve numbers and letters
                            let cleanedValue = value.replace(/[%]/g, '');  // Specifically removing % here

                            // Check if the cleaned value is a boolean and convert
                            if (cleanedValue.toLowerCase() === 'true') {
                                return true;
                            } else if (cleanedValue.toLowerCase() === 'false') {
                                return false;
                            }

                            // Check if the cleaned value should be preserved as a string or converted to a number
                            if (/[^0-9.-]/.test(cleanedValue)) {
                                // Contains non-numeric characters, preserve as string
                                return cleanedValue;
                            }

                            // Attempt to convert to float if the value is numeric
                            if (!isNaN(parseFloat(cleanedValue)) && cleanedValue.trim() !== '') {
                                return parseFloat(cleanedValue);
                            } else {
                                // Not a valid number, return the original cleaned string
                                return cleanedValue;
                            }
                        }
                        return value;
                    });
                });
            }

            if (isOldQuote) {
                processOldQuoteImportedData(jsonData, hasHeaders);
            }
        };
        reader.readAsBinaryString(fileEx);
    }

    const oldQuoteMapping = {
        GeneralDetails: [
            "quoteNumber",
            "amount",
            "cost",
            "margin",
            "quoteDate",
            "projectName",
            "qttype",//category
            "headerName",
            "quoteContact",
            "quoteContactDetailsMobile",
        ],
        CustomerDetails: [
            "contactFullName",
            "owner",
            "alias",
            "enabled",
            "custClassification",
            "customerName",
            "comments",
            "isPrimary",
            "phone",
            "contactEmail",
            "isQuoteContact",
            "ownerJobTitle",
            "ownerMobile",
            "ownerEmail",
            "debtorType",
            "priceCategory",
            "customerNumber",
            "priceListNote",
            "addressName",
            "addressLine1",
            "addressLine2",
            "addressLine3",
            "suburb",
            "state",
            "country",
            "postCode",
            "fax",
            "contactFname",
            "contactLname",
            "customerId",
        ],
        ItemDetails: [
            "actCost",
            "actCostExact",
            "stFutureCostRound",
            "stFutureCost",
            "stEecostRound",
            "stEecost",
            "costEa",
            "costEaexact",
            "dcOnLp",
            "description",
            "l20",
            "l50",
            "l55",
            "l60",
            "lastCost",
            "lastCostExact",
            "listPrice",
            "margin",
            "nettEA",
            "qty",
            "stStockCode",
            "totalCost",
            "totalPrice",
            "type",
            "rowType",
            "soh",
            "repDisc",
            //newly added
            "dcCode",
            "dcRate",
            "actMargin",
            "frcMargin",
            "lcMargin",
            "assemblyName",
            "oldStockCode",
            "stkWithDiffSPPrice",
            "displayGroupName",
            "displaySubGroupName",
        ],
    };

    function getMappingArrayForOldQuotes(view) {
        return oldQuoteMapping[view] || [];
    }


   function processOldQuoteImportedData(jsonData, hasHeaders) {
    const startingIndex = hasHeaders ? 1 : 0; // Skip the first row if it does not contain headers
    let invalidFields = []; // To collect all invalid fields and their respective rows

    const generalDetails = jsonData.slice(startingIndex).map((row) => {
        let item = {};
        let CategoryType = row[0] !== undefined && row[0] !== null ? row[0] : "";

        if (CategoryType === "GD") {
            const mappingArray = getMappingArrayForOldQuotes("GeneralDetails");
            mappingArray.forEach((field, index) => {
                let value = row[index + 1];
                if (isValidType(field, value)) {
                    item[field] = value !== undefined && value !== null ? value : "";
                } else {
                    invalidFields.push(`"${field}" passing the value ${value}`);
                }
            });
        }

        return item;
    });

    const customerDetails = jsonData.slice(startingIndex).map((row) => {
        let item = {};
        let CategoryType = row[0] !== undefined && row[0] !== null ? row[0] : "";

        if (CategoryType === "CU") {
            const mappingArray = getMappingArrayForOldQuotes("CustomerDetails");
            mappingArray.forEach((field, index) => {
                let value = row[index + 1];
                if (isValidType(field, value)) {
                    item[field] = value !== undefined && value !== null ? value : "";
                } else {
                    invalidFields.push(`"${field}" passing the value ${value}`);
                }
            });
        }
        return item;
        
    });

    const itemDetails = jsonData.slice(startingIndex).map((row) => {
        let item = {};
        let CategoryType = row[0] !== undefined && row[0] !== null ? row[0] : "";

        if (CategoryType === "IT") {
            const mappingArray = getMappingArrayForOldQuotes("ItemDetails");
            mappingArray.forEach((field, index) => {
                let value = row[index + 1];
                if (isValidType(field, value)) {
                    item[field] = value !== undefined && value !== null ? value : "";
                } else {
                    invalidFields.push(`"${field}" passing the value ${value}`);
                }
            });
        }

        return item;
    });

 
    let generalDetails_ = generalDetails.filter(value => Object.keys(value).length !== 0);
    let customerDetails_ = customerDetails.filter(value => Object.keys(value).length !== 0);
    let itemDetails_ = itemDetails.filter(value => Object.keys(value).length !== 0);

 
    setGeneralDetails(generalDetails_);
    setCustomerDetails(customerDetails_);
    setItemDetailst(itemDetails_);
    setIsOQDataLoaded(true);

    if (invalidFields.length > 0) {
        toast.error(`skipping the rows of Invalid data found for the following fields: ${invalidFields.join(', ')}`, {
            position: "top-center",
            transition: Slide,
            autoClose: 2000,
        });
    }
}

    const assignCustomerAndItemIDs = async () => {
        setIsLoading(true);
        let primaryCustID = '';
        const dbExtra = await getResponse("DbExtra/getdbextra");
        const data = dbExtra[0];
        const consumableId = data.id;

        for (let i = 0; i < customerDetails.length; i++) {
            const cusDetails = await getCustomerIDByCustNo(customerDetails[i]);
            if(cusDetails === undefined){
                toast.error("Customer not found in the system. Unable to import the quote.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    position: "top-center",
                    transition: Slide,
                });
                setIsLoading(false);
                return;
            }
            else if(cusDetails[0]?.enabled === false){
                toast.error("Customer is disabled, please enter different customer and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    position: "top-center",
                    transition: Slide,
                });
                 setIsLoading(false);
                return;
            }
            customerDetails[i]["customerId"] = cusDetails[0].customerId;
            customerDetails[i]["priceListNote"] = cusDetails[0].priceListNote;
            customerDetails[i]["isPrimary"] = cusDetails[0].isPrimary;
            if (customerDetails[i]["isPrimary"] === true) {
                primaryCustID = customerDetails[i]["customerId"];
            }
        }

        const stStockCodes = itemDetails.map(item => item.stStockCode).filter(code => code);
        const stockDetails = await getStockDetailsByStockCodes(stStockCodes, primaryCustID);

        // Add check to ensure each detail is valid
        const stockDetailsMap = stockDetails.reduce((acc, detail) => {
            if (detail && detail.stStockCode) {
                acc[detail.stStockCode] = detail.stId;
            }
            return acc;
        }, {});

        let groupId = '';
        let subGroupId = '';
        let gName = '';
        let sgName = '';
        let groupNo = 0;
        let subGroupNo = 0;
        let sgDName = '';
        let gDName = '';

        for (let i = 0; i < itemDetails.length; i++) {
            if (itemDetails[i]["displayGroupName"] !== undefined && itemDetails[i]["displayGroupName"] !== null && itemDetails[i]["displayGroupName"] !== '' && itemDetails[i]["displaySubGroupName"] === '') {
                groupId = uuidv4();
                groupNo++;
                sgName = '';
                gDName = '';
                itemDetails[i]["groupId"] = groupId;
                itemDetails[i]["rowId"] = uuidv4();
                gName = itemDetails[i]["displayGroupName"]
                gName.includes('Non Grouped Items') ? itemDetails[i]["groupName"] = "Non Grouped Items" : itemDetails[i]["groupName"] = "Group" + groupNo
                gDName = itemDetails[i]["groupName"];
                itemDetails[i]["displayGroupName"] = gName.includes('Non Grouped Items') ? gName : gName;
                itemDetails[i]["nonGroupDbExtraId"] = itemDetails[i]["rowType"] === "DX" && itemDetails[i]["displayGroupName"].includes('Non Grouped Items') ? consumableId : '';

            } else if (itemDetails[i]["displaySubGroupName"] !== undefined && itemDetails[i]["displaySubGroupName"] !== null && itemDetails[i]["displaySubGroupName"] !== '') {
                subGroupId = uuidv4();
                subGroupNo++;
                itemDetails[i]["groupId"] = groupId;
                itemDetails[i]["subGroupId"] = subGroupId;
                itemDetails[i]["rowId"] = uuidv4();
                itemDetails[i]["subGroupName"] = "Sub Group " + subGroupNo;
                sgDName = itemDetails[i]["subGroupName"];
                sgName = itemDetails[i]["displaySubGroupName"];
                itemDetails[i]["displaySubGroupName"] = sgName;
                itemDetails[i]["displayGroupName"] = gName !== '' ? gName : '';
            } else {
                itemDetails[i]["stId"] = stockDetailsMap[itemDetails[i]["stStockCode"]] || uuidv4();
                itemDetails[i]["customerId"] = primaryCustID;
                itemDetails[i]["rowId"] = uuidv4();
                itemDetails[i]["groupId"] = gName !== '' ? groupId : '';
                itemDetails[i]["subGroupId"] = sgName !== '' ? subGroupId : '';
                itemDetails[i]["displayGroupName"] = '';
                itemDetails[i]["displaySubGroupName"] = '';
                itemDetails[i]["groupName"] = gDName;
                itemDetails[i]["subGroupName"] = sgDName;

                if (itemDetails[i]["rowType"] === "DX") {
                    itemDetails[i]["groupDbExtraId"] = itemDetails[i]["groupId"] ? consumableId : '';
                    itemDetails[i]["subGroupDbExtraId"] = itemDetails[i]["subGroupId"] ? consumableId : '';
                    itemDetails[i]["nonGroupDbExtraId"] = itemDetails[i]["displayGroupName"].includes('Non Grouped Items') ? consumableId : '';
                }
            }
        }

        await handleOldQuoteSave();
    }


    const handleOldQuoteSave = async () => {
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setIsOldQuoteDone(true);
        setIsQuoteSaved(true);
    
        customerDetails.forEach(customer => {
          if (typeof customer.ownerMobile !== 'string') {
            customer.ownerMobile = customer?.ownerMobile ? customer?.ownerMobile?.toString() : "";
          }
        });
    
        customerData[id] = customerDetails;
    
        if (!customerData[id].some((item) => item.isPrimary === true)) {
          toast.error("Please select a quote customer and try again.", {
            position: "top-center",
            transition: Slide,
          });
          return;
        }
    
        const groupId = uuidv4();
        const rowId = uuidv4();
        const qId = uuidv4();
    
        const quoteCustomers = quoteCustomer.length > 0 ? quoteCustomer : customerData[id] || [];
        const oldQuoteGeneralDetail = generalDetails[0];
    
        const quote = {
          quoteId: id,
          quoteNumber: id,
          amount: oldQuoteGeneralDetail["amount"],
          cost: oldQuoteGeneralDetail["cost"],
          margin: oldQuoteGeneralDetail["margin"],
          quoteDate: oldQuoteGeneralDetail["quoteDate"],
          owner: "",
          createdOn: new Date(),
          modifiedOn: new Date(),
          parentQuoteNo: "",
          quoteType: 0,
          projectName: oldQuoteGeneralDetail["projectName"],
          qttype: category && categoryList.length > 0
            ? categoryList.filter((item) => item.qtType === oldQuoteGeneralDetail["qttype"])[0].qtId
            : 1,
          headerName: header && headerList.length > 0
            ? headerList.filter((item) => item.headerName === oldQuoteGeneralDetail["headerName"])[0].qhId
            : 1,
          createdBy: user?.homeAccountId,
          modifiedBy: user?.homeAccountId,
          createdUserEmail: "",
          createdUserName: "",
          modifiedUserName: "",
          modifiedUserEmail: "",
          isDeleted: false,
          previousQuoteRef: null,
          quoteContact: oldQuoteGeneralDetail["quoteContact"],
          quoteContactDetailsMobile: oldQuoteGeneralDetail["quoteContactDetailsMobile"],
          isActive: true,
        };
    
        const dataToSend = {
          quote: quote,
          quoteCustomers: customerData[id],
          quoteItems: itemDetails,
          userId: user?.homeAccountId,
          timeZone: clientTimezone,
        };
    
        const formattedData = getQuoteDataModel(dataToSend);
    
        // Start the timer and show the progress bar
       
      // Start the timer and show the progress bar
      setOpenShowProgress(true);
      const expectedDuration = 10000; // Set the expected duration in milliseconds (e.g., 10 seconds)
      setProgress((prev) => ({
        ...prev,
        [id]: { percentage: 0, remainingTime: Math.ceil(expectedDuration / 1000) },
      }));
      let startTime = Date.now();
      const interval = setInterval(() => {
        let elapsed = Date.now() - startTime;
        let remainingTime = Math.max(0, expectedDuration - elapsed);
        setProgress((prev) => ({
          ...prev,
          [id]: {
            percentage: Math.round(Math.min(100, (elapsed / expectedDuration) * 100)),
            remainingTime: Math.ceil(remainingTime / 1000),
          },
        }));
        if (remainingTime <= 0) {
          clearInterval(interval);
        }
      }, 100);
    
        postResponse("Quote/createnewquote", formattedData)
          .then((res) => {
            if (res.statusCode) {
              setcurrentQuoteId(res.data.quoteId);
              setSelectedCustomers(res.data.tblQuoteCustomers);
              setProjectName(res.data.projectName);
              setHeader(headerList.filter((item) => item.qhId === res.data.headerName)[0].headerName);
              setQuoteDate(new Date(res.data.quoteDate));
              setCategory(
                categoryList.filter((item) => {
                  return item.qtId === res.data.qttype;
                })[0].qtType
              );
              setQuote(res.data);
              setQuoteNumber(res.data?.quoteNumber);
              setQuoteContact(res.data.quoteContact);
              setQuoteContactDetailsMobile(res.data.quoteContactDetailsMobile);
              setQuoteCreatedbyUser(res.data?.createdBy);
              setCustomerData((prevCustomerData) => ({
                ...prevCustomerData,
                [id]: res.data.tblQuoteCustomers,
              }));
              setMainCustomer(res?.data?.tblQuoteCustomers[0]?.customerId);
              setCustomerId(res?.data?.tblQuoteCustomers?.[0]?.customerId);
              const sortedTblQuoteItems = sortItemListByPosition(res.data?.tblQuoteItems);
              let assemblyId = uuidv4();
              let groupAssembly = 1;
              let assmGrpName = "ASSEMBLY " + groupAssembly;
              let oldStkCode = '';
              let retValue = {};
              let parentId = null;
              let treeId = null;
              const updatedQuoteItems = sortedTblQuoteItems.map((item) => {
                let getItemDtl = itemDetails.filter((x) => x.stStockCode === item.stStockCode);
                treeId = uuidv4();
                if (item.rowType === "DX") {
                  retValue = {
                    ...item,
                    listPrice: "",
                    l60: "",
                    l55: "",
                    l50: "",
                    l20: "",
                    stEecost: "",
                    stEecostRound: "",
                    stFutureCost: "",
                    stFutureCostRound: "",
                    actCost: "",
                    actCostExact: "",
                    frcMargin: "",
                    totalCost: "",
                    lastCost: "",
                    lastCostExact: "",
                    margin: "",
                    actMargin: "",
                    lcMargin: "",
                    repDisc: "",
                    assemblyGroupName: "",
                    displayAssemblyGroupName: "",
                    id: treeId,
                    parentId: parentId
                  };
                } else if (item.rowType === "A") {
                  assemblyId = (item.assembly === undefined) ? uuidv4()
                    : item.assemblyId.length === 0 ? uuidv4()
                      : item.assemblyId;
                  assmGrpName = "ASSEMBLY " + groupAssembly;
                  groupAssembly += 1;
                  retValue = {
                    ...item,
                    assemblyId: assemblyId,
                    assemblyGroupName: assmGrpName,
                    displayAssemblyGroupName: assmGrpName,
                    oldStockCode: item?.oldStockCode ? item?.oldStockCode : null,
                    stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                    // oldStockCode: getItemDtl[0]?.oldStockCode ? getItemDtl[0]?.oldStockCode : null,
                    // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                    id: treeId,
                    parentId: parentId
                  };
                } else if (item.rowType === "AI") {
                  retValue = {
                    ...item,
                    nettEA: "",
                    l60: "",
                    l55: "",
                    l50: "",
                    l20: "",
                    totalPrice: "",
                    margin: "",
                    actMargin: "",
                    frcMargin: "",
                    lcMargin: "",
                    dcOnLp: "",
                    listPrice: "",
                    lastCost: "",
                    assemblyId: assemblyId,
                    assemblyGroupName: assmGrpName,
                    displayAssemblyGroupName: "",
                    oldStockCode: item?.oldStockCode ? item?.oldStockCode : null,
                    stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                    // oldStockCode: getItemDtl[0]?.oldStockCode ? getItemDtl[0]?.oldStockCode : null,
                    // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                    id: treeId,
                    parentId: parentId
                  };
                } else {
                  retValue = {
                    ...item,
                    assemblyGroupName: "",
                    displayAssemblyGroupName: "",
                    l60: !item?.l60 ? "" : item?.l60,
                    l55: !item?.l55 ? "" : item?.l55,
                    l50: !item?.l50 ? "" : item?.l50,
                    l20: !item?.l20 ? "" : item?.l20,
                    oldStockCode: item?.oldStockCode ? item?.oldStockCode : null,
                    stkWithDiffSPPrice: item?.stkWithDiffSPPrice,
                    isDisabled: item?.isDisabled,
                    // l60: !getItemDtl[0]?.l60 ? "" : getItemDtl[0]?.l60,
                    // l55: !getItemDtl[0]?.l55 ? "" : getItemDtl[0]?.l55,
                    // l50: !getItemDtl[0]?.l50 ? "" : getItemDtl[0]?.l50,
                    // l20: !getItemDtl[0]?.l20 ? "" : getItemDtl[0]?.l20,
                    // oldStockCode: getItemDtl[0]?.oldStockCode ? getItemDtl[0]?.oldStockCode : null,
                    // stkWithDiffSPPrice: getItemDtl[0]?.stkWithDiffSPPrice,
                    // isDisabled: getItemDtl[0]?.isDisabled,
                    id: treeId,
                    parentId: item.rowType === "G"
                      ? null
                      : parentId
                  }
                }
    
                parentId = item.rowType === "G" || item.rowType === "SG"
                  ? treeId
                  : !item.groupName.startsWith("Group")
                    ? null
                    : parentId;
                return retValue;
              });
    
              updateQuoteDetailsForId(id, {
                quoteId: res.data.quoteId,
                quoteType: categoryList.filter((item) => {
                  return item.qtId === res.data.qttype;
                })[0].qtType,
                header: headerList.filter((item) => {
                  return item.qhId === res.data.headerName;
                })[0].headerName,
                projectName: res.data.projectName,
                quoteDate: new Date(res.data.quoteDate),
                quoteContact: res.data.quoteContact,
                quoteContactDetailsMobile: quoteContactDetailsMobile,
                selectedCustomers: res.data.tblQuoteCustomers,
                savedQuote: res.data,
                selectedItems: updatedQuoteItems,
              });
    
              handleNodeWhenSaving(quotes, setQuotes, res.data.quoteId, res.data.quoteNumber);
    
              toast.success("The Quote has been Created Successfully", {
                position: "top-center",
                transition: Slide,
              });
    
            } else {
              toast.error("Failed to create the Quote.", {
                position: "top-center",
                transition: Slide,
              });
            }
          })
          .catch((error) => {
            console.error("Failed to save data:", error);
            toast.error("Connection error. Please try again later.", {
              position: "top-center",
              transition: Slide,
            });
          })
          .finally(() => {
            setIsLoading(false);
            setIsQuoteSaved(false);
            setOpenShowProgress(false);
          });
      };
    


    const getCustomerIDByCustNo = async (item) => {
        let cusNo = item.customerNumber;
        let convertedCustomerNumber = typeof cusNo !== 'string' ? cusNo.toString() : cusNo;
        try {
            const response = await getResponse("Customer/getcustomerdetailsforoldquotes", {
                customerNumber: convertedCustomerNumber,
            });

           
            const formattedData = convertToModel([response]);
            if (formattedData.length > 0) {
                formattedData[0].isPrimary = item.isPrimary; // Set isPrimary value
            }

            return formattedData;

        } catch (error) {
            console.error("Error : ", error);
         
        }
    };


    const getStockDetailsByStockCodes = async (stStockCodes, itemPrimaryCustID) => {
        try {
            const response = await getResponse("Stocks/getstockdetailsusingarray", {
                stStockCodes: stStockCodes.join(','),  // Convert array to comma-separated string
                CustomerId: itemPrimaryCustID,
                UserId: user?.homeAccountId,
            });

            return response; // Assuming response is an array of stock details
        } catch (error) {
            console.error("Error fetching stock details: ", error);
            return [];
        }
    };

    return (
        <>


    
{openShowProgress && progress[id] && (
      <div>
        <div className="bar-txt">Importing In Progress, this may take a few seconds/minutes... </div>
        <ProgressBar 
        completed={progress[id].percentage} 
        bgColor="#1a3a7b"
        baseBgColor="#ddd"
        height="18px"
        width="100%"
        borderRadius="4px"
        labelClassName="bar-progress-label"
        label={`${progress[id].percentage}%`} 
      />
      </div>)}
        
            <div className="ip-spinner-page">{isLoading && <Spinner />}</div>


            {!isLoading && categoryList.length > 0 && headerList.length > 0 ? (
                <>
                    <QouteHeaders
                        projectName={projectName}
                        category={category}
                        quoteDate={quoteDate}
                        header={header}
                        setProjectName={setProjectName}
                        setCategory={setCategory}
                        setQuoteDate={setQuoteDate}
                        setHeader={setHeader}
                        IscategoryChanged={isCategoryChanged}
                        isHeaderChanged={isHeaderChanged}
                        setIsHeaderChanged={setIsHeaderChanged}
                        setIscategoryChanged={setIsCategoryChanged}
                        quoteCustomer={quoteCustomer}
                        quoteDetails={quoteDetails}
                        updateQuoteDetailsForId={updateQuoteDetailsForId}
                        categories={categories}
                        headers={headers}

                    />
                    {/* <Button icon="delete" themeColor={"secondary"} onClick={handleDelete}>
                        <strong>Delete Quote</strong>
                    </Button> */}

                    {/* data as quote details */}

                    <Accordion id="default-accordion-example2">
                        <AccordionItem>
                            <h2 className="accordion-header" id="headingTwo">
                                <button
                                    className={classnames("accordion-button", { collapsed: !col2 })}
                                    type="button"
                                    onClick={t_col2}
                                    style={{ cursor: "pointer" }}>
                                    Customer ({custCount})
                                </button>
                            </h2>
                            <Collapse isOpen={col2} className="accordion-collapse" id="collapseTwo">
                                <div className="accordion-body">
                                    <DataGridView
                                        data={paginate(customerData[id] || [], pageNumber, pageSize)}
                                        columns={columns}
                                        onEdit={onEdit}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                        handleCustomerNumberChange={handleCustomerNumberChange}
                                        showSelectionColumn={true}
                                        isHeaderCheckboxRendered={false}
                                        singleSelect={true}
                                        idField="customerId"
                                        tableName="Customers"
                                        toolbarButtons={[
                                            {
                                                label: <strong>Add Customer</strong>,
                                                onClick: handleAdd,
                                                //icon: "plus",
                                                //look: "flat",
                                            },
                                            {
                                                label: <strong>Edit Customer</strong>,
                                                onClick: () => {
                                                    if (selectedRows[0]) {
                                                        onCustomerEditClick(
                                                            selectedRows[0].customerId,
                                                            selectedRows[0].customerNumber
                                                        );
                                                    } else {
                                                        toast.error("Please select a customer first.", {
                                                            autoClose: 3000,
                                                            closeOnClick: true,
                                                            position: "top-center",
                                                            transition: Slide,
                                                        });
                                                    }
                                                },

                                                disabled: selectedRows.length < 1,
                                            },
                                            {
                                                label: <strong>Remove Customer</strong>,
                                                onClick: handleRemove,
                                                disabled: selectedRows.length < 1,
                                            },
                                            // { label: <strong>Save Quote</strong>, onClick: handleSave }, //commented this button due to we move the save button outside the component
                                        ]}
                                        total={customerData[id]?.length || 0}
                                        showEmptyRow={true}
                                        onPageChange={handlePageChange}
                                        isGroupable={false}
                                    />
                                </div>
                            </Collapse>
                        </AccordionItem>
                    </Accordion>
                </>
            ) : (
                <></>
            )}

            {dialogVisible && (
                <Dialog width="100%" title={"Customer Lookup"} onClose={closeDialog}>
                    <CustomerLookup onAddItems={handleSelectedItems} />

                    <DialogActionsBar>
                        <div className="d-flex justify-content-end ip-dialog-btnstatic">
                            <Button id="btnCustomerlookupAdd" className="px-4 me-2 ip-button-general" onClick={handleAddItems}>
                                Add
                            </Button>
                            <Button id="btnCustomerlookupAddClear" className="px-4" onClick={closeDialog}>
                                Cancel
                            </Button>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}

            {quotesDialogVisible && (
                <Dialog width="60%" title={"Select Quote"} onClose={() => setQuotesDialogVisible(!quotesDialogVisible)}>
                    <QuotesLookup onSelectQuote={handleSelectQuote} />

                    <DialogActionsBar>
                        <div className="d-flex justify-content-end ip-dialog-btnstatic">
                            <Button
                                className="px-4 me-2 ip-button-general"
                                onClick={() => {
                                    setQuotesDialogVisible(!quotesDialogVisible);
                                }}>
                                Open
                            </Button>
                            <Button className="px-4 " onClick={() => setQuotesDialogVisible(!quotesDialogVisible)}>
                                Cancel
                            </Button>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}

            {/* {customerEditFormVisible && (
                <Dialog

                    width="85%"

                    title={"Customer : " + customerDetails.customerOwnerDetails.customer.customerName}

                    onClose={() => setCustomerEditFormVisible(false)}>

                    <CustomerEditForm data={customerDetails} updateGridData={updateGridData} />

                    <div style={{ width: "20%", marginLeft: "auto", color: "#6495ED" }}>

                        <DialogActionsBar style={{ textAlign: "right" }}>

                            <button className="k-button" onClick={() => setCustomerEditFormVisible(false)}>

                                Cancel

                            </button>

                            <button className="k-button k-primary" onClick={() => setCustomerEditFormVisible(false)}>

                                OK

                            </button>

                        </DialogActionsBar>

                    </div>

                </Dialog>
            )} */}

            {customerEditFormVisible && (
                <Dialog
                    className="ipd-dialog-container"
                    title={"Customer : " + (selectedCustomer?.customerName || "New Customer")}
                    onClose={() => setCustomerEditFormVisible(false)}>
                    <CustomerEditForm
                        data={editCustomer}
                        updateGridData={updateGridData}
                        toggleEditForm={toggleEditForm}
                        setCustomerEditFormVisible={setCustomerEditFormVisible}
                        customerEditFormVisible={customerEditFormVisible}
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                    />
                </Dialog>
            )}

            {contactEditFormVisible && (
                <Dialog
                    className="ipd-dialog-container"
                    title={"Contacts: " + (selectedCustomer?.customerName || "New Customer")}
                    onClose={() => triggerCustomerEditFormClose()}>
                    <ContactEditForm
                        data={editCustomer}
                        updateGridData={updateGridData}
                        toggleCotactForm={toggleCotactForm}
                        setContactEditFormVisible={setContactEditFormVisible}
                        contactEditFormVisible={contactEditFormVisible}
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                        customerNumber={selectedCustomer?.customerNumber}
                        selectedContact={selectedContact}
                        setSelectedContact={setSelectedContact}
                        triggerCustomerEditFormClose={customerEditFormClose}
                    />
                </Dialog>
            )}
        </>
    );
};

export default CustomerDataGrid;
