import React, { useState, useEffect, useRef } from "react";
import { isNumber } from "lodash";

const EditableCell = (props) => {
    const { dataItem, field, onChange, index, disabled, stStockcodeRef } = props;
    const [localValue, setLocalValue] = useState(dataItem[field]);
    const [previousValidValue, setPreviousValidValue] = useState(parseFloat(dataItem[field]).toFixed(2));
    const [hasError, setHasError] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        if (hasError) {
            setLocalValue(previousValidValue);
            setHasError(false);
        }
    }, [hasError, previousValidValue]);

    useEffect(() => {
        setLocalValue(
            isNumber(dataItem[field])
                ? field !== "qty" && field !== "margin" && field !== "repDisc"
                    ? parseFloat(dataItem[field]).toFixed(2)
                    : dataItem[field]
                : dataItem[field]
        );
        setPreviousValidValue(parseFloat(dataItem[field]).toFixed(2)); // Update previous valid value whenever dataItem[field] changes
    }, [dataItem[field]]);

    const handleLocalChange = (e) => {
        setLocalValue(e.target.value);
    };

    const handleBlur = () => {
        // Revert changes if the localValue is different from the previous valid value
        if (localValue !== previousValidValue && localValue != 'NaN' && previousValidValue != 'NaN' && localValue != undefined) {
            setLocalValue(previousValidValue);
        }
    };

    const handleKeyDown = (e) => {
        const cursorPosition = inputRef.current.selectionStart;
        const ableToNavigate = parseFloat(localValue) === parseFloat(dataItem[field]);

        switch (e.key) {
            case "Enter":
                onChange({
                    dataItem,
                    field,
                    value: localValue,
                    clearInput: () => setHasError(true),
                });
                setPreviousValidValue(localValue); // Update the valid value upon pressing Enter
                // Move to the next row in the same column
                e.preventDefault(); // Prevent default Enter behavior
                focusCell(1, 0); // Move down to the next row in the same column
                break;
            case "Tab":
                onChange({
                    dataItem,
                    field,
                    value: localValue,
                    clearInput: () => setHasError(true),
                });
                setPreviousValidValue(localValue); // Update the valid value upon pressing Enter
                break;
            case "ArrowUp":
                if (ableToNavigate) {
                    e.preventDefault(); // Prevent scrolling
                    focusCell(-1, 0);
                }
                break;
            case "ArrowDown":
                if (ableToNavigate) {
                    e.preventDefault(); // Prevent scrolling
                    focusCell(1, 0);
                }
                break;
            case "ArrowLeft":
                if (cursorPosition === 0 && ableToNavigate) {
                    e.preventDefault(); // Prevent scrolling
                    focusCell(0, -1);
                }
                break;
            case "ArrowRight":
                if (cursorPosition === localValue.length && ableToNavigate) {
                    e.preventDefault(); // Prevent scrolling
                    focusCell(0, 1);
                }
                break;
            default:
                break;
        }
    };

    const focusCell = (rowOffset, colOffset) => {
        const currentCell = inputRef.current.closest("td");
        const currentRow = currentCell.closest("tr");
        const allRows = Array.from(currentRow.parentElement.children);
        const rowIndex = allRows.indexOf(currentRow);
        const colIndex = Array.from(currentCell.parentElement.children).indexOf(currentCell);

        const targetRow = allRows[rowIndex + rowOffset];
        if (!targetRow) return;

        const targetCell = targetRow.children[colIndex + colOffset];
        if (targetCell) {
            const targetInput = targetCell.querySelector("input");
            if (targetInput) {
                inputRef.current.blur(); // Blur the current input
                targetInput.focus();
                targetInput.select(); // Selects the entire content of the input
            }
        }
    };

    const handleClick = (e) => {
        e.stopPropagation();
    };

    const inputStyle = hasError ? { border: "2px solid red" } : {};

    return (
        <td onClick={handleClick}>
            <input
                className={`ip-editable-input ${props.className}`}
                autoComplete="on"
                type="text"
                value={localValue}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                disabled={disabled}
                ref={inputRef}
                style={inputStyle}
            />
        </td>
    );
};

export default EditableCell;