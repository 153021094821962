import { React, useState, useRef, useEffect, useContext, Fragment, useMemo } from 'react';
import ReactDOM from 'react-dom';
//import { PageCountContext } from '../../Contexts/PageCountContext';
import { PDFExport } from '@progress/kendo-react-pdf';
import ipdLogoPath1 from '../../assets/default/images/ipd/pdf/IPD.png';
import ipdLogoPath2 from '../../assets/default/images/ipd/pdf/Addelec.png';
import ipdLogoPath3 from '../../assets/default/images/ipd/pdf/PJWhite.png';
import ipdPdfImg1 from '../../assets/default/images/ipd/pdf/CL_Qtebanner.jpg';
import ipdPdfImg2 from '../../assets/default/images/ipd/pdf/HTC_Qtebanner.jpg';
import ipdPdfImg3 from '../../assets/default/images/ipd/pdf/PJW_Qtebanner.jpg';
import ipdPdfImg4 from '../../assets/default/images/ipd/pdf/PS_Qtebanner.jpg';
import ipdPdfImg5 from '../../assets/default/images/ipd/pdf/SRV_Qtebanner.jpg';
import ipdPdfImg6 from '../../assets/default/images/ipd/pdf/WS_Qtebanner.jpg';
import ipdPdfimg7 from '../../assets/default/images/ipd/pdf/DR_Qtebanner.jpg';
import ipdPdfimg8 from '../../assets/default/images/ipd/pdf/GT_Qtebanner.jpg';
import LZString from 'lz-string';
import ipdPdfimgApprpending from '../../assets/default/images/ipd/pdf/pdf-pending-stamp.png';
import ipdPdfimgApprreject from '../../assets/default/images/ipd/pdf/pdf-reject-stamp.png';
import ipdPdfimgApprrequired from '../../assets/default/images/ipd/pdf/pdf-required-stamp.png';
import { getResponse } from "../../Services/Common/Get";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { Interweave } from 'interweave';
import "../../assets/default/scss/config/default/pdf-style.css";
import { get } from 'lodash';


const imageToDataUrl = async (path) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
        };
        img.onerror = (errorEvent) => reject(new Error("Error converting the image to Data URL"));
        img.src = path;
    });
};

const ipdLogoDataUrl1 = await imageToDataUrl(ipdLogoPath1);
const ipdLogoDataUrl2 = await imageToDataUrl(ipdLogoPath2);
const ipdLogoDataUrl3 = await imageToDataUrl(ipdLogoPath3);
const ipdAdvertDataUrl1 = await imageToDataUrl(ipdPdfImg1);
const ipdAdvertDataUrl2 = await imageToDataUrl(ipdPdfImg2);
const ipdAdvertDataUrl3 = await imageToDataUrl(ipdPdfImg3);
const ipdAdvertDataUrl4 = await imageToDataUrl(ipdPdfImg4);
const ipdAdvertDataUrl5 = await imageToDataUrl(ipdPdfImg5);
const ipdAdvertDataUrl6 = await imageToDataUrl(ipdPdfImg6);
const ipdAdvertDataUrl7 = await imageToDataUrl(ipdPdfimg7);
const ipdAdvertDataUrl8 = await imageToDataUrl(ipdPdfimg8);
const ipdAdvertDataUrlApprP = await imageToDataUrl(ipdPdfimgApprpending);
const ipdAdvertDataUrlApprR = await imageToDataUrl(ipdPdfimgApprreject);
const ipdAdvertDataUrlApprReq = await imageToDataUrl(ipdPdfimgApprrequired);


const getAdvertImageByCustCategory = (custCategory) => {
    switch (custCategory) {
        case "CL":
            return ipdAdvertDataUrl1;
        case "HTC":
            return ipdAdvertDataUrl2;
        case "PJW":
            return ipdAdvertDataUrl3;
        case "PS":
            return ipdAdvertDataUrl4;
        case "SRV":
            return ipdAdvertDataUrl5;
        case "WS":
            return ipdAdvertDataUrl6;
        case "DR":
            return ipdAdvertDataUrl7;
        case "GT":
            return ipdAdvertDataUrl8;
        default:
            return ipdAdvertDataUrl1;  // default image if no category matches
    }
};





const getCompanyDetails = (headerName) => {
    let companyName, companyAddress, companyDescription;

    switch (headerName) {
        case 1:
            companyName = "IPD Group Limited";
            companyAddress = `
              43 Newton Road
              Wetherill Park, NSW 2164
              T: 1300 556 601
              E: customerservice@ipd.com.au
              W: www.ipd.com.au
          `;
            break;
        case 2:
            companyName = "PJ White & Co";
            companyDescription = "A business of IPD Group Ltd";
            companyAddress = `
                27 Croydon Road
                Keswick, SA 5035
                T: 1300 253 306
                E: lamps@pjwhite.com.au
                W: www.pjwhite.com.au
            `;
            break;
        case 3:
            companyName = "Addelec Power Services";
            companyAddress = `
              43 Newton Road
              Wetherill Park, NSW 2164
              T: 1300 556 601
              E: customerservice@ipd.com.au
              W: www.ipd.com.au
          `;
            break;
        default:
            companyName = "Unknown Company";
            companyAddress = 'There is no given address';
            break;
    }

    return { companyName, companyDescription, companyAddress };
}

const getQuoteDetailsFromSessionStorage = () => {
    const compressedQuoteDetailsString = sessionStorage.getItem('quoteDetails');
    if (compressedQuoteDetailsString) {
        const decompressedQuoteDetailsString = LZString.decompress(compressedQuoteDetailsString);
        try {
            return JSON.parse(decompressedQuoteDetailsString);
        } catch (e) {
            console.error('Error parsing decompressed quote details:', e);
            return null;
        }
    }
    return null;
};

// const PDFHeader = ({ apiResponse, systemSetting, userFullName, userId }) => {
//     const id = sessionStorage.getItem('id')

//     const quoteDetails = getQuoteDetailsFromSessionStorage();
//     const primaryCustomer = apiResponse.quote.tblQuoteCustomers.find(customer => customer.isPrimary);
//     const { companyName, companyAddress, companyDescription } = getCompanyDetails(apiResponse.quote.headerName);
//     const advertImageUrl = getAdvertImageByCustCategory(primaryCustomer.custCategory);
//     //const user = useContext(UserContext);



//     let contactPerson = apiResponse.quote.quoteContact;
//     let contactPersonPhoneNumber = apiResponse.quote.quoteContactDetailsMobile;
//     //let contactPersonPhoneNumber = quoteDetails[id].quoteContactDetailsMobile;

//     let ipdLogoToUse;
//     switch (apiResponse.quote.headerName) {
//         case 1:
//             ipdLogoToUse = ipdLogoDataUrl1;
//             break;
//         case 2:
//             ipdLogoToUse = ipdLogoDataUrl3;
//             break;
//         case 3:
//             ipdLogoToUse = ipdLogoDataUrl2;
//             break;
//         default:
//             ipdLogoToUse = ipdLogoDataUrl1;  // Default case, modify as needed
//             break;
//     }

//     const renderLines = (text) => {
//         if (!text) return null; // Return null if text is falsy
//         return text.split("\n").map((line, index) => {
//             return <p><Interweave key={index} content={line.trim()} /></p>;
//         });
//     };


//     return (
//         <table className="pdf-table" border="0">
//             <tbody>
//                 <tr>
//                     <td width="50%"><img src={ipdLogoToUse} width="auto" height="40" alt="" /></td>
//                     <td width="50%">&nbsp;</td>

//                 </tr>
//                 <tr>
//                     <td>
//                         <div className="pdf-company">
//                             <p className="r1">{renderLines(companyName)}</p>
//                             <p className="r2">{renderLines(companyDescription)}</p>
//                             <p className="r2">ABN: 12 111 178 351</p>
//                         </div>
//                         <div className="pdf-address">
//                             {renderLines(companyAddress)}
//                         </div>
//                     </td>
//                     <td><div className="pdf-quotehead">Quotation</div>
//                         <div className="pdf-quotetitle">
//                             <table border="0">
//                                 <tbody>
//                                     <tr height="25">
//                                         <td width="100px">Quote No:</td>
//                                         <td valign="middle" className="pdf-fbolder">{apiResponse.quote.quoteNumber}</td>
//                                     </tr>
//                                     <tr height="25">
//                                         <td>Date:</td>
//                                         <td valign="middle" className="pdf-fbolder">{new Date(apiResponse.quote.quoteDate).toLocaleDateString('en-GB')}</td>
//                                     </tr>
//                                     {
//                                         apiResponse.quote.projectName &&
//                                         <tr height="25">
//                                             <td>Project Name:</td>
//                                             <td valign="middle" className="pdf-fbolder">{apiResponse.quote.projectName}</td>
//                                         </tr>
//                                     }
//                                     <tr height="25">
//                                         <td>Customer Ref:</td>
//                                         <td valign="middle" className="pdf-fbolder">{primaryCustomer.customerNumber}</td>
//                                     </tr>
//                                 </tbody>
//                             </table>
//                         </div>
//                     </td>
//                 </tr>
//                 <tr>
//                     <td>
//                         <div className="pdf-cutomerto">
//                             <p>TO:</p>
//                             <p className="pdf-fbolder">{primaryCustomer.contactFullName}</p>
//                             <p className="pdf-fbolder">{primaryCustomer.customerName}</p>
//                         </div>
//                     </td>
//                     <td><div className="pdf-advertimg"><img src={advertImageUrl} width="auto" height="90" alt="" /></div></td>
//                 </tr>
//                 <tr>
//                     <td>&nbsp;</td>
//                     <td>&nbsp;</td>
//                 </tr>
//                 <tr>
//                     <td valign="middle" colspan="2">

//                         <div className="pdf-contact" >

//                             Contact Person: <span className='pdf-fbolder'>{contactPerson}</span> <span className='seperator'>|</span>Contact No: <span className='pdf-fbolder'>{contactPersonPhoneNumber}</span>

//                         </div>
//                     </td>
//                 </tr>
//             </tbody>
//         </table>
//     );
// };
const PDFHeader = ({ apiResponse, systemSetting, userFullName, userId, grandTotal, shouldHideTotalPrice, totalPages, setTotalPages }) => {
    const id = sessionStorage.getItem('id')
    const quoteDetails = getQuoteDetailsFromSessionStorage();
    let primaryCustomer = "";
    if(apiResponse?.quote?.approvalStatusId === 4 || apiResponse?.quote?.approvalStatusId === 2){
        primaryCustomer = quoteDetails[id].selectedCustomers.find(customer => customer.isPrimary);
    }
    else primaryCustomer = apiResponse.quote.tblQuoteCustomers.find(customer => customer.isPrimary);
    
    const { companyName, companyAddress, companyDescription } = getCompanyDetails(apiResponse.quote.headerName);
    //const { totalPages } = useContext(PageCountContext); 
    const advertImageUrl = getAdvertImageByCustCategory(primaryCustomer.custCategory);

    let contactPerson = apiResponse.quote.quoteContact.trim();
    let contactPersonPhoneNumber = apiResponse.quote.quoteContactDetailsMobile.trim();

    //t itemsCount = (apiResponse.quote.tblQuoteItems).length;
    //const logoClass = totalPages === 2 ? 'displaynone' : '';



    //const contextValue = useContext(PageCountContext);

    let ipdLogoToUse;
    switch (apiResponse.quote.headerName) {
        case 1:
            ipdLogoToUse = ipdLogoDataUrl1;
            break;
        case 2:
            ipdLogoToUse = ipdLogoDataUrl3;
            break;
        case 3:
            ipdLogoToUse = ipdLogoDataUrl2;
            break;
        default:
            ipdLogoToUse = ipdLogoDataUrl1;  // Default case, modify as needed
            break;
    }

    const renderLines = (text) => {
        if (!text) return null; // Return null if text is falsy
        return text.split("\n").map((line, index) => {
            return <p key={index}><Interweave content={line.trim()} /></p>;
        });
    };

    return (
        <table className="pdf-table" border="0">
            <tbody>
                <tr>
                    <td width="50%"><img src={ipdLogoToUse} width="auto" height="40" alt="" /></td>
                    <td width="50%">&nbsp;</td>
                </tr>
                <tr>
                    <td>
                        <div className="pdf-company">
                            <p className="r1">{renderLines(companyName)}</p>
                            <p className="r2">{renderLines(companyDescription)}</p>
                            <p className="r2">ABN: 12 111 178 351</p>
                        </div>
                        <div className="pdf-address">
                            {renderLines(companyAddress)}
                        </div>
                    </td>
                    <td>
                        <div className="pdf-quotehead">Quotation</div>
                        <div className="pdf-quotetitle">
                            <table border="0">
                                <tbody>
                                    <tr height="20">
                                        <td width="100">Quote No:</td>
                                        <td valign="middle" className="pdf-fbolder">{apiResponse.quote.quoteNumber.trim()}</td>
                                    </tr>
                                    <tr height="20">
                                        <td>Date:</td>
                                        <td valign="middle" className="pdf-fbolder">{new Date(apiResponse.quote.quoteDate).toLocaleDateString('en-GB')}</td>
                                    </tr>
                                    {apiResponse.quote.projectName && (
                                        <tr height="20">
                                            <td>Project Name:</td>
                                            <td valign="middle" className="pdf-fbolder">{apiResponse.quote.projectName.trim()}</td>
                                        </tr>
                                    )}
                                    <tr height="20">
                                        <td>Customer Ref:</td>
                                        <td valign="middle" className="pdf-fbolder">{primaryCustomer.customerNumber.trim()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="pdf-cutomerto">
                            <p>To:</p>
                            <p className="pdf-fbolder">{primaryCustomer.contactFullName.trim()}</p>
                            <p className="pdf-fbolder">{primaryCustomer.customerName.trim()}</p>
                        </div>
                    </td>
                    <td><div className="pdf-advertimg displaynone"><img src={advertImageUrl} width="auto" height="90" alt="" /></div></td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td valign="middle" colSpan="2">
                        <div className="pdf-contact">
                            Contact Person: <span>{contactPerson.trim()}</span>
                            <span className='seperator'>|</span>
                            Contact No: <span>{contactPersonPhoneNumber.trim()}</span>
                        </div>
                        {(!shouldHideTotalPrice) && (

                            <div id = "C1" className="pdf-head-grandtotal">
                                Grand Total (AU$): <span className='pdf-fbolder'>${grandTotal.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </div>
                        )}
                    </td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            </tbody>
        </table>
    );
};






const getItemClass = (item) => {
    return (item.rowType === 'I') ? 'pdf-highlight' : '';
}


const PDFDataGrid = ({ apiResponse, shouldHideTotalPrice, hiddenStatus }) => {
    const isTotalPriceHidden = apiResponse.quote.quoteId ? apiResponse.quote.qttype === 2 : false;
    const isUnitPriceHidden = apiResponse.quote.quoteId ? apiResponse.quote.qttype === 2 : false;

    // Sort items based on 'position' property
    const sortedItems = [...apiResponse.quote.tblQuoteItems].sort((a, b) => a.position - b.position);
    const grandTotal = useMemo(() => {
        return sortedItems.reduce((acc, item) => {
            const hideInfo = hiddenStatus[item.rowId] || {};
            const isNonGroupItem = item.groupName.includes("Non Grouped Items");
            if (item.rowType === "SG") {
                item.displayGroupName = "";
                
            }
            if (hideInfo.hideRow) {
                if (item.displayGroupName !== "" || isNonGroupItem) {
                    return acc + item.totalPrice; // Include this item's total price even if the row is hidden
                }
            }
            if (isNonGroupItem || item.displayGroupName !== "") {
                return acc + item.totalPrice;
            }
            return acc;
        }, 0);
    }, [sortedItems, hiddenStatus]);

    // Get all items with rowType === 'I'
    let indentItems = sortedItems.filter(item => item.rowType === 'I');
    let hasVisibleIndentItems = indentItems.some(item => !hiddenStatus[item.rowId]?.hideRow);
    let isOnlyEngineeringIndentItems = indentItems.length > 0 && indentItems.every(item => item.stStockCode === 'ENGINEERING');
    //let hideInfo = {};
    

    return (
        <table className="pdf-quotetable" width="100%" border="0">
            <thead>
                <tr className="r-head">
                    <td width="27%">ITEM#</td>
                    <td align="left" width="40%">DESCRIPTION</td>
                    <td align="right" width="8%">QTY</td>
                    <td align="right" width="12%">UNIT ($)</td>
                    <td align="right" width="13%">TOTAL ($)</td>
                </tr>
                <tr className="r-head-spacer"></tr>
            </thead>
            <tbody>
                {sortedItems.map((item, index) => {
                    const hideInfo = hiddenStatus[item.rowId] || {};
                    const isAssemblyItem = item.rowType === "AI";
                    const isEngineeringItem = item.stStockCode === "ENGINEERING";
                    if(isEngineeringItem && hideInfo.hideRow && isOnlyEngineeringIndentItems){
                        hasVisibleIndentItems = false;
                        
                    }
                    const isDbextraItem = item.rowType === "DX";
                    const shouldCheckIsAssemblyHeader = Object.keys(hideInfo).length === 0;
                    const isblankrow = item.stStockCode === "";
                    const isAssemblyHeader = shouldCheckIsAssemblyHeader && item.rowType === "A";

                    if (hideInfo.hideRow || isAssemblyItem) {
                        return null;  // Skip rendering this row if hideRow is true
                    }

                    let displayGroupHeader = item.displayGroupName &&
                        (index === 0 || item.displayGroupName !== sortedItems[index - 1].displayGroupName);
                    const displaySubGroupHeader = item.displaySubGroupName &&
                        (index === 0 || item.displaySubGroupName !== sortedItems[index - 1].displaySubGroupName);

                        if (item.rowType === "SG") {
                            displayGroupHeader = false;
                            
                        }

                    const shouldHideTotalLn = item.rowType === "LN";

                    return (
                        <Fragment key={item.itemId}>
                            {displayGroupHeader && (
                                <tr className='pdf-group-title'>
                                    <td colSpan="1" className='c1 left'>{item.displayGroupName}</td>
                                    <td align="left" width="40%" className='c1 left'>
                                        {item.description}
                                    </td>
                                    <td align="right" width="8%" className='c1 numfrendbold'>
                                        {hideInfo.hideQty ? "" : item.qty}
                                    </td>
                                    <td align="right" width="12%" className='c1 numfrendbold'>
                                        {shouldHideTotalLn || hideInfo.hidePrice ? "" : item?.nettEA?.toFixed(2)}
                                    </td>
                                    <td align="right" width="13%" className='c1 numfrendbold'>
                                        {shouldHideTotalLn || hideInfo.hidePrice ? "" : `${item?.totalPrice?.toFixed(2)}`}
                                    </td>
                                </tr>
                            )}
                            {displaySubGroupHeader && (
                                <tr className='pdf-subgroup-title'>
                                    <td colSpan="1" className='c1 left'>{item.displaySubGroupName}</td>
                                    <td align="left" width="40%" className='c1 left'>
                                        {item.description}
                                    </td>
                                    <td align="right" width="8%" className='c1 numfrendboldi'>
                                        {hideInfo.hideQty ? "" : item.qty}
                                    </td>
                                    <td align="right" width="12%" className='c1 numfrendboldi'>
                                        {shouldHideTotalLn || hideInfo.hidePrice ? "" : item?.nettEA?.toFixed(2)}
                                    </td>
                                    <td align="right" width="13%" className='c1 numfrendboldi'>
                                        {shouldHideTotalLn || hideInfo.hidePrice ? "" : `${item?.totalPrice?.toFixed(2)}`}
                                    </td>
                                </tr>
                            )}
                            {!(displayGroupHeader || displaySubGroupHeader) && (
                                <tr className='pdf-normal-title'>
                                    <td width="27%" className={`pdf-quotetable-item ${getItemClass(item)}`}>
                                        {item.stStockCode}{(item.rowType === 'I') && '*'}
                                    </td>
                                    <td align="left" width="40%">
                                        {item.description}
                                    </td>
                                    <td align="right" className='numfrend' width="8%">
                                        {hideInfo.hideQty || shouldHideTotalLn || isblankrow ? "" : item.qty}
                                    </td>
                                    <td align="right" className='numfrend' width="12%">
                                        {isUnitPriceHidden || shouldHideTotalLn || hideInfo.hidePrice || isblankrow  ? "" : `${item?.nettEA?.toFixed(2)}`}
                                    </td>
                                    <td align="right" className='numfrend' width="13%">
                                        {shouldHideTotalLn || isTotalPriceHidden ||  hideInfo.hidePrice || isblankrow ? "" : `${parseFloat(item?.totalPrice)?.toFixed(2)}`}
                                    </td>
                                </tr>
                            )}
                        </Fragment>
                    );
                })}
                {!shouldHideTotalPrice && (
                    <tr className="r-head grand">
                        <td align="right" colSpan="3"> Grand Total - ex GST (AU$)</td>
                        <td align="right" colSpan="2" className="pdf-grandtotal">
                            {shouldHideTotalPrice ? "" : `$${grandTotal.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                        </td>
                    </tr>
                )}
                {hasVisibleIndentItems && (
                    <tr className="pdf-note-row">
                        <td className="pdf-highlight pdf-note" colSpan="5">
                            Note: Items marked with * are indent or custom manufactured.
                            These items are <span>non-returnable.</span>
                        </td>
                    </tr>
                )}
                <tr>
                    <td className="pdf-fixed-spacer" colSpan="5">&nbsp;</td>
                </tr>
            </tbody>
        </table>
    );
};



const Stamp = ({ approvalStatusId }) => {
    let src;
    switch (approvalStatusId) {
        case 2:
            src = ipdAdvertDataUrlApprP;
            break;
        case 5:
            src = ipdAdvertDataUrlApprR;
            break;
        case 1:
            src = ipdAdvertDataUrlApprReq;
            break;
        default:
            src = null; // Provide a default image or leave blank
    }



    if (!src) return null;

    return (
        <div className='displaynone'> {/* Hide the stamp by default */}
            <div className="pdf-stamp">
                <img src={src} width="auto" height="140" alt="Stamp" />
            </div>
        </div>
    );
};


const PageTemplate = (props, apiResponse, totalPages, setTotalPages , shouldHideTotalPrice) => {

    const currentYear = new Date().getFullYear(); // Dynamic current year
    // const apiResponse = getdata();
    //const { setTotalPages } = useContext(PageCountContext); 
if(!shouldHideTotalPrice){
    props.totalPages <= 2  ? document.getElementById("C1").style.display = "none" : document.getElementById("C1").style.display = "blocked";
}
    //logoClass = props.totalPages <= 2 ? 'displaynone' : '';

    useEffect(() => {
        setTotalPages(props.totalPages);
        
    }, [props.totalPages]);
    let stampSrc;
    switch (apiResponse?.quote?.approvalStatusId) {
        case 2:
            stampSrc = ipdAdvertDataUrlApprP;
            break;
        case 5:
            stampSrc = ipdAdvertDataUrlApprR;
            break;
        case 1:
            stampSrc = ipdAdvertDataUrlApprReq;
            break;
        // Add more cases as needed
        default:
            stampSrc = null; // Or a default stamp image
            break;
    }


    let ipdLogoToUsePageTemplate;
    switch (apiResponse?.quote?.headerName) {
        case 1:
            ipdLogoToUsePageTemplate = ipdLogoDataUrl1;
            break;
        case 2:
            ipdLogoToUsePageTemplate = ipdLogoDataUrl3;
            break;
        case 3:
            ipdLogoToUsePageTemplate = ipdLogoDataUrl2;
            break;
        default:
            ipdLogoToUsePageTemplate = ipdLogoDataUrl1; // Default case, modify as needed
            break;
    }









    const logoClass = props.pageNum === 1 ? 'displaynone' : '';
    //const stampHtml = stampSrc ? `<div className="pdf-stamp"><img src="${stampSrc}" width="auto" height="140" alt="Stamp" /></div>` : '';

    return (
        <div>
            <div className="pdf-stamp">
                <img src={stampSrc} width="auto" height="140" alt="Stamp" />
            </div>
            <div className={`${logoClass}`}>
                <div className="pdf-header">
                    <img src={ipdLogoToUsePageTemplate} width="auto" height="30" alt="" />
                </div>
            </div>

            <div className="pdf-footer">
                <div className="r1line"></div>
                <div className="r1-c1">Power Distribution & Protection I Enclosures & Climate Control I Energy Management & Power Quality I Automation & Motor Control Safety & Hazardous Area Equipment I Industrial Communications I EV Chargers & PV Solutions I Installation & Accessories</div>
                <div className="r2">
                    <div className="r2-c1">Copyright {currentYear} IPD Group Ltd. All rights reserved.</div>
                    <div className="r2-c2">Page: {props.pageNum} of {props.totalPages}</div>
                </div>
            </div>
        </div>
    );
};

const QuotePDFLayout = ({ apiResponse, onFinish, systemSetting, userFullName, termsAndConditions, userId, shouldHideTotalPrice, hiddenStatus, totalPages, setTotalPages }) => {
    const pdfExportComponent = useRef(null);
    const id = sessionStorage.getItem('id')
    const quoteDetails = getQuoteDetailsFromSessionStorage();
    let primaryCustomer = "";
    if(apiResponse?.quote?.approvalStatusId === 4 || apiResponse?.quote?.approvalStatusId === 2){
        primaryCustomer = quoteDetails[id]?.selectedCustomers?.find(customer => customer.isPrimary);
    }
    else primaryCustomer = apiResponse.quote?.tblQuoteCustomers?.find(customer => customer.isPrimary);
    const username = userFullName;
    const UserId = userId;

    // Fetch the item from sessionStorage
    //const quoteId = sessionStorage.getItem('id'); // Reuse the method to obtain the quote ID consistently
  // Retrieve all session storage keys
const keys = Object.keys(sessionStorage);

// Define the prefix for the keys
const prefix = "combinedItems_";

// Find the key that starts with the prefix
let matchingKey = keys.find(key => key.startsWith(prefix));
let DYNAMIC_ID = null;
if (matchingKey) {
  // Extract the dynamic part from the key
  const dynamicPart = matchingKey.substring(prefix.length);

  // Store the extracted part in a constant
  DYNAMIC_ID = dynamicPart;

  console.log(DYNAMIC_ID);
} else {
  console.log("No matching key found.");
  
}
let storedAppendedNodes = null;
let combinedItemsKey = null;
if (matchingKey) {
    combinedItemsKey = `combinedItems_${DYNAMIC_ID}`;
    if(apiResponse?.quote?.createdBy === userId &&  apiResponse?.quote?.approvalStatusId !== 4 && apiResponse?.quote?.approvalStatusId !== 2 && apiResponse?.quote?.approvalStatusId !== 5){
        storedAppendedNodes = sessionStorage.getItem(combinedItemsKey);
    }
}
    combinedItemsKey = `combinedItems_${id}`;
    if(apiResponse?.quote?.createdBy === userId &&  apiResponse?.quote?.approvalStatusId !== 4 && apiResponse?.quote?.approvalStatusId !== 2 && apiResponse?.quote?.approvalStatusId !== 5){
        storedAppendedNodes = sessionStorage.getItem(combinedItemsKey);
    }
   
    
    if(storedAppendedNodes === null){
    combinedItemsKey = `combinedItems_${id}`;
    storedAppendedNodes = quoteDetails[id]?.pdfQuoteTermsAndConditions;
    }

    useEffect(() => {
        if (pdfExportComponent.current && apiResponse && systemSetting && primaryCustomer && termsAndConditions) {
            pdfExportComponent.current.save();
            if (onFinish) {
                onFinish();
            }
        }
    }, [onFinish]);

    const fileName = `${apiResponse?.quote?.quoteNumber}_${primaryCustomer?.customerNumber}.pdf`;

    const sortedItems = [...apiResponse?.quote?.tblQuoteItems].sort((a, b) => a.position - b.position);
    const grandTotal = useMemo(() => {
        return sortedItems.reduce((acc, item) => {
            const hideInfo = hiddenStatus[item.rowId] || {};
            const isNonGroupItem = item.groupName.includes("Non Grouped Items");
            if (item.rowType === "SG") {
                item.displayGroupName = "";
                
            }
            if (hideInfo.hideRow) {
                if (item.displayGroupName !== "" || isNonGroupItem) {
                    return acc + item.totalPrice; // Include this item's total price even if the row is hidden
                }

            }
            if (isNonGroupItem || item.displayGroupName !== "") {
                return acc + item.totalPrice;
            }
            return acc;
        }, 0);
    }, [sortedItems, hiddenStatus]);



    const renderTermsAndConditions = () => {
        const tradingConditionsContent = `Unless otherwise stated, this offer is subject to IPD Group Ltd Standard Trading Conditions. Any contract arising from your acceptance of this offer will be governed by these conditions. The Terms and Conditions can be viewed on our website at <a href="https://www.ipd.com.au/Documents/Pdf/TermsConditions.pdf" target="_blank">www.ipd.com.au/pdf/termsconditions.pdf</a>`;
        const parseHTMLString = (htmlString) => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = htmlString;
        
            const listItems = Array.from(tempDiv.children).filter(item => item.tagName === 'OL' || item.tagName === 'UL')
            .flatMap(list => Array.from(list.children).filter(item => item.tagName === 'LI'));
            const termsArray = [];
        
            listItems.forEach((item) => {
                // Find the first strong tag and treat it as the header
                const strongTag = item.querySelector('strong');
                const header = strongTag ? strongTag?.innerHTML : "";
        
                // Remove the first strong tag from the content, but keep any other strong tags intact
                if (strongTag) {
                    strongTag.remove();
                }
                
                // The remaining content (with subsequent strong tags, if any)
                const content = item?.innerHTML.trim();
        
                termsArray.push({ header, content });
            });
        
            return termsArray;
        };
        
    
        const parsedTerms = storedAppendedNodes ? parseHTMLString(storedAppendedNodes) : [];
        
        const termsContent = storedAppendedNodes
            ? parsedTerms.map((term, index) => (
                <div className='pdf-terms-inner' key={index}>
                    <span className='heading'><Interweave content={term.header} /></span>
                    <span className='body'><Interweave content={term.content} /></span>
                </div>
                 ))
            : termsAndConditions?.map(term => (
                <div className='pdf-terms-inner' key={term.id}>
                    <span className='heading'>{term.headerName}</span>
                    <span className='body'><Interweave content={term.templateDescription} /></span>
                </div>
            ));

        return (
            <div>
                <div className='pdf-terms'>
                    <div className='heading'>Terms and Conditions</div>
                    <div className='pdf-bodyadj'>{termsContent}</div>
                    <div className='pdf-terms trading'>
                        <div className='heading2'><span className='h1'>Trading Conditions</span><span className='b1'><br/><Interweave content={tradingConditionsContent} /></span></div>
                    </div>
                </div>            
            </div>
        );
    };



    return (
        <div>
            <PDFExport
                ref={pdfExportComponent}
                paperSize="A4"
                pageTemplate={(props) => PageTemplate(props, apiResponse, totalPages, setTotalPages , shouldHideTotalPrice)}
                fileName={fileName}
                margin="1.2cm"
                forcePageBreak=".page-break"
            >
                <Stamp approvalStatusId={apiResponse?.quote?.approvalStatusId} />
                <PDFHeader grandTotal={grandTotal} shouldHideTotalPrice={shouldHideTotalPrice} apiResponse={apiResponse} userFullName={username} userId={UserId} systemSetting={systemSetting} totalPages={totalPages} setTotalPages={totalPages} />
                <PDFDataGrid apiResponse={apiResponse} shouldHideTotalPrice={shouldHideTotalPrice} hiddenStatus={hiddenStatus} />
                <div className="page-break"></div>
                {renderTermsAndConditions()}

            </PDFExport>
        </div>
    );
}

const handleSystemSettingFetch = async (userId) => {
    if (!userId) {
        console.error("User ID not provided");
        return null;
    }
    return await getResponse("SystemSetting/getusersystemsettingbyuserid", { userId: userId });
}

const GetTermsandConditionData = async (userId) => {
    const response = await getResponse("TermsCondition/GetCombinedTermsAndConditions", { userId });
    const desiredHeaders = ["Pricing", "Delivery", "Validity"];
    return response.filter(item => desiredHeaders.includes(item.headerName));
}



const handleExportWithComponent = async (quoteId, userId, userFullName, shouldHideTotalPrice, hiddenStatus, totalPages, setTotalPages) => {
    if (!quoteId || !userId) {
        console.error("Quote ID or User ID not provided");
        return;
    }



    const username = userFullName;
    const UserId = userId;
    const apiResponse = await getResponse("Quote/getfilteredquotecustomers", { quoteId: quoteId });
    const systemSettingResponse = await handleSystemSettingFetch(userId);
    const termsAndConditionsData = await GetTermsandConditionData(userId);

    if (apiResponse && systemSettingResponse) {
        const container = document.createElement('div');
        document.body.appendChild(container);

        const removeContainer = () => {
            ReactDOM.unmountComponentAtNode(container);
            document.body.removeChild(container);
        };

        ReactDOM.render(
            <QuotePDFLayout
                apiResponse={apiResponse}
                systemSetting={systemSettingResponse}
                onFinish={removeContainer}
                userFullName={username}
                userId={UserId}
                termsAndConditions={termsAndConditionsData} // pass the filtered data here
                shouldHideTotalPrice={shouldHideTotalPrice}  // Updated to shouldHideTotalPrice
                hiddenStatus={hiddenStatus}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
            />,
            container
        );
    }
}

export default handleExportWithComponent;