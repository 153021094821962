import * as React from 'react';
import { itemStyle, indicatorToolTip } from "../../utils/QuoteItemIndicators";
import { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { QteRepDiscCheckerContext } from "../../Contexts/QteRepDiscCheckerContext";
import { HiddenStatusContext } from "../../Contexts/HiddenStatusContext";
import { isNumber, set, unescape } from "lodash";

export class Renderers {
  constructor(enterEdit, exitEdit, editFieldName,editItem, 
    onKeyDownSetSelectedItem,cancelChanges, authType, viewType, flattedData,showedColumns) {
    this.enterEdit = enterEdit;
    this.exitEdit = exitEdit;
    this.editFieldName = editFieldName;
    this.ArrowOrMousepress = false;
    this.editItem = editItem;
    this.onKeyDownSetSelectedItem = onKeyDownSetSelectedItem;
    this.cancelChanges = cancelChanges;
    this.authType = authType;
    this.viewType = viewType;
    this.flattedData = flattedData;
    this.showedColumns = showedColumns;
  }
  cellRender = (tdElement, cellProps) => {
    const dataItem = cellProps.dataItem;
    const field = cellProps.field;
    const editedField = cellProps.dataItem[this.editFieldName];
    const colsLeftAlign = ["groupLevelName", "selected", "stStockCode", "description"];
    const colsNonEdit = ["type", "dcCode", "dcRate", "listPrice", "l60", "l50", "l55", "l20", "soh", "totalCost", "totalPrice"];
    const costEAStyle = " ip-gridclr-costea";
    const dcOnLpStyle = " ip-gridclr-dc";
    const applyBGColor = field === "stEecostRound" ? costEAStyle : (field === "dcOnLp") ? dcOnLpStyle : "";
    const applyClassNonEditCol = colsNonEdit.includes(cellProps.field) ? " ip-gridclr-noneditable " : "";
    const zeroValClass = cellProps.field === "nettEA"
                  ? cellProps.dataItem[cellProps.field] === 0
                      ? " zeroVal"
                      : ""
                  : "";

    const applyClass = colsLeftAlign.includes(cellProps.field) ? "" : "align-right" + applyClassNonEditCol + applyBGColor + zeroValClass;

    const additionalProps = editedField && cellProps.field === editedField ? {
      ref: td => {
        const input = td && td.querySelector('input');
        if (!input || input === document.activeElement) {
          return;
        }
        if (input.type === 'checkbox') {
          input.focus();
        } else {
          input.select();
        }
      }
    } : {
      onClick: event => {
        const targetClasses = event.target.classList;
        this.ArrowOrMousepress = true;
        if (targetClasses && (targetClasses.contains('k-i-expand') || targetClasses.contains('k-i-collapse'))) {
          return;
        } else {
          this.editItem !== undefined && ((Number(sessionStorage.getItem("currColIndex"))||0) !== event.target.colIndex) ? this.cancelChanges() : <></>;
        }
      },
      onMouseOver: (e) => {
          if (e.currentTarget.cellIndex === 2) {
              e.currentTarget.title = indicatorToolTip(dataItem);
          } else {
              const titleName = (cleanedData) => {
                  const mappings = {
                      "stStockCode": "Part No.",
                      "description": "Description",
                      "listPrice": "Price",
                      "l60": "L60 Price",
                      "l50": "L50 Price",
                      "l55": "L35 Price",
                      "l20": "L20 (O1)",
                      "futCost": "Fut. Cost",
                      "costEA": "CostEA",
                      "avgCost": "Avg. Cost",
                      "lastCost": "Last Cost",
                      "repDisc": "RepDisc(%)",
                      "actMargin": "Margin(%)",
                      "avgcMargin": "AvgC. Margin (%)",
                      "frcMargin": "FC. Margin (%)",
                      "lcMargin": "LC. Margin (%)",
                      "totalCost": "Total Cost",
                      "totalPrice": "Total Price",
                      "groupLevelName": "Groups",
                      "subGroupName": "Sub Groups",
                      "stEecostRound": "Cost Round",
                      "actCost": "Avg Cost",
                      "stFutureCostRound": "Fut Cost",
                      "nettEA": "Net EA",
                      "soh" :"SOH"
                  };
                  return mappings[field];
              };
              const mappedField = titleName(field);
              if (mappedField) {
                  e.currentTarget.title = e.target.innerText !== '' ? mappedField + ": " + e.target.innerText : mappedField;
              }
          }
      },
      className: applyClass
    };
    return React.cloneElement(tdElement, {
      ...tdElement.props,
      ...additionalProps
    }, tdElement.props.children);
  };
  rowRender = (trElement, _dataItem) => {
    const currentData = _dataItem.dataItem;
    const isGroup = _dataItem.dataItem.rowType === "G";
    const isSubGroup = _dataItem.dataItem.rowType === "SG";
    let applyStyle = "";
    const { quoteRepDiscData } = useContext(QteRepDiscCheckerContext);
    const { hiddenStatus, setHiddenStatus } = useContext(HiddenStatusContext);
    const rId = _dataItem.dataItem.rowId;
    let selectedStyle = '';
  
    const setToEmptyIfZeroFields = ["repDisc","l50", "l55", "l20", "l60", "listPrice","dcOnLp","dcRate"];
    const withOneDecimalPlaces = ["margin","actMargin", "avgcMargin", "frcMargin", "lcMargin","dcOnLp","repDisc"];
    const noDecimalPlaces = ["soh","qty"];
    const groupLevelZeroRepDisc = ["G","SG","A"];
    const itemRowEmptySOH = ["G","SG","A","CI","DX","AI","LN"];
    const customRowType = ["G","SG","A","CI","DX","AI"];

    //Update hiddenStatus based on stStockCode
    useEffect(() => {
        if (!_dataItem.dataItem.stStockCode) return;
        if (_dataItem.dataItem.stStockCode.length === 0) return;
        if (this.editItem) return;
        setHiddenStatus(prevStatus => {
            const newStatus = { ...prevStatus };
            if (!newStatus[rId]) {
                newStatus[rId] = { hideRow: false, hideQty: false, hidePrice: false };
            }

            if (_dataItem.dataItem.stStockCode === "ENGINEERING"  || _dataItem.dataItem.rowType === "DX" || _dataItem.dataItem.rowType === "AI" || _dataItem.dataItem.rowType === "A") {
                newStatus[rId].hideRow = true;
            } else if (hiddenStatus[rId] && hiddenStatus[rId].hideRow === true) {
               newStatus[rId].hideRow = true;
            } else {
                newStatus[rId].hideRow = false;
            }

            return newStatus;
        });
    }, [_dataItem.dataItem.stStockCode, rId, setHiddenStatus]);

    if (_dataItem.dataItem.selected) selectedStyle = " selected-row";
    if (!_dataItem.dataItem.selected) selectedStyle = "";
    

    let skipRow = false;
    if (typeof this.editItem !== "undefined") {
      if (this.editItem.rowId === _dataItem.dataItem.rowId) skipRow = true;
    }

    // hide costea if authType is General and viewType is Normal
    // required for editable mode also
    if (this.authType === "General" && this.viewType === "Normal" && _dataItem.dataItem?.rowType !== "" && _dataItem.dataItem?.rowType !== "CI" && _dataItem.dataItem?.rowType !== "LN") {
      _dataItem.dataItem.stEecostRound = "***";
    }

    if (!skipRow) {

      if (_dataItem.dataItem.stStockCode && _dataItem.dataItem.inEdit !== 'stStockCode') {
        if (quoteRepDiscData) {
            const rIndex = quoteRepDiscData.findIndex((x) => x.stStockCode === _dataItem.dataItem.stStockCode && x.rowId === _dataItem.dataItem.rowId);
            if (rIndex > -1) {
                const repDiscData = quoteRepDiscData[rIndex];
                if (repDiscData.stStockCode === _dataItem.dataItem.stStockCode) {
                    _dataItem.dataItem.iconIndicator = (_dataItem.dataItem.repDisc * 0.01) >= repDiscData.currRepDisc
                        ? (_dataItem.dataItem.repDisc * 0.01) === 0 && repDiscData.currRepDisc === 0
                            ? ""
                            : "notif-repdisc"
                        : "";
                }
            }
        }
      
      }
    
      //update all NaN values to empty string
      const fieldsToExclude = ["headerRow","selected","iconIndicator","groupLevelName"];
      for (const key in _dataItem.dataItem) {
        const fieldShown = (this.showedColumns.filter((col) => col.field === key && !fieldsToExclude.includes(col.field)));
        if (fieldShown.length === 0) continue;
        const dataValue = _dataItem.dataItem[key];
        // fields that needs to be set to empty if zero
          const zIndex = setToEmptyIfZeroFields.indexOf(key);
        if (setToEmptyIfZeroFields[zIndex] !== undefined) {
          if (Number(dataValue) === 0 || Number(dataValue) === "NaN") {
            _dataItem.dataItem[key] = "";
          }
        };

        const grpLvlZeroIndex = groupLevelZeroRepDisc.indexOf(_dataItem.dataItem["rowType"]);
        if (groupLevelZeroRepDisc[grpLvlZeroIndex] !== undefined) {
          if (Number(dataValue) === 0) _dataItem.dataItem[key] = "";
        }

        // fields to be set to no decimal places
        const oneDecIndex = withOneDecimalPlaces.indexOf(key);
        if (withOneDecimalPlaces[oneDecIndex] !== undefined) {
          if (Number(dataValue) > 0 && Number(dataValue) !== "NaN") {
            _dataItem.dataItem[key] = parseFloat(dataValue).toFixed(1);
          }
        };

          // fields to be set to no decimal places
        const noDecIndex = noDecimalPlaces.indexOf(key);
        if (noDecimalPlaces[noDecIndex] !== undefined) {
          if (Number(dataValue) > 0 && Number(dataValue) !== "NaN") {
            _dataItem.dataItem[key] = parseFloat(dataValue).toFixed(0);
          } else if (Number(dataValue) === 0 && key === "qty") {
            _dataItem.dataItem[key] = parseFloat(0).toFixed(0);
          }
          
        };

        //emprty soh
        
        if (key === "soh") {
          const sohEmptyIndex = itemRowEmptySOH.indexOf(_dataItem.dataItem["rowType"]);
          if (Number(dataValue) === 0 && sohEmptyIndex > -1) {
            _dataItem.dataItem[key] = "";
          }
        }
       
        if (key === "type") {
          if ((dataValue === "" && _dataItem.dataItem.rowType !== "") || (customRowType.includes(_dataItem.dataItem.rowType))) {
            _dataItem.dataItem[key] = "";
          }
        }

        if (isNumber(dataValue) && isNaN(dataValue)) {
          _dataItem.dataItem[key] = "";
        }

        if ( _dataItem.dataItem.rowType === "LN") {
          if (isNumber(_dataItem.dataItem[key])) _dataItem.dataItem[key] = "";
        }

        // empty row
        if (_dataItem.dataItem.rowType === "" && _dataItem.dataItem.type === "") {
          // _dataItem.dataItem.totalCost = "";
          // _dataItem.dataItem.totalPrice = "";
          _dataItem.dataItem[key] = "";
        }

      }
    } 

    if (_dataItem.dataItem.rowType !== "" ) {
      applyStyle = selectedStyle + " ";
      _dataItem.dataItem.inEdit === undefined
        ? applyStyle = applyStyle + itemStyle(currentData)
        : <></>;
    }

    const trProps = {
      ...trElement.props,
      className: (isGroup ? "ip-grouprow-style" : isSubGroup ? "ip-subgrouprow-style" : "ip-normalrow-style") + applyStyle,
      onFocus: (e) => {
        // if (e.target.tagName === 'TD') {
          document.querySelectorAll(".arrowRight").forEach((element)=>element.classList.remove("arrowRight"));
          e.target.parentElement.cells !== undefined  ? e.target.parentElement.cells[0].classList.add("arrowRight") : <></>;
          // sessionStorage.setItem("currRowIndex", _dataItem.dataItem.position - 1);
          // } 
      },
      onKeyUp: (e) => {
        if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "ArrowRight" || e.key === "ArrowLeft") {
          this.ArrowOrMousepress = true;
        }
      },
      onClick: (e) => {
        const curCellIndex = e.target.cellIndex||Number(sessionStorage.getItem("currColIndex"))||4;
        if (e.target.tagName === 'TD') {
          if (_dataItem.dataItem){
            this.onKeyDownSetSelectedItem(_dataItem.rowIndex);
            this.ArrowOrMousepress = true;
          }
        } 
      },
      ref: (tr) => {
        if(tr) {
          if (_dataItem.dataItem.inEdit === undefined) {
            const currRowIndex = Number(sessionStorage.getItem("currRowIndex"))||0;
            const currColIndex = Number(sessionStorage.getItem("currColIndex"))||4;
            const totalRows = tr.parentElement.rows.length;
            const boundedRowIndex = Math.min(currRowIndex, totalRows - 1);
            if (tr.parentElement.rows.length <= currRowIndex) {
              // sessionStorage.setItem("currRowIndex", tr.parentElement.rows.length-1);
              // tr.parentElement.rows[tr.parentElement.rows.length-1].cells[currColIndex].focus();
            } else {
              const skipCellFocusIfFocusFor = ['txtProjectName','name','phone'
                ,'email','customerName','comment','phone','alias','jobTitle','mobile'
                ,'accountOwnerEmail','labourMarginField','contactSearch','newmargin','newprice']; //Note: this is a temporary fix. we need to find a better way to handle lost focus when typing from these input fields
              if (tr.rowIndex-1 === currRowIndex) {
                if (this.ArrowOrMousepress) {
                  this.ArrowOrMousepress = false;
                } else if (tr.parentElement.rows[currRowIndex] !== undefined) {
                  if (!skipCellFocusIfFocusFor.includes(document.activeElement?.id)) { // this has been added to prevent unfocus from input fields - temporary fix
                    const cell = tr.parentElement.rows[boundedRowIndex].cells[currColIndex];
                    cell !== undefined ? cell.focus({ preventScroll: false }) : <></>;
                    tr.parentElement.rows[currRowIndex].cells[currColIndex] !== undefined 
                      ? tr.parentElement.rows[currRowIndex].cells[currColIndex].focus({ preventScroll: true }) 
                      : <></>;
                  }
                }
              }
            }
          }
        }
      }
    };
    return React.cloneElement(trElement, {
      ...trProps
    }, trElement.props.children);
  };
}